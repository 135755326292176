import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactosService from "./contactosService"

const initialState = {
    contactos: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    isLoadingDel: false,
  }

  export const getContactos = createAsyncThunk(
    'contactos/get',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await contactosService.getContactos(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getContactos2 = createAsyncThunk(
    'contactos/get2',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await contactosService.getContactos(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteSingleContacto = createAsyncThunk(
    'contactos/deleteSingle',
    async ({userId, userEliminarId,tipoCuentaEliminar}, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        return await contactosService.deleteSingleContacto(userId,userEliminarId,tipoCuentaEliminar,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
export const deleteContactos = createAsyncThunk(
    'contactos/delete',
    async (id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
  
        return await contactosService.deleteContactos(id,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteOtrosContactos = createAsyncThunk(
    'contactos/deleteOtros',
    async (id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
  
        return await contactosService.deleteOtrosContactos(id,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const contactosSlice = createSlice({
    name: 'contactos',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
      .addCase(getContactos.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getContactos.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.contactos = action.payload
        state.isLoadingDel=false
      })
      .addCase(getContactos.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.isLoadingDel=true
      })
      .addCase(getContactos2.pending, (state) => {
        //state.isLoading = true
      })
      .addCase(getContactos2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.contactos = action.payload
       // state.isLoadingDel=false
      })
      .addCase(getContactos2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        //state.isLoadingDel=true
      })
        .addCase(deleteContactos.pending, (state) => {
          state.isLoading = true
        })
        .addCase(deleteContactos.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.contactos=[]
        })
        .addCase(deleteContactos.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(deleteOtrosContactos.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOtrosContactos.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
      })
      .addCase(deleteOtrosContactos.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteSingleContacto.pending, (state,action) => {
       /*  state.isLoadingDel = true
        if(action.meta.arg.tipoCuentaEliminar==="empresa"){
          state.contactos[0].empresas = state.contactos[0].empresas.filter(
            (item) => item.user._id !== action.meta.arg.userEliminarId
          ) 
        } else if(action.meta.arg.tipoCuentaEliminar==="persona"){
          state.contactos[0].contactos = state.contactos[0].contactos.filter(
            (item) => item.user._id !== action.meta.arg.userEliminarId
          ) 
        } */
        
      })
      .addCase(deleteSingleContacto.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        if(action.meta.arg.tipoCuentaEliminar==="empresa"){
          state.contactos[0].empresas = state.contactos[0].empresas.filter(
            (item) => item.user._id !== action.meta.arg.userEliminarId
          ) 
        } else if(action.meta.arg.tipoCuentaEliminar==="persona"){
          state.contactos[0].contactos = state.contactos[0].contactos.filter(
            (item) => item.user._id !== action.meta.arg.userEliminarId
          ) 
        }
       /*  state.isLoadingDel = false */

      })
      .addCase(deleteSingleContacto.rejected, (state, action) => {
        state.isLoading = false
         /* state.isLoadingDel = false */
        state.isError = true
        state.message = action.payload
      })
        
    },
  })
  
  export const { reset } = contactosSlice.actions
  export default contactosSlice.reducer