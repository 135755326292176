import { AppBar, Box } from "@mui/material"
import Container from "../../Varios/Container";
import {  useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getUser, getUser2, reset } from "../../../features/auth/authSlice";
import Sidebar2 from "./Sidebar/Sidebar2"
import Topbar2 from "./Topbar/Topbar2"
import { getMensajes, getMensajes2 } from "../../../features/mensajes/mensajesSlice";
import MensajesAfuera from "../../MensajesAfuera/MensajesAfuera";
import { useSnackbar } from 'notistack';
import socketIO from "socket.io-client"
import { getNotos } from "../../../features/notos/notosSlice";
import NotificacionNoti from "./Topbar/NotificacionNoti";
import { useLocation, useNavigate } from "react-router-dom";
import { getInvitaciones2 } from "../../../features/invitaciones/invitacionesSlice";
import { getIdsInvitaciones, getIdsContactos } from "../../../features/busquedas/busquedasSlice";
import { getContactos2 } from "../../../features/contactos/contactosSlice";
import {  getUsersOnempresasId4 } from "../../../features/onempresas/onempresasSlice";
import { getDashboard2 } from "../../../features/dashboard/dashboardSlice";

let socket



function BufferHead({children, trigger,theme, handleSidebarOpen, handleSidebarClose, pages, colorInvert, user, selectedTab, setSelectedTab, open, setOpenSidebar, openedMen, setOpenedMen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location.pathname)
  const mounted = useRef(false);
  const botonNoto = useRef()
  const botonNoto2 = useRef()
  const botonMenso = useRef()

  const [socketConnected, setSocketConnected] = useState(false)
  const [stateDrawer, setStateDrawer] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //  SocketContext =createContext()

  const performMyAction = useCallback(
    (Ref, action, data) => {
      return (Ref.current = dispatch(action(data)));
    },
    [dispatch]
  );
  useEffect(()=>{
    //socket = socketIO.connect("http://localhost:5000")
    socket = socketIO.connect("https://rekloot.com")
    socket.emit("setup", user._id)
    socket.on("connection", ()=> setSocketConnected(true))
    socket.on("notification received", (newMessageReceived)=>{
 //console.log(newMessageReceived)
// console.log("huevo")
// dispatch(getNotos())
//console.log(newMessageReceived)

if(newMessageReceived.tipo==="terminoOnboard"||newMessageReceived.tipo==="registro"){
  enqueueSnackbar((newMessageReceived.tipo==="terminoOnboard"?"Completaste el Onboarding":newMessageReceived.tipo==="registro"&&"Bienvenido a Rekloot"),{
    key: newMessageReceived.userReceptor+newMessageReceived.fecha,
    content:()=>(
      <NotificacionNoti
      newMessageReceived={newMessageReceived}
         closeSnackbar={closeSnackbar}

          performMyAction={performMyAction}
          botonNoto={botonNoto}
          
          navigate={navigate}
      />
     
    ),
    anchorOrigin:{horizontal:"right",vertical:"bottom"}
  }
  )
} else{
  setTimeout(async()=>{
    if(mounted.current===true){
      if(newMessageReceived.tipo==="mensaje"){
        await performMyAction(botonMenso,getMensajes2,)
      }
      if(newMessageReceived.tipo==="cambioCuenta"){
        await performMyAction(botonMenso,getUser2)
      }
      if(newMessageReceived.tipo==="invitaciones"&&location.pathname.includes("invitaciones")) {
        await performMyAction(botonMenso,getInvitaciones2,)
      }
    
      if((newMessageReceived.tipo==="aceptaInv"||newMessageReceived.tipo==="ignoraInv")&&location.pathname.includes("invitaciones")) {
        await performMyAction(botonMenso,getInvitaciones2,)
      } else if((newMessageReceived.tipo==="aceptaInv"||newMessageReceived.tipo==="ignoraInv")&&location.pathname.includes("busqueda-candidatos")){
          performMyAction(botonMenso,getIdsInvitaciones,)
        await performMyAction(botonMenso,getIdsContactos,)
      } else if(newMessageReceived.tipo==="aceptaInv"&&location.pathname.includes("contactos")){
        await performMyAction(botonMenso,getContactos2,)
      } else if(newMessageReceived.tipo==="aceptaInv"&&location.pathname.includes("mensajes")){
        await performMyAction(botonMenso,getContactos2,)
      }

      if(newMessageReceived.tipo==="aceptaRegistro"&&location.pathname.includes("perfil/equipo")){
        await performMyAction(botonMenso, getUsersOnempresasId4)
      }

      if((newMessageReceived.tipo==="invitaciones"||newMessageReceived.tipo==="mensaje"||newMessageReceived.tipo==="aceptaInv"||newMessageReceived.tipo==="ignoraInv")&&location.pathname.includes("dashboard")){
        await performMyAction(botonMenso, getDashboard2,)
      }
      
    // if(newMessageReceived.tipo!=="terminoOnboard"&&newMessageReceived.tipo!=="registro"){
      await performMyAction(botonNoto2,getNotos,)
    // }
     
      //console.log(newMessageReceived)
      enqueueSnackbar(newMessageReceived?.tipo==="mensaje"?"Nuevo Mensaje":(newMessageReceived?.tipo==="invitaciones"?"Nueva Invitación":(newMessageReceived?.tipo==="aceptaInv"?"Invitación Aceptada":(newMessageReceived.tipo==="ignoraInv"?"Invitación Ignorada":(newMessageReceived.tipo==="terminoOnboard"?"Completaste el Onboarding":(newMessageReceived.tipo==="cambioCuenta"?"Han cambiado tus permisos":(newMessageReceived.tipo==="aceptaRegistro"&&"Se han unido a tu equipo")))))),{
        key: newMessageReceived.userReceptor+newMessageReceived.fecha,
        content:()=>(
          <NotificacionNoti
          newMessageReceived={newMessageReceived}
             closeSnackbar={closeSnackbar}
    
              performMyAction={performMyAction}
              botonNoto={botonNoto}
              
              navigate={navigate}
          />
         
        ),
        anchorOrigin:{horizontal:"right",vertical:"bottom"}
      }
      )
     
    }
    },1000)
}

      
      //dispatch(getNotos())
      //console.log(newMessageReceived.message)
    });

    return async()=>{
      setSocketConnected(false)
      //console.log(socket?.off("setup"))
      //await socket?.offAny()
      // socket.off("message received", ()=>{
      //   console.log("ya no")
      // });
      socket.disconnect(user._id)
    }
  },[])


  


  useEffect(()=>{
    mounted.current = true;
    const d = dispatch(getUser())
    const men = dispatch(getMensajes());
    const notos = dispatch(getNotos())
    return ()=>{
      mounted.current = false;
      d?.abort()
      men?.abort();
      notos?.abort();
      botonNoto?.current?.abort();
      botonNoto2?.current?.abort();
      botonMenso?.current?.abort();
      dispatch(reset()) 
    }
  },[dispatch])




  
  return (
    <>
    
    <Box> 
      <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : theme.palette.background.paper,
          //zIndex: (theme) => theme.zIndex.drawer + 1 ,
        }}
        elevation={trigger ? 1 : 0}
        
        className={"navi2"}
        
      >
        <Container paddingY={1} className="cont-nav2" maxWidth={"100%"} sx={{display:"flex"}}>
          <Topbar2
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
            user={user}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            stateDrawer={stateDrawer}
            setStateDrawer={setStateDrawer}
            botonNoto={botonNoto}
            performMyAction={performMyAction}
          />
        </Container>
      </AppBar>
      
      <Sidebar2
        onClose={handleSidebarClose}
        setOpenSidebar={setOpenSidebar}
        open={open}
        variant="temporary"
        pages={pages}
        user={user}
        stateDrawer={stateDrawer}
        setStateDrawer={setStateDrawer}
      />
       <main>
        {children}
       
      </main>
      </Box>
      
      <MensajesAfuera
          openedMen={openedMen}
          setOpenedMen={setOpenedMen}         
          user={user}
          //contacto={contacto}
        />
      </>
  )
}

export {BufferHead, socket}
