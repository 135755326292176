import { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import { Avatar, Card, Grid, IconButton, Typography } from '@mui/material';
import { updateNotos } from '../../../../features/notos/notosSlice';
import { Box } from '@mui/system';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { ellipsis } from "polished";
import { useSelector } from 'react-redux';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

function countWords(str) {
    return str.trim().split(/\s+/).length;
  }
  
  function capitalize2(string) {
    /*     console.log(string)
     */
    const words = string.trim();
    return words.charAt(0).toUpperCase();
  }
  
  
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = "#";
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
  
  function capitalize(string) {
    /*     console.log(string)
     */ return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  function stringAvatar(name) {
    if (countWords(name) === 0) {
      return {
        sx: {
          bgcolor: "red",
        },
        children: "NN",
      };
    } else if (countWords(name) === 1 && name.length > 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[1][0])}`,
      };
    } else if (countWords(name) === 1 && name.length === 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}`,
      };
    } else if (countWords(name) === 2) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else if (countWords(name) >= 3) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${capitalize(name.split(" ")[1][0])}`,
      };
    } else {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[0][1])}`,
      };
    }
  }
  


const NotificacionNoti = forwardRef((props,ref)=> {
    const notoIni = useSelector((state) => state.notos)

   
    const { newMessageReceived, performMyAction, botonNoto, navigate, closeSnackbar  } = props;
    const handleClose = (ev,item) => {
        ev.preventDefault();
        ev.stopPropagation();
    closeSnackbar(item)
        // performMyAction(botonNoto,deleteSingleNotos,{
        //     idNotos:item?._id
        // })
        // if (props.onClose) {
        //   props.onClose(item.id);
        // }
      };


 
    return (
    <SnackbarContent
    ref={ref}
    className="snakeNoti"
    >
    <Card sx={{display:"flex", alignItems:"center", position:"relative", width:"100%", padding:"20px", minHeight:"64px",
    borderRadius:"16px",
    marginBottom:"16px",
    cursor:"pointer",}}
    className="shadoweoNoto"
    elevation={0}
    component={'div'}
    to={newMessageReceived.link || ''}
   onClick={()=>{
    //console.log(newMessageReceived)
    navigate(newMessageReceived.link)
    const noto = notoIni?.notos?.notos?.filter((item)=>item.mensaje===newMessageReceived.mensaje&&item.fecha===newMessageReceived.fecha&&item.tipo===newMessageReceived.tipo)
    //console.log(noto)
   // setStateDrawer(false)
    performMyAction(botonNoto, updateNotos,{
        idNotos:noto?.[0]?._id
    })
    closeSnackbar(newMessageReceived.userReceptor+newMessageReceived.fecha)
   }}
    >
<Box
        sx={{ backgroundColor: 'background.default',
        display:"flex", alignItems:"center",
        flexShrink:"0", justifyContent:"center", width:"32px", height:"32px", marginRight:"12px", borderRadius:"9999px"
         }}
      >
        <IconButton
        sx={{opacity:"1",
        backgroundColor:"transparent",
        }}
        color="inherit"
      
        disableRipple
        >
   
       
        {(newMessageReceived.tipo==="mensaje" || newMessageReceived.tipo==="invitaciones"||newMessageReceived.tipo==="aceptaInv"||newMessageReceived.tipo==="cambioCuenta"||newMessageReceived.tipo==="aceptaRegistro")&& (


            newMessageReceived?.avatar ? (
            <Avatar
              sx={{ 
                height:40,
                width:40,
                                    // backgroundColor: (theme) =>
                                    //   theme.palette.primary[500],
                                  }}
                                  alt={capitalize2(newMessageReceived?.nombre)
                                   + " " + capitalize2(newMessageReceived?.apellido)}
                                  src={
                                    newMessageReceived?.avatar
                                  }
                                  
            />
          ):
       ( newMessageReceived?.nombre ? (
        <Avatar
       
              sx={{ 
                height:40,
                width:40,
                                    // backgroundColor: (theme) =>
                                    //   theme.palette.primary[500],
                                  }}
                                  alt={capitalize2(newMessageReceived?.nombre)
                                   + " " + capitalize2(newMessageReceived?.apellido)}
                                  {...stringAvatar(
                                   capitalize2(newMessageReceived?.nombre)
                                   + " " + capitalize2(newMessageReceived?.apellido)
                                  )}
                                  
            />
       ): 


       (<MailIcon/>))
        )}
        {newMessageReceived.tipo==="ignoraInv" &&
        <PersonOffIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
        }

        {newMessageReceived.tipo==="terminoOnboard" &&
        <AccountCircleIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
 }

 {newMessageReceived.tipo==="registro" &&
        <ThumbUpOffAltIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
 }
        </IconButton>
      </Box>

      <Grid
    sx={{display:"flex", flexDirection:"column", flex:"1 1 auto"}}
    style={ellipsis(undefined,1)}
    >
      {newMessageReceived.tipo && 
        <Typography 
      sx={{fontWeight:"600",
      overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"14px",
      }}
      //className="font-semibold line-clamp-1"
      
      >
      {newMessageReceived.tipo==="mensaje" ? "Nuevo mensaje" : newMessageReceived.tipo==="invitaciones"? "Nueva invitación" : newMessageReceived.tipo==="aceptaInv" ? "Invitación aceptada" : newMessageReceived.tipo==="ignoraInv"?"Invitación ignorada":newMessageReceived.tipo==="terminoOnboard" ? "Completaste el onboarding" : newMessageReceived.tipo==="registro" ? "Bienvenido a Rekloot": newMessageReceived.tipo==="cambioCuenta" ?"Han cambiado tus permisos":newMessageReceived.tipo==="aceptaRegistro"&&"Se han unido a tu equipo"}
      </Typography>
     }

      {newMessageReceived.mensaje && (
        <Typography
       // className="line-clamp-2" 
       sx={{marginTop:"4px",
       overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"14px",
       }}
       style={ellipsis(undefined,3)}
        //style={ellipsis(undefined,1)}

        >
        {newMessageReceived.mensaje}
        </Typography>
      )} 

      {/* {newMessageReceived.mensaje && (
        <Typography className="mt-8 text-sm leading-none " 
        sx={{marginTop:"4px",
        overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"12px",
         }}
        color="text.secondary"
        
        >
          {formatDistanceToNow(new Date(newMessageReceived.fecha), { locale:spanishLocale ,addSuffix: true,  })}
        </Typography>
      )} */}
    </Grid>

    <IconButton
      disableTouchRipple
      sx={{top:"8px", right:"8px", position:"absolute", padding:"8px"}}
      color="inherit"
      size="small"
      onClick={(ev)=>handleClose(ev,newMessageReceived.userReceptor+newMessageReceived.fecha)}
    >
      
          <CloseIcon sx={{fontSize:"0.7rem"}}/>
        
    </IconButton> 


    </Card>
    </SnackbarContent>
  )
})

export default NotificacionNoti