import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import onboardService from './onboardService'


const initialState = {
  onboard: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new onboard
export const createOnboard = createAsyncThunk(
  'onboard/create',
  async (onboardData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onboardService.createOnboard(onboardData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



// Get user onboard
export const getOnboard = createAsyncThunk(
  'onboard/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onboardService.getOnboard(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onboard
export const getOnboardSinLoad = createAsyncThunk(
  'onboard/getAllSin',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onboardService.getOnboard(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onboard
export const getOnboard2 = createAsyncThunk(
  'onboard/getAll2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onboardService.getOnboard(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onboard}=getState();
      /* console.log(onboard,onboard.length) */
      if(onboard&&onboard.onboard&&onboard.onboard.length===0){
        return false
      }
    }
  }
)

// Delete user onboard
export const deleteOnboard = createAsyncThunk(
  'onboard/delete',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onboardService.deleteOnboard(userId, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit onboard
export const editOnboard = createAsyncThunk(
  "onboard/editOnboard", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
     /*  console.log(token) */
   // const token = JSON.parse(localStorage.getItem("user")).token;
 //     console.log("SLICE" + token)
      return await onboardService.editOnboard(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit onboard
export const editOnboard2 = createAsyncThunk(
  "onboard/editOnboard2", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 

      return await onboardService.editOnboard2(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Modificar Contacto email
export const uploadFileOn = createAsyncThunk(
  'onboard/uploadFileOn',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onboardService.uploadFileOn(imageData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Modificar Contacto email
export const uploadCV = createAsyncThunk(
  'onboard/uploadCV',
  async (fileData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onboardService.uploadCV(fileData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOnboard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createOnboard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onboard.push(action.payload)
      })
      .addCase(createOnboard.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getOnboard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOnboard.fulfilled, (state, action) => {
        state.isError= false
        state.isLoading = false
        state.isSuccess = true
        state.onboard = action.payload
      })
      .addCase(getOnboard.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getOnboardSinLoad.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(getOnboardSinLoad.fulfilled, (state, action) => {
        state.isError= false
        state.isLoading = false
        state.isSuccess = true
        state.onboard = action.payload
      })
      .addCase(getOnboardSinLoad.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getOnboard2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOnboard2.fulfilled, (state, action) => {
        state.isError= false
        state.isLoading = false
        state.isSuccess = true
        state.onboard = action.payload
      })
      .addCase(getOnboard2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteOnboard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOnboard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onboard = null
      })
      .addCase(deleteOnboard.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editOnboard.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editOnboard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onboard[0] = action.payload
      })
      .addCase(editOnboard.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      .addCase(editOnboard2.pending, (state) => {
       /*  state.isLoading = true */
      })
      .addCase(editOnboard2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        /* console.log({...action.meta.arg.modData}) */

        if(action.meta.arg.modData.modificaCon){
          delete action.meta.arg.modData.modificaCon
        }

        if(action.meta.arg.modData.fechaNacimiento){
          action.meta.arg.modData.fechaNacimiento=action.meta.arg.modData.fechaNacimiento.toISOString()
         // console.log(JSON.stringify(action.meta.arg.modData.fechaNacimiento))
          
        } 

        if(action.meta.arg.modData.laboral){
          for(let t=0;t<=action.meta.arg.modData.laboral.length-1;t++){
            action.meta.arg.modData.laboral[t].anoStart=action.meta.arg.modData.laboral[t].anoStart.toISOString()
            if(action.meta.arg.modData.laboral[t].anoFinish){
              action.meta.arg.modData.laboral[t].anoFinish=action.meta.arg.modData.laboral[t].anoFinish.toISOString()
            }
          }
          
        }
        if(action.meta.arg.modData.universidad){
          for(let t=0;t<=action.meta.arg.modData.universidad.length-1;t++){
            action.meta.arg.modData.universidad[t].anoInicio=action.meta.arg.modData.universidad[t].anoInicio.toISOString()
            if(action.meta.arg.modData.universidad[t].anoTermino){
              action.meta.arg.modData.universidad[t].anoTermino=action.meta.arg.modData.universidad[t].anoTermino.toISOString()
            }
          }
          
        }

      
          state.onboard[0] = {...state.onboard[0], ...action.meta.arg.modData}
       
        
      })
      .addCase(editOnboard2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })

      /* .addCase(uploadFile.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(uploadFileOn.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onboard[0] = action.payload
      })
      .addCase(uploadFileOn.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      /* .addCase(uploadCV.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(uploadCV.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
      })
      .addCase(uploadCV.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
  },
})

export const { reset } = onboardSlice.actions
export default onboardSlice.reducer
