import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {  useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import rekloot from "../../../../utils/icons/rekloot.webp";
import Typography from "@mui/material/Typography";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  logout } from "../../../../features/auth/authSlice";
import "./topbar.styles.css";
import { Avatar,  Divider, Drawer, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, Popover } from "@mui/material";
import {  useState } from "react";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import { reset as reset2} from '../../../../features/goals/goalSlice'
import CircularProgress from "@mui/material/CircularProgress";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import{ reset as resetMensajes} from '../../../../features/mensajes/mensajesSlice'
import{ reset as resetNotos} from '../../../../features/notos/notosSlice'
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import iNoBounce from "../../../../hooks/inobounce";
import useWindowDimensions from "../../../../hooks/useWindowsDimensions";
import DrawerNotificaciones from "./DrawerNotificaciones";
import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";
import { motion } from "framer-motion";

const transitionValues = {
  duration: 0.8,
  repeatType: "reverse",
  repeat:Infinity,
  ease: "easeOut"
};

const ballStyle = {
  display: "block",
  width: "8px",
  height: "8px",
  backgroundColor: "#d32f2f",
  borderRadius: "8px",
  marginRight: "auto",
  marginLeft: "auto",
  position:"absolute",
  right:"5px",
  top:"4px",
};

const Topbar2 = ({ onSidebarOpen, selectedTab, setSelectedTab, pages, colorInvert = false, user, stateDrawer, setStateDrawer, botonNoto, performMyAction }) => {
  const theme = useTheme();
  const notoIni = useSelector((state) => state.notos)

 
  const {  width } = useWindowDimensions();

  const { mode } = theme.palette;


  const [openedPopoverId, setOpenedPopoverId] = useState(null);


  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function countWords(str) {
    
    return str.trim().split(/\s+/).length;
  }

  function stringAvatar(name) {

    if(countWords(name)===0){
      return {
        sx: {
          bgcolor: "red",
        },
        children: "NN",
      };
    } else if (countWords(name)===1  && name.length>1  ){
      
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[1][0])}`,
      };
      

    } else if (countWords(name)===1 && name.length===1 ){
      
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}`,
      };
      

    } 
     else if(countWords(name)===2){
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else if(countWords(name)>=3){
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${capitalize(name.split(' ')[1][0])}`,
      };
    } else{
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[0][1])}`,
      };
    }
   

  
   
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
 // const { user } = useSelector((state) => state.auth);

/*  const updateAndThenLogout = () => async (dispatch) => {
  return await dispatch(logout());
}; */

const useStyles = makeStyles({

  paper: {
    background: "rgb(241, 245, 249)"
  }
});

 
  const onLogout = async () => {
    await dispatch(logout());
    dispatch(resetMensajes())
    dispatch(resetNotos())
   /*  dispatch(reset()); */
    //dispatch(reset2());
   // dispatch(reset3());
    navigate("/");
  };

/*   const onClicker = () =>{
    navigate("/");
  } */

  

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
    setOpenedPopoverId(event.currentTarget);
  };

  const userMenuClose = () => {
    setOpenedPopoverId(null);
    setUserMenu(null);
  };



  function capitalize(string) {
/*     console.log(string)
 */    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalize2(string) {
    /*     console.log(string)
     */   
    const words = string.trim()
     return words.charAt(0).toUpperCase()
      }

  function capitalizar(string) {
    /* console.log(string) */
    const words = string?.trim()?.split(" ");
   
    for (let i = 0; i < words?.length; i++) {
      if(words[i]==="de" || words[i]==="la" || words[i]==="las"|| words[i]==="lo" || words[i]==="los"|| words[i]===" " || words[i]==="" || words[i]==="en" || words[i]==="y" || words[i]==="del"){
        
      }
  
      else if(words[i]==="De" || words[i]==="La" || words[i]==="Las"|| words[i]==="Lo" || words[i]==="Los" || words[i]==="En" || words[i]==="Y" || words[i]==="Del"){
        words[i] = words[i][0].toLowerCase() + words[i].substr(1);
      }
  
      else{
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
       
    }
    
    return words.join(" ");
  }

  const load = useSelector((state) => state.auth.isLoading)
  //const load2 = useSelector((state) => state.auth.equipo).isLoading;
  const classNameFunc = ({ isActive }) => (isActive ? "activoLink" : "botonTopArriba");
  
  const classes = useStyles();

  // const contador = notoIni.filter((item))

  const contador = notoIni?.notos?.notos?.filter((item)=>item.unread===true)?.length

  const { pathname } = useLocation();
  // console.log(pathname)

  return (
    <>
    <Box
            sx={{ display: { xs:"block", md:"none"} }}
            alignItems={"center"}
            
          >
            <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
              disableTouchRipple
              sx={{
                borderRadius: 2,
                minWidth: "auto",
                padding: 1,
                //borderColor: alpha(theme.palette.divider, 0.2),
                borderColor: 'transparent',
                "&:hover":{
                  borderColor: 'transparent',
                  backgroundColor:"transparent",
                }
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
         <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          className="navi3"
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems={"center"}
          >
            <Box >
              <Box
                display={"flex"}
                component="div"
                sx={{cursor:"pointer"}}
                onClick={()=>{
                  navigate("/")
                }}
                title="Rekloot"
                width={{ xs: 100, md: 150 }}
              >
                <Box
                  component={"img"}
                  src={
                    mode === "light" && !colorInvert
                      ? rekloot
                      : rekloot
                  }
                  alt={'rekloot'}
                  height={1}
                  width={1}
                  paddingRight={{ xs: 0, md: "2rem" }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "block" }, whiteSpace:"nowrap" }}
            alignItems={"center"}
          >
           <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/dashboard')}}
           >
            <NavLink to="/dashboard" className={classNameFunc} >
           
          Dashboard
          
            </NavLink>
            </Button>
        {user && user.tipoCuenta==="empresa"&&
           <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/busqueda-candidatos')}}
           >
            <NavLink to="/busqueda-candidatos" className={classNameFunc} >
           
          Buscar Candidatos
          
            </NavLink>
            </Button>
            }
            <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/invitaciones/pendientes')}}
      
           >
            <NavLink to="/invitaciones/pendientes" 
            className={({isActive,isPending})=>{
              return isActive ? "activoLink" : pathname.includes('invitaciones') ? "activoLink" : "botonTopArriba"
            }}
            
            >
           

         Invitaciones
          
            </NavLink>
            </Button>
            <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/mensajes/bandeja-de-entrada')}}
           >
            <NavLink to="/mensajes/bandeja-de-entrada" 
              className={({isActive,isPending})=>{
              return isActive ? "activoLink" : pathname.includes('mensajes') ? "activoLink" : "botonTopArriba"
            }}
            >
           
         Mensajes
          
            </NavLink>
            </Button>
            <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/contactos')}}
           >
            <NavLink to="/contactos" className={classNameFunc}>
           
         Contactos
          
            </NavLink>
            </Button>

            {user && user.tipoCuenta==="persona"&&
           <Button disableRipple
           className="botonTopArribaeste2"
           onClick={()=>{navigate('/redes')}}
           >
            <NavLink to="/redes" className={classNameFunc} >
           
          Redes
          
            </NavLink>
            </Button>
            }
          </Box>
          <Box
            sx={{ display: { xs: "block", md: "block" }, whiteSpace:"nowrap" }}
            alignItems={"center"}
          //  style={ellipsis(undefined,1)}
          >
   
         
            

           
       {/* <Tooltip title="Notificaciones" followCursor arrow> */}
            <IconButton  className="botonTopArribaeste botonTopArriba"
            onClick={()=>{
              setStateDrawer(true);
              iNoBounce.enable();
            }}
            >
            {contador&&contador>=1 ?
              <motion.span
            transition={{
        y: transitionValues,
        width: transitionValues,
        height: transitionValues
      }}
      animate={{
        y: ["4px", "0px", "0px"],
        width: ["8px", "8px", "8px"],
        height: ["8px", "8px", "8px"]
      }}
            style={ballStyle}
           / > : <></>
            }
           
       
           
          <NotificationsNoneOutlinedIcon/>
          
            </IconButton>
          {/*  // </Tooltip> */}
            
            <Drawer
        ModalProps={{
          onBackdropClick: () => {
            iNoBounce.disable();
            setStateDrawer(false);
           
          },
          keepMounted: false,
        }}
        //BackdropProps={{invisible:true}}
        variant="temporary"
        sx={{ position: "absolute",  }}
        anchor="right"
        open={stateDrawer}
        classes={{paper:classes.paper}}
        // onClose={toggleDrawer(false)}
        // className={clsx(classes.drawer, {
        //   [classes.drawerOpen]: stateDrawer,
        //   [classes.drawerClose]: !stateDrawer,
        // })}
       //PaperProps={{backgroundColor:"rgb(241, 245, 249)"}}
      >
      {isMobile ? (
      <Grid sx={{
         width: isMobile ? (width>300?"300px":"100vw") :( width > 400 ? "400px!important" : width),
      backgroundColor:"rgb(241, 245, 249)!important",
      
      }}>
     
 
      
      <DrawerNotificaciones
      user={user}
      setStateDrawer={setStateDrawer}
      stateDrawer={stateDrawer}
      botonNoto={botonNoto}
      performMyAction={performMyAction}

      />
      

      </Grid>
      ) : (<PerfectScrollbar>
      <Grid sx={{
         width: isMobile ? (width>300?"300px":"100vw") :( width > 400 ? "400px!important" : width),
      backgroundColor:"rgb(241, 245, 249)!important",
      
      }}>
     
 
      
      <DrawerNotificaciones
      user={user}
      setStateDrawer={setStateDrawer}
      stateDrawer={stateDrawer}
      botonNoto={botonNoto}
      performMyAction={performMyAction}

      />
      

      </Grid>
      </PerfectScrollbar>)}
      
      
      </Drawer>
            
            <Button
            style={{borderRadius:"99999px", minWidth:"2rem", minHeight:"2rem"}}
        className="botontopbar botone2"
        onClick={userMenuClick}
        color="inherit"
      >
        <Grid className="gridtopbar" sx={{display:"none", marginLeft:"0.4rem", marginRight:"0.4rem",alignItems:"flex-end", flexDirection:"column"}}>
          <Typography component="span" className="topotop" sx={{fontSize:"0.75rem", color:"#000"}}>
            {(user) && (user.tipoCuenta==="persona" ? (capitalizar(user.firstname) + " " + capitalizar(user.lastname).split(" ")[0]) : (user.tipoCuenta==="empresa" && (user.empresa? capitalizar(user.empresa): (capitalizar(user.firstname) + " " + capitalizar(user.lastname).split(" ")[0]))))}
          </Typography>
          <Typography sx={{fontWeight:"500",fontSize: "0.5rem"}} color="text.secondary">
            {(user) && (user.tipoCuenta ==="persona" ? (user.email): (user.tipoCuenta==="empresa" && (user.sitioWeb ? user.sitioWeb : (user.email))))}
            {/* {(!user.tipoCuenta || user.tipoCuenta.length===0) && 'Invitado'} */}
          </Typography>
        </Grid>

       {/*  <Grid className="overlay3" sx={{background:"#fff!important"}}>
                       
                       </Grid> */}
                    
        {(user) && ((user.tipoCuenta==="persona") ? (user.profilePicture ? (
          <>
          <Grid className="overlay3" sx={{background:"#fff!important"}}>
                       
                       </Grid>
          <Avatar className="avatop avatop2" alt="avatar usuario"  imgProps={{referrerPolicy:"no-referrer"}}  src={user.profilePicture} />
          </>
        ) : (<>
          {load ? (<Avatar className="avatop"
         
          ><CircularProgress size="20px"/></Avatar>) : (<Avatar className="avatop"
          {...stringAvatar(capitalize2(user.firstname)+" "+capitalize2(user.lastname))}
          ></Avatar>)}
          </>
        )):(user.profilePicture ? (
          <>
          <Grid className="overlay3" sx={{background:"#fff!important"}}>
                       
                       </Grid>
          <Avatar className="avatop avatop2" alt="avatar usuario"  imgProps={{referrerPolicy:"no-referrer"}}  src={user.profilePicture} />
          </>
        ): (<>
            {(load)? (<Avatar className="avatop"
         
         ><CircularProgress size="20px"/></Avatar>):(<Avatar className="avatop"
          {...stringAvatar(user.empresa ? capitalizar(user.empresa):(capitalizar(user.firstname) + " " + capitalizar(user.lastname).split(" ")[0]))}
          ></Avatar>)}
          </>)
          
        ))}
    
        <ExpandMoreIcon
            sx={{
              marginLeft: theme.spacing(1 / 4),
              width: 16,
              height: 16,
              transition: "transform 200ms ease",
              transform: openedPopoverId !==null  ? "rotate(180deg)" : "none",
              color: "black",
              display:"none"
            }}
            className="gridtopbar2"
          />
      </Button>
            
            <Grid marginLeft={4} sx={{display:"flex"}}>
            
           


            <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'paddingarribayabajo',
        }}
        elevation={3}
        
        className="transformation1"
        sx={{
            ".MuiPaper-root": {
           
              padding: "0.5rem 0",
              
             // marginTop: 2,
              border: "1px solid #ebeaeb",
              borderRadius: "8px",
              //width: "220px",
             // minWidth: "unset",
              mountOnEnter:true, 
              unmountOnExit:true
              
            }
        }}
      
      >
       
          <>
            <MenuItem component={Link} to={user.tipoCuenta==="persona"? "/perfil" :  "/perfil/empresas"} onClick={user.tipoCuenta==="persona"?userMenuClose:
            ()=>{setSelectedTab(0)
            userMenuClose()}} role="button" className="coloreMenu" sx={{minHeight:"24px!important"}}>
             
              <ListItemIcon sx={{minWidth:"4rem"}} className="vero2" >
                {user && user.tipoCuenta==="persona" ? <AccountCircleOutlinedIcon /> :<ApartmentOutlinedIcon /> }
              </ListItemIcon>
              <ListItemText primary={"Perfil"}
              className="coloreMenu"
              primaryTypographyProps={{fontSize:"1rem", lineHeight:1.50}}
               />
            </MenuItem>
            {user && user.tipoCuenta==="persona" && (
              <MenuItem component={Link} to={ "/perfil/vista-empresa" } onClick={userMenuClose} role="button" className="coloreMenu"  sx={{minHeight:"24px!important"}}>
              <ListItemIcon sx={{minWidth:"4rem"}} className="vero2" >
                <RemoveRedEyeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Reclutadores"} />
            </MenuItem>
            )}

            {user && user.tipoCuenta==="empresa" && (
              <MenuItem component={Link} to={ "/perfil/equipo" } onClick={()=>{
                setSelectedTab(1)
                userMenuClose()}} role="button" className="coloreMenu"  sx={{minHeight:"24px!important"}}>
              <ListItemIcon sx={{minWidth:"4rem"}} className="vero2" >
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Equipo"} />
            </MenuItem>
            )}
            
            <MenuItem component={Link} to={user.tipoCuenta==="persona"? "/preferencias/general" :  "/preferencias/general"} onClick={userMenuClose} role="button" className="coloreMenu" sx={{minHeight:"24px!important"}}>
              <ListItemIcon sx={{minWidth:"4rem"}} className="vero2" >
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={user.tipoCuenta ==="persona" ? "Preferencias" : "Preferencias"} />
            </MenuItem>
            <MenuItem component={Link} to={user.tipoCuenta==="persona"? "/ayuda" :  "/ayuda"} onClick={userMenuClose} role="button" className="coloreMenu" sx={{minHeight:"24px!important"}}>
              <ListItemIcon sx={{minWidth:"4rem"}} className="vero2">
                <HelpOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Ayuda" />
            </MenuItem >
            <Divider sx={{ml:"16px", mr:"16px"}} />
            <MenuItem
              component={Button}
             // to="/sign-out"
              onClick={() => {
                userMenuClose();
                onLogout();
              }}
              role="button"
              className="coloreMenu"
              sx={{minHeight:"24px!important"}}
            >
              <ListItemIcon sx={{minWidth:"4rem"}}>
                <LogoutOutlinedIcon  />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </MenuItem>
          </>
     
      </Popover>

          </Grid>
            {/*    </>
          
        ) : (
          <>
            <Box marginLeft={4}>
              <Button
                variant="outlined"
                color="primary"
                target="blank"
                onClick={onLogout}
                size="large"
                sx={{width: 150}}
              >
                Logout
              </Button>
            </Box>on
          </>
        )} */}
          </Box>
         
        </Box>
 {/*       ) : (
        <>
          <Box marginLeft={4}>
            <Button
              //variant="outlined"
              //color="primary"
            //  target="blank"
              onClick={onLogout}
             // size="large"
              sx={{ width: 150 }}
              className="boton-registro"
            >
              Cerrar sesión
            </Button>
          </Box>
        </>
      )}  */}
    </>
  );
};

export default Topbar2;
