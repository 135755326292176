import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'
// import 'react-toastify/dist/ReactToastify.css'
import Header2 from './components/Header2/Header2'
// import Dashboard from './pages/Dashboard'
import Page from './components/Varios/Page'
import './index.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-perfect-scrollbar/dist/css/styles.css";
// import Login3 from './pages/Login3'
// import Register2 from './pages/Register2'
// import Empresas from "./pages/Empresas"
// import Home from "./pages/Home"
import {GoogleOAuthProvider} from "@react-oauth/google"
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
// import OnboardProv2 from './pages/OnboardProv2'
// import Perfil from './pages/Perfil'
// import OnboardEmp from './pages/OnboardEmp'
// import PerfilEmp from './pages/PerfilEmp'
import {       useEffect, useRef, useState } from 'react'
// import NoMatch from './pages/NoMatch'
// import RegisterEquipo from './pages/RegisterEquipo'
// import OlvidoContrasena from './pages/OlvidoContrasena'
// import RecuperaContrasena from './pages/RecuperaContrasena'
// import PerfilVistaEmpresa from './pages/PerfilVistaEmpresa'
// import Preferencias from './pages/Preferencias'
// import Ayuda from './pages/Ayuda'
// import Faq from './pages/Faq'
import SearchAyuda from './components/Ayuda/SearchAyuda'
// import Planes from './pages/Planes'
// import Cookies from './pages/Cookies'
// import Privacidad from './pages/Privacidad'
// import BusquedaCandidatos from './pages/BusquedaCandidatos'
// import Invitaciones from './pages/Invitaciones'
// import Contactos from './pages/Contactos'
// import Mensajes from './pages/Mensajes'
import { SnackbarProvider } from 'notistack'
// import Proximamente from './pages/Proximamente'
// import Contacto2 from './pages/Contacto2'
// import Nosotros from './pages/Nosotros'
// import Trabajos from './pages/Trabajos'
// import Blog from './pages/Blog'
// import Articulo from './components/Blog/Articulo'
// import blog from './utils/lists/blog'
// import TerminosYCondiciones from './pages/TerminosYCondiciones'
// import Redes from './pages/Redes'
// import Noticias from './pages/Noticias'
// import Lanzamiento from './components/Noticias/Lanzamiento'
// import BlogNoticia from './components/Noticias/BlogNoticia'
// import RedesDeContacto from './components/Noticias/RedesDeContacto'
// import InvitacionesYMensajes from './components/Noticias/InvitacionesYMensajes'
// import MediosDePago from './components/Noticias/MediosDePago'
// import AyudaNoticia from './components/Noticias/AyudaNoticia'
// import NoticiasHistoria from './components/Noticias/NoticiasHistoria'
import { ModalProvider } from './composables/ModalProvider';
import { FallbackProvider } from './composables/FallbackProvider';
//  import Reclutadores from './pages/Reclutadores'
//  import BufferArticulo from "./components/Blog/BufferArticulo"
//  import PaginaReset from './components/Varios/PaginaReset'
// import { lazy, Suspense } from 'react';
/* import { useAppScrollLock } from './hooks/useAppScrollLock' */

/* import socketIO from "socket.io-client"

const socket = socketIO.connect("http://localhost:5000")
 */

// import loadable from '@loadable/component'
// import pMinDelay from 'p-min-delay'

// import Loadable from 'react-loadable';


// function Loading(props) {
//   if (props.error) {
//     return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
//   } else if (props.pastDelay) {
//     return <div>Loading...</div>;
//   } else {
//     return null;
//   }
// }
// import 'nprogress/nprogress.css';


//  import loadable from "@loadable/component";
// import { PrerenderedComponent } from "react-prerendered-component";
// import SkeletonPerfil from './components/Perfil/SkeletonPerfil';
// import Spinner4 from './components/Varios/Spinner4';
// import SkeletonVarios from './components/Varios/SkeletonVarios';
// import { useSelector } from 'react-redux';
//import BufferArticulo from './components/Blog/BufferArticulo';
 import {lazy} from "@loadable/component";
// import { PrerenderedComponent } from "react-prerendered-component";
 // import {lazy} from 'react-imported-component'

import { PrerenderedComponent } from 'react-prerendered-component';
// const prerendered = dynamicImport => {
//   const LoadableComponent = loadable(dynamicImport);
//   return memo(props => (
//     // you can use the `.preload()` method from react-loadable or react-imported-component`
//     <PrerenderedComponent live={LoadableComponent.preload()}>
//       <LoadableComponent {...props} />
//     </PrerenderedComponent>
//   ));
// };



// const prerendered = dynamicImport => {
//   const LoadableComponent = loadable(dynamicImport);
//   return memo(props => (
//     // you can use the `.preload()` method from react-loadable or react-imported-component`
//       <PrerenderedComponent live={LoadableComponent.load()}>
//       <LoadableComponent {...props} />
    
//       </PrerenderedComponent>
//   ));
// };

// const prefetchMap = new WeakMap();
// const prefetchLazy = LazyComponent => {
//   if (!prefetchMap.has(LazyComponent)) {
//     prefetchMap.set(LazyComponent, LazyComponent._ctor());
//   }
//   return prefetchMap.get(LazyComponent);
// };

// const prerenderedLazy = dynamicImport => {
//   const LazyComponent = lazy(dynamicImport);
//   return memo(props => (
//     <PrerenderedComponent live={()=>prefetchLazy(LazyComponent)}>
//       <LazyComponent {...props} />
//     </PrerenderedComponent>
//   ));
// };



 //const SearchAyuda = lazy(()=>('./components/Ayuda/SearchAyuda')) 

// const history = createBrowserHistory();

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Home = lazy(() => import("./pages/Home"));

 const Perfil = lazy(() => import("./pages/Perfil"));
 const PerfilVistaEmpresa = lazy(() => import("./pages/PerfilVistaEmpresa"));
 const Login3 = lazy(() => import("./pages/Login3"));
 const OnboardProv2 = lazy(() => import("./pages/OnboardProv2"));
 const OnboardEmp = lazy(() => import("./pages/OnboardEmp"));
 const PerfilEmp = lazy(() => import("./pages/PerfilEmp"));
 const Register2 = lazy(() => import("./pages/Register2"));
 const Empresas = lazy(() => import("./pages/Empresas"));
 const Preferencias = lazy(() => import("./pages/Preferencias"));
 const OlvidoContrasena = lazy(() => import("./pages/OlvidoContrasena"));
 const RegisterEquipo = lazy(() => import("./pages/RegisterEquipo"));
 const RecuperaContrasena = lazy(() => import("./pages/RecuperaContrasena"));
 const Ayuda = lazy(() => import("./pages/Ayuda"));
 const Blog = lazy(() => import("./pages/Blog"));
const Planes = lazy(() => import("./pages/Planes"));
 const Faq = lazy(() => import("./pages/Faq"));
 const Privacidad = lazy(() => import("./pages/Privacidad"));
 const TerminosYCondiciones = lazy(() => import("./pages/TerminosYCondiciones"));
 const Cookies = lazy(() => import("./pages/Cookies"));
 const Invitaciones = lazy(() => import("./pages/Invitaciones"));
 const Mensajes = lazy(() => import("./pages/Mensajes"));
 const Contactos = lazy(() => import("./pages/Contactos"));
 const Redes = lazy(() => import("./pages/Redes"));
 const Nosotros = lazy(() => import("./pages/Nosotros"));
 const Trabajos = lazy(() => import("./pages/Trabajos"));
 const Noticias = lazy(() => import("./pages/Noticias"));
 const Reclutadores = lazy(() => import("./pages/Reclutadores"));

 const Contacto2 = lazy(() => import("./pages/Contacto2"));
 const BusquedaCandidatos = lazy(() => import("./pages/BusquedaCandidatos"));
 const Proximamente = lazy(() => import("./pages/Proximamente"));
 const NoMatch = lazy(() => import("./pages/NoMatch"));

 const Lanzamiento = lazy(()=>import("./components/Noticias/Lanzamiento"))
 const BlogNoticia = lazy(()=>import("./components/Noticias/BlogNoticia"))
 const RedesDeContacto = lazy(()=>import("./components/Noticias/RedesDeContacto"))
 const InvitacionesYMensajes = lazy(()=>import("./components/Noticias/InvitacionesYMensajes"))
 const MediosDePago = lazy(()=>import("./components/Noticias/MediosDePago"))
 const AyudaNoticia = lazy(()=>import("./components/Noticias/AyudaNoticia"))
 const NoticiasHistoria = lazy(()=>import("./components/Noticias/NoticiasHistoria"))

 const BufferArticulo = lazy(()=>import("./components/Blog/BufferArticulo"))


function App() {
/*   useAppScrollLock() */
const [isMounted, setIsMounted] = useState(false);
  
useEffect(() => {
  
  setIsMounted(true);
 
}, []);


  const [selectedTab, setSelectedTab] = useState(0);
  const [cuenteque, setCuenteque] = useState(true);
  const [openDropElim, setOpenDropElim] = useState(false);
  // const [flag, setFlag] = useState(false);
  const [suscr, setSuscr]= useState(false)
  const [openedMen, setOpenedMen]=useState({})
  const refHeader = useRef(null)

  // const { user } = useSelector((state) => state.auth);
  

  return ( 
  isMounted? <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <SnackbarProvider>
    <Page> 
      <Router>
        
          <Header2 selectedTab={selectedTab} setSelectedTab={setSelectedTab} refer={refHeader} openedMen={openedMen} setOpenedMen={setOpenedMen}>
    <ModalProvider>
          <FallbackProvider>      
          <Routes>
          {/* <Route exact path="/linkedin" element={<LinkedInCallback/>}/>
            <Route path='/' element={<Home openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/>} />
            <Route path='/dashboard' element={<Dashboard suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/perfil' element={<Perfil  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/perfil/vista-empresa' element={<PerfilVistaEmpresa  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/login' element={<Login3  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/perfil/onboard' element={<OnboardProv2 cuenteque={cuenteque} setCuenteque={setCuenteque}  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/perfil/empresas/onboard' element={<OnboardEmp cuenteque={cuenteque} setCuenteque={setCuenteque}  suscr={suscr} setSuscr={setSuscr} />} />
            <Route path='/perfil/empresas' element={<PerfilEmp 
              dDonde={0} selectedTab={selectedTab} setSelectedTab={setSelectedTab}  suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}
            />} />
            <Route path='/perfil/equipo' element={<PerfilEmp 
              dDonde={1} selectedTab={selectedTab} setSelectedTab={setSelectedTab}  suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}
            />} />
            <Route path='/registrarse' element={<Register2  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/empresas' element={<Empresas  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/preferencias/general' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel1"/>} />
            <Route path='/preferencias/suscripcion' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel2" flagValor={false} suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/preferencias/pago' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel2" flagValor={true}/>} />
            <Route path='/preferencias/privacidad' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel3"/>} />
            <Route path='/preferencias/notificaciones' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel4"/>} />
            <Route path='/preferencias/contrasena' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel5"/>} />
            <Route path='/preferencias/eliminar/cuenta' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel6" openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/>} />
            <Route path='/preferencias/eliminar/empresa' element={<Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel7" openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/>} />
            <Route path='/olvidaste-contrasena' element={<OlvidoContrasena  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/registrarse/empresas/equipo/:onempresasId/:email/:cuenta' element={<RegisterEquipo  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/olvidaste-contrasena/recuperar/:reset/:email/:fecha' element={<RecuperaContrasena  suscr={suscr} setSuscr={setSuscr}/>} />
            <Route path='/ayuda' element={<Ayuda/>} />
            <Route path='/contacto' element={<Contacto2/>} />
            <Route path='/faq' element={<Faq/>} />
            <Route path='/ayuda/busqueda' element={<SearchAyuda/>} />
            <Route path='/planes' element={<Planes/>} />
            <Route path='/cookies' element={<Cookies/>} />
            <Route path='/privacidad' element={<Privacidad/>} />
            <Route path='/terminos-y-condiciones' element={<TerminosYCondiciones/>} />
            <Route path='/busqueda-candidatos'  element={<BusquedaCandidatos suscr={suscr} setSuscr={setSuscr} openedMen={openedMen} setOpenedMen={setOpenedMen}/>}  />
        
            <Route path='/invitaciones/aceptadas' element={<Invitaciones  suscr={suscr} setSuscr={setSuscr} activo="aceptadas" openedMen={openedMen} setOpenedMen={setOpenedMen}/>}/>
            <Route path='/invitaciones/ignoradas'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="ignoradas" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/pendientes' element={<Invitaciones  suscr={suscr} setSuscr={setSuscr} activo="pendientes" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/etiquetas/:etiquetas'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="etiquetas" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />

            <Route path='/invitaciones/entrevistas' element={<Invitaciones suscr={suscr} setSuscr={setSuscr}  activo="entrevistas" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/procesos'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="procesos" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/perfil'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="perfil" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/otros'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="otros" openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/enviadas'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="enviadas"  openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />
            <Route path='/invitaciones/recibidas-redes'  element={<Invitaciones suscr={suscr} setSuscr={setSuscr} activo="recibidas-redes"  openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />

            <Route path='/contactos'  element={<Contactos suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />

            <Route path='/mensajes/bandeja-de-entrada' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="bandeja"/>} />
            <Route path='/mensajes/spam' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="spam"/>} />
            <Route path='/mensajes/no-leidos' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="no-leidos"/>} />
            <Route path='/mensajes/eliminados' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="eliminados"/>} />
            <Route path='/mensajes/favoritos' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="favoritos"/>} />
            <Route path='/mensajes/importantes' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="importantes"/>} />
            <Route path='/mensajes/etiquetas/equipo' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="equipo"/>} />
            <Route path='/mensajes/etiquetas/candidatos' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="candidatos"/>} />
            <Route path='/mensajes/etiquetas/redes' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="redes"/>} />
            <Route path='/mensajes/etiquetas/reclutadores' element={<Mensajes suscr={suscr} setSuscr={setSuscr} activo="reclutadores"/>} />
            
            <Route path='/redes' element={<Redes suscr={suscr} setSuscr={setSuscr} openedMen={openedMen} setOpenedMen={setOpenedMen}/>} />

            <Route path='/quienes-somos' element={<Nosotros/>} />
            <Route path='/trabajos' element={<Trabajos/>} />
            <Route path='/blog' element={<Blog/>} />
            <Route path='/noticias' element={<Noticias/>} />
            <Route path='/guias' element={<Proximamente/>} />

            <Route path='/noticias/lanzamiento' element={<Lanzamiento/>} />
            <Route path='/noticias/blog' element={<BlogNoticia/>} />
            <Route path='/noticias/redes' element={<RedesDeContacto/>} />
            <Route path='/noticias/invitaciones-mensajes' element={<InvitacionesYMensajes/>} />
            <Route path='/noticias/medios-de-pago' element={<MediosDePago/>} />
            <Route path='/noticias/ayuda' element={<AyudaNoticia/>} />
            <Route path='/noticias/historia' element={<NoticiasHistoria/>} />
            <Route path='/reclutadores' element={<Reclutadores/>} />
            <Route path={"/blog/tendencias-mercado-laboral"} 
            element={<BufferArticulo
              index={0}
            />} />

<Route path={"/blog/beneficios"} 
            element={<BufferArticulo
              index={1}
              />} />

<Route path={"/blog/destaca-tu-perfil"} 
            element={<BufferArticulo
              index={2}
              />} />

<Route path={"/blog/entrevista-virtual"} 
            element={<BufferArticulo
              index={3}
              />} />
<Route path={"/blog/errores-comunes"} 
            element={<BufferArticulo
              index={4}
              />} />

<Route path={"/blog/transicion"} 
          element={<BufferArticulo
              index={5}
              />} />

<Route path={"/blog/que-es-el-reclutamiento-inverso"} 
             element={<BufferArticulo
              index={6}
              />} />

<Route path={"/blog/proposito"} 
            element={<BufferArticulo
              index={7}
              />} />

<Route path={"/blog/negociar-sueldo"} 
            element={<BufferArticulo
              index={8}
              />} />

<Route path={"/blog/habilidades-valoradas"} 
          element={<BufferArticulo
              index={9}
              />} />
            

            <Route path={"/blog/head-hunters-rekloot"} 
          element={<BufferArticulo
              index={10}
              />} />

<Route path={"/blog/importancia-redes"} 
        element={<BufferArticulo
              index={11}
              />} />

<Route path={"/blog/marca-personal"} 
         element={<BufferArticulo
              index={12}
              />} />

<Route path={"/blog/inteligencia-artificial"} 
     element={<BufferArticulo
              index={13}
              />} /> */}


          <Route exact path="/linkedin" element={<LinkedInCallback/>}/>
            <Route path='/' element={<PrerenderedComponent live={Home.load()}><Home openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/></PrerenderedComponent>} />
            <Route path='/dashboard' element={<PrerenderedComponent live={Dashboard.load()}><Dashboard suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/perfil' element={<PrerenderedComponent live={Perfil.load()}><Perfil  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/perfil/vista-empresa' element={<PrerenderedComponent live={PerfilVistaEmpresa.load()}><PerfilVistaEmpresa  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/login' element={<PrerenderedComponent live={Login3.load()}><Login3  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/perfil/onboard' element={<PrerenderedComponent live={OnboardProv2.load()}><OnboardProv2 cuenteque={cuenteque} setCuenteque={setCuenteque}  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/perfil/empresas/onboard' element={<PrerenderedComponent live={OnboardEmp.load()}><OnboardEmp cuenteque={cuenteque} setCuenteque={setCuenteque}  suscr={suscr} setSuscr={setSuscr} /></PrerenderedComponent>} />
            <Route path='/perfil/empresas' element={<PrerenderedComponent live={PerfilEmp.load()}><PerfilEmp 
              dDonde={0} selectedTab={selectedTab} setSelectedTab={setSelectedTab}  suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}
            /></PrerenderedComponent>} />
            <Route path='/perfil/equipo' element={<PrerenderedComponent live={PerfilEmp.load()}><PerfilEmp 
              dDonde={1} selectedTab={selectedTab} setSelectedTab={setSelectedTab}  suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}
            /></PrerenderedComponent>} />
            <Route path='/registrarse' element={<PrerenderedComponent live={Register2.load()}><Register2  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/empresas' element={<PrerenderedComponent live={Empresas.load()}><Empresas  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/preferencias/general' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel1"/></PrerenderedComponent>} />
            <Route path='/preferencias/suscripcion' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel2" flagValor={false} suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/preferencias/pago' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel2" flagValor={true}/></PrerenderedComponent>} />
            <Route path='/preferencias/privacidad' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel3"/></PrerenderedComponent>} />
            <Route path='/preferencias/notificaciones' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel4"/></PrerenderedComponent>} />
            <Route path='/preferencias/contrasena' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel5"/></PrerenderedComponent>} />
            <Route path='/preferencias/eliminar/cuenta' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel6" openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/></PrerenderedComponent>} />
            <Route path='/preferencias/eliminar/empresa' element={<PrerenderedComponent live={Preferencias.load()}><Preferencias cuenteque={cuenteque} setCuenteque={setCuenteque} activo="panel7" openDropElim={openDropElim} setOpenDropElim={setOpenDropElim}/></PrerenderedComponent>} />
            <Route path='/olvidaste-contrasena' element={<PrerenderedComponent live={OlvidoContrasena.load()}><OlvidoContrasena  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/registrarse/empresas/equipo/:onempresasId/:email/:cuenta' element={<PrerenderedComponent live={RegisterEquipo.load()}><RegisterEquipo  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/olvidaste-contrasena/recuperar/:reset/:email/:fecha' element={<PrerenderedComponent live={RecuperaContrasena.load()}><RecuperaContrasena  suscr={suscr} setSuscr={setSuscr}/></PrerenderedComponent>} />
            <Route path='/ayuda' element={<PrerenderedComponent live={Ayuda.load()}><Ayuda/></PrerenderedComponent>} />
            <Route path='/contacto' element={<PrerenderedComponent live={Contacto2.load()}><Contacto2/></PrerenderedComponent>} />
            <Route path='/faq' element={<PrerenderedComponent live={Faq.load()}><Faq/></PrerenderedComponent>} />
            <Route path='/ayuda/busqueda' element={<SearchAyuda/>} />
            <Route path='/planes' element={<PrerenderedComponent live={Planes.load()}><Planes/></PrerenderedComponent>} />
            <Route path='/cookies' element={<PrerenderedComponent live={Cookies.load()}><Cookies/></PrerenderedComponent>} />
            <Route path='/privacidad' element={<PrerenderedComponent live={Privacidad.load()}><Privacidad/></PrerenderedComponent>} />
            <Route path='/terminos-y-condiciones' element={<PrerenderedComponent live={TerminosYCondiciones.load()}><TerminosYCondiciones/></PrerenderedComponent>} />
            <Route path='/busqueda-candidatos'  element={<PrerenderedComponent live={BusquedaCandidatos.load()}><BusquedaCandidatos suscr={suscr} setSuscr={setSuscr} openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>}  />
            <Route path='/invitaciones/aceptadas' element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones  suscr={suscr} setSuscr={setSuscr} activo="aceptadas" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>}/>
            <Route path='/invitaciones/ignoradas'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="ignoradas" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/pendientes' element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones  suscr={suscr} setSuscr={setSuscr} activo="pendientes" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/etiquetas/:etiquetas'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="etiquetas" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />

            <Route path='/invitaciones/entrevistas' element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr}  activo="entrevistas" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/procesos'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="procesos" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/perfil'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="perfil" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/otros'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="otros" openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/enviadas'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="enviadas"  openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />
            <Route path='/invitaciones/recibidas-redes'  element={<PrerenderedComponent live={Invitaciones.load()}><Invitaciones suscr={suscr} setSuscr={setSuscr} activo="recibidas-redes"  openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />

            <Route path='/contactos'  element={<PrerenderedComponent live={Contactos.load()}><Contactos suscr={suscr} setSuscr={setSuscr}  openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />

            <Route path='/mensajes/bandeja-de-entrada' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="bandeja"/></PrerenderedComponent>} />
            <Route path='/mensajes/spam' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="spam"/></PrerenderedComponent>} />
            <Route path='/mensajes/no-leidos' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="no-leidos"/></PrerenderedComponent>} />
            <Route path='/mensajes/eliminados' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="eliminados"/></PrerenderedComponent>} />
            <Route path='/mensajes/favoritos' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="favoritos"/></PrerenderedComponent>} />
            <Route path='/mensajes/importantes' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="importantes"/></PrerenderedComponent>} />
            <Route path='/mensajes/etiquetas/equipo' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="equipo"/></PrerenderedComponent>} />
            <Route path='/mensajes/etiquetas/candidatos' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="candidatos"/></PrerenderedComponent>} />
            <Route path='/mensajes/etiquetas/redes' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="redes"/></PrerenderedComponent>} />
            <Route path='/mensajes/etiquetas/reclutadores' element={<PrerenderedComponent live={Mensajes.load()}><Mensajes suscr={suscr} setSuscr={setSuscr} activo="reclutadores"/></PrerenderedComponent>} />
            
            <Route path='/redes' element={<PrerenderedComponent live={Redes.load()}><Redes suscr={suscr} setSuscr={setSuscr} openedMen={openedMen} setOpenedMen={setOpenedMen}/></PrerenderedComponent>} />

            <Route path='/quienes-somos' element={<PrerenderedComponent live={Nosotros.load()}><Nosotros/></PrerenderedComponent>} />
            <Route path='/trabajos' element={<PrerenderedComponent live={Trabajos.load()}><Trabajos/></PrerenderedComponent>} />
            <Route path='/blog' element={<PrerenderedComponent live={Blog.load()}><Blog/></PrerenderedComponent>} />
            <Route path='/noticias' element={<PrerenderedComponent live={Noticias.load()}><Noticias/></PrerenderedComponent>} />
            <Route path='/guias' element={<PrerenderedComponent live={Proximamente.load()}><Proximamente/></PrerenderedComponent>} />

            <Route path='/noticias/lanzamiento' element={<PrerenderedComponent live={Lanzamiento.load()}><Lanzamiento/></PrerenderedComponent>} />
            <Route path='/noticias/blog' element={<PrerenderedComponent live={BlogNoticia.load()}><BlogNoticia/></PrerenderedComponent>} />
            <Route path='/noticias/redes' element={<PrerenderedComponent live={RedesDeContacto.load()}><RedesDeContacto/></PrerenderedComponent>} />
            <Route path='/noticias/invitaciones-mensajes' element={<PrerenderedComponent live={InvitacionesYMensajes.load()}><InvitacionesYMensajes/></PrerenderedComponent>} />
            <Route path='/noticias/medios-de-pago' element={<PrerenderedComponent live={MediosDePago.load()}><MediosDePago/></PrerenderedComponent>} />
            <Route path='/noticias/ayuda' element={<PrerenderedComponent live={AyudaNoticia.load()}><AyudaNoticia/></PrerenderedComponent>} />
            <Route path='/noticias/historia' element={<PrerenderedComponent live={NoticiasHistoria.load()}><NoticiasHistoria/></PrerenderedComponent>} />
            <Route path='/reclutadores' element={<PrerenderedComponent live={Reclutadores.load()}><Reclutadores/></PrerenderedComponent>} />
            <Route path={"/blog/tendencias-mercado-laboral"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={0}
            /></PrerenderedComponent>} />

<Route path={"/blog/beneficios"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={1}
            /></PrerenderedComponent>} />

<Route path={"/blog/destaca-tu-perfil"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={2}
            /></PrerenderedComponent>} />

<Route path={"/blog/entrevista-virtual"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={3}
            /></PrerenderedComponent>} />
<Route path={"/blog/errores-comunes"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={4}
            /></PrerenderedComponent>} />

<Route path={"/blog/transicion"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={5}
            /></PrerenderedComponent>} />

<Route path={"/blog/que-es-el-reclutamiento-inverso"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={6}
            /></PrerenderedComponent>} />

<Route path={"/blog/proposito"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={7}
            /></PrerenderedComponent>} />

<Route path={"/blog/negociar-sueldo"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={8}
            /></PrerenderedComponent>} />

<Route path={"/blog/habilidades-valoradas"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={9}
            /></PrerenderedComponent>} />
            

            <Route path={"/blog/head-hunters-rekloot"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={10}
            /></PrerenderedComponent>} />

<Route path={"/blog/importancia-redes"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={11}
            /></PrerenderedComponent>} />

<Route path={"/blog/marca-personal"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={12}
            /></PrerenderedComponent>} />

<Route path={"/blog/inteligencia-artificial"} 
            element={<PrerenderedComponent live={BufferArticulo.load()}><BufferArticulo
              index={13}
            /></PrerenderedComponent>} />



            <Route path="*" element= {<NoMatch/>}/>
          </Routes>
 </FallbackProvider>
         </ModalProvider>   
          </Header2>
          
       
      </Router>
      {/* <ToastContainer /> */}
       </Page> 
      </SnackbarProvider>
    </GoogleOAuthProvider> :null
  )
}

export default App
