import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';
import { isMobile } from 'react-device-detect';
import { Grid, IconButton } from '@mui/material';
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Sidebar = ({ pages, open, variant, onClose, user, onLogout, setOpenSidebar }) => {
  return (
    <>
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 300,
        },
      }}
    >
     {isMobile ?( 
      <Box
        sx={{
          height: '100%',
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
      <Grid>
        <SidebarNav pages={pages} user={user} onLogout={onLogout} setOpenSidebar={setOpenSidebar}/>
        </Grid>
      </Box>) : (
        <PerfectScrollbar>
        <Box
        sx={{
          height: '100%',
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
      <Grid>
        <SidebarNav pages={pages} user={user} onLogout={onLogout} setOpenSidebar={setOpenSidebar}/>
        </Grid>
</Box>
        </PerfectScrollbar>
      )}
    </Drawer>
    <Grid sx={{position:"absolute", top:"0px", left:"50px"}}

            >
    <IconButton onClick={()=>{
      setOpenSidebar(false)
    }} size="large" className='pruee2' sx={{display:open?("inline-flex"):("none")}}>
<CloseOutlinedIcon sx={{fontSize:"30px"}} />
</IconButton>
</Grid>
    
    </>
  );
};



export default Sidebar;
