import { axiosPrivate } from '../../API/refreshToken'



    const deleteContacto = async (userMail, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response =  axiosPrivate.delete("/api/email/deleteContacto/"+userMail, config)
      
        return response.data
      }


      const deleteLink = async (link, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.delete("api/link/delete/"+link, config)
      
        return response.data
      }

      const postDelete = async (deleteData, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.post("/api/delete/", deleteData, config)
      
        return response.data
      }

      const postDeleteOtros = async (deleteData, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.post("/api/delete/otros/", deleteData, config)
      
        return response.data
      }

      const deleteSusmedio = async (userId, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.delete("/api/webpay/deleteSusmedio/"+userId, config)
      
        return response.data
      }

      const deleteLinkInvita = async (onempresasId, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.delete("/api/link/delete/invita/"+onempresasId, config)
      
        return response.data
      }

      const deleteLinkInvitaOtros = async (email, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.delete("/api/link/delete/"+email, config)
      
        return response.data
      }

      const deleteFileFolder = async (token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
        const response = await axiosPrivate.post("/api/files/upload/deleteFileFolder/",{}, config)
      
        return response.data
      }

      const deleteService = {
        deleteContacto,
        deleteLink,
        deleteSusmedio,
        postDelete,
        postDeleteOtros,
        deleteLinkInvita,
        deleteLinkInvitaOtros,
        deleteFileFolder,
      }

      export default deleteService;