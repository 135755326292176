import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import rekloot from "../../../../../../utils/icons/rekloot.webp";
import { useNavigate } from 'react-router-dom';
import {  useDispatch } from 'react-redux';
import { logout } from '../../../../../../features/auth/authSlice';

import NavItem from './components/NavItem';

const SidebarNav = ({ pages, user, setOpenSidebar }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    Candidatos: candidatos,
    Reclutadores: reclutadores,
    Nosotros: nosotros,
  } = pages;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* const { user } = useSelector((state) => state.auth); */

/*   const updateAndThenLogout = () => async (dispatch) => {
    return await dispatch(logout());
  }; */


  const onLogout = () => {
    
    dispatch(logout());
   /*  dispatch(reset()); */
    navigate("/");
  };

  return (
    <Box >
      <Box width={1} sx={{paddingLeft:"24px", paddingRight:"24px", }} paddingY={1} >
        <Box
          display={'flex'}
          component="a"
          onClick={()=>{
                  navigate("/")
                  setOpenSidebar(false)
                }}
          title="Rekloot"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? rekloot
                : rekloot
            }
            height={1}
            width={1}
            alt='rekloot'
          />
        </Box>
      </Box>
      <Box paddingX={0} paddingY={2}>
     
      
          <NavItem 
          title={'Para Candidatos'} 
          items={candidatos} 
          setOpenSidebar={setOpenSidebar}
          paneleo="panel1" />
        

       
          <NavItem title={'Para Empresas'} items={[reclutadores[0]]} setOpenSidebar={setOpenSidebar} paneleo="panel2"/>
        
    
          <NavItem title={'Nosotros'} items={nosotros} setOpenSidebar={setOpenSidebar} paneleo="panel3"/>
       
        
            {user === null ? (<>
            <Box marginTop={2} paddingX={2}>
          <Button
           // size='large'
          //  variant="outlined"
            fullWidth
           // component="a"
           onClick={()=>{
                  navigate("/login")
                  setOpenSidebar(false);
                }}
            className="boton-loginN"
          >
            Inicia Sesión
          </Button>
        </Box >
        <Box marginTop={1} paddingX={2}>
          <Button
           // size='large'
           // variant="contained"
           // color="primary"
            fullWidth
           // component="a"
           // target="blank"
           onClick={()=>{
                  navigate("/registrarse")
                  setOpenSidebar(false);
                }}
            className="boton-registroN"
          >
           Regístrate
          </Button>
        </Box></>) : 
            (<>
            <Box marginTop={2}>
              <Button
               // variant="outlined"
               // color="primary"
               // target="blank"
                onClick={onLogout}
               // size={"large"}
                fullWidth
                className="boton-registro"
              >
                Cerrar sesión
              </Button>
            </Box>
          </>)}
       
      </Box>
    </Box>
  );
};


export default SidebarNav;
