import {
  Button,
  Grid,
  Input,
  InputAdornment,
  Pagination,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import dataSearch from "./data";
import { motion } from "framer-motion";
import { blue, green } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import { ellipsis } from "polished";
import usePagination from "./usePagination";
import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import FooterPequeño from "../Footer/FooterPequeño";
import {
  Link,
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { matchSorter } from "match-sorter";
import { Helmet } from "react-helmet";
import FallbackPageWrapper from "../../composables/FallbackPageWrapper";
import "./ayuda.css"

function SearchAyuda() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();

  const SEARCH = searchParams.get("search");

  const [searchInput, setSearchInput] = useState(SEARCH);

  const handleChangeBusca = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    setPage(1);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setSearchInput(e.target.value);
      navigate({
        pathname: "/ayuda/busqueda/",
        search: "?search=" + searchInput,
      });
      setPage(1);
      window.scrollTo(0, 0);
    }
  };

  const { user } = useSelector(
    (state) => state.auth
  );
  /* const [data, setData] = useState([]); */
  const [page, setPage] = useState(1);
  const per_Page = 5;

  const filtrado = dataSearch.filter((itemo) => {
    if (searchInput === "") {
      return itemo;
    } else if (
      itemo?.topico?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      itemo?.question?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
      itemo?.answer?.toLowerCase()?.includes(searchInput?.toLowerCase())
    ) {
      return itemo;
    }
  });

  const filtrado2 = matchSorter(filtrado, searchInput, {
    keys: ["topico", "question", "answer"],
  });
  const count = Math.ceil(filtrado2.length / per_Page);
  const _DATA = usePagination(filtrado2, per_Page);
/*   console.log(filtrado2)
console.log(_DATA) */
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    window.scrollTo(0, 0);
  };

  const navegar = (_item)=>{
    navigate(
        "/faq",
        {state:{id:_item.id , topico:_item.topico, question:_item.question}},
        )
  }

  /*  useEffect(() => {
        setTimeout(() => {
          setData(dataSearch);
        }, 100);
      }, []); */

  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };


    return (
      
        <div
          className={
            user ? "agregueFoot coloreBack" : "agregueFoot2 coloreBack"
          }
        >
        <Helmet>
      <meta
      name="description"
      content="Busca lo que necesitas en nuestro Centro de Ayuda en Rekloot. Busca un tema, revisa nuestras preguntas frecuentes, ve nuestras guías y contáctanos para soporte adicional."
    />
                <title>Rekloot búsqueda centro de ayuda</title>
                <link rel="canonical" href="https://rekloot.com/ayuda/busqueda/" />
            </Helmet>
          <div className="backColorSearch">
            <Grid
              sx={{
                display: "block",
                alignItems: "center",
                flex: "1 1",
                padding: "24px",
                paddingLeft: "4vw",
                paddingRight: "4vw",
                width: "full",
                maxWidth: "640px",
              }}
              className="searchAyuda"
            >
              <Grid className="gridConHelp" sx={{ paddingBottom: "24px" }}>
                <Button
                  className="ContactHelpBot"
                  component={Link}
                  to="/ayuda"
                  sx={{ color: "#004e56" }}
                  disableRipple
                  startIcon={
                    <ArrowBackIcon size={20} sx={{ color: "#004e56" }} />
                  }
                >
                  Volver a Centro de Ayuda
                </Button>
              </Grid>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "16px",
                  height: "44px",
                }}
                className="shadoweo2"
              >
                <Input
                  placeholder="Buscar..."
                  disableUnderline
                  fullWidth
                  inputProps={{
                    "aria-label": "Buscador",
                  }}
                  value={searchInput}
                  onChange={handleChangeBusca}
                  endAdornment={
                    <Tooltip title="Buscar" followCursor arrow>
                      <InputAdornment position="end">
                        <Link
                          className="linkHeight"
                          to={{
                            pathname: "/ayuda/busqueda",
                            search: `?${createSearchParams({
                              search: searchInput,
                            })}`,
                          }}
                        >
                          <SearchIcon color="action" sx={{ height: "24px" }} />
                        </Link>
                      </InputAdornment>
                    </Tooltip>
                  }
                  onKeyDown={keyPress}
                />
                {/*  <SearchIcon color="action" /> */}
              </Paper>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "24px",
                paddingTop: "0px",
                width: "full",
                maxWidth: "640px",
                backgroundColor: "rgb(241, 245, 249)",
              }}
              className="gridSearchde"
            >
              <Grid
                sx={{ display: "flex", flex: "1 1", flexDirection: "column" }}
              >
                {dataSearch.length > 0 && (
                  <motion.div
                    variants={container}
                    initial="hidden"
                    animate="show"
                  >
                    <motion.div variants={item}>
                      <Typography
                      variant="h1"
                        color="text.secondary"
                        sx={{ marginBottom: "24px", fontSize: "13px!important", lineHeight:"1.5!important" }}
                      >
                        {filtrado2.length!== 1 ? filtrado2.length + " resultados" : filtrado2.length + " resultado"}
                      </Typography>
                    </motion.div>

                    {_DATA.currentData().map((_item) => (
                      <motion.div
                        variants={item}
                        className="marginmotionDivSearch"
                        key={_item.id}
                      >
                        {_item.link ? (
                          <Link className="linkeoSearchsinSub" to={_item.link}>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: blue[800],
                              }}
                            >
                              {_item.question}
                            </Typography>
                          </Link>
                        ) : (
                            
                          <Typography
                          onClick={()=>navegar(_item)}
                            sx={{
                              fontSize: "18px",
                              cursor: "pointer",
                              color: blue[800],
                            }}
                          >
                            {_item.question}
                          </Typography>
                         
                        )}

                        <Typography
                          sx={{
                            color: green[800],
                          }}
                        >
                          {_item.topico}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "13px" }}
                          style={ellipsis(undefined, 2)}
                        >
                          {_item.answer}
                        </Typography>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                <Pagination
                  count={count}
                  color="secondary"
                  page={page}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
          {user ? <FooterPequeño /> : <Footer />}
        </div>
        
    );
 
}

export default SearchAyuda;
