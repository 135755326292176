import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import rekloot from "../../../../../../utils/icons/rekloot.webp";

import NavItem2 from './components/NavItem/NavItem2';

function capitalizar(string) {
  /* console.log(string) */
  const words = string.trim().split(" ");
  
  for (let i = 0; i < words.length; i++) {
    if(words[i]==="de" || words[i]==="la" || words[i]==="las"|| words[i]==="lo" || words[i]==="los"|| words[i]===" " || words[i]==="" || words[i]==="en" || words[i]==="y" || words[i]==="del"){
      
    }

    else if(words[i]==="De" || words[i]==="La" || words[i]==="Las"|| words[i]==="Lo" || words[i]==="Los" || words[i]==="En" || words[i]==="Y" || words[i]==="Del"){
      words[i] = words[i][0].toLowerCase() + words[i].substr(1);
    }

    else{
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
     
  }
  
  return words.join(" ");
}


const SidebarNav2 = ({ pages, user, setOpenSidebar, stateDrawer, setStateDrawer }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    Candidatos: candidatos,
    Notificaciones: notificaciones,
    Perfil: perfil,
    Perfil2: perfil2,
    BuscarCandidatos: buscarCandidatos,
    Invitaciones: invitaciones,
    Contactos: contactos,
    Mensajes:mensajes,
    Dash:dashboard,
    Redes:redes,
  } = pages;

  const navigate = useNavigate();
  /* const { user } = useSelector((state) => state.auth); */


/*   const updateAndThenLogout = (params) => async (dispatch) => {
    return await dispatch(logout(params));
  }; */


  return (
    <Box >
  
      <Box width={1} sx={{paddingLeft:"24px", paddingRight:"24px"}} paddingY={1} >
        <Box
          display={'flex'}
          component="a"
          onClick={()=>{
                  navigate("/")
                  setOpenSidebar(false)
                }}
          title="Rekloot"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? rekloot
                : rekloot
            }
            height={1}
            width={1}
            alt='rekloot'
          />
        </Box>
      </Box>
      <Box paddingX={0} paddingY={2}>
     
  
          <NavItem2 
          title={user?"¡Hola "+capitalizar(user.firstname.trim(" "))+"!":"Perfil"} 
          items={user.tipoCuenta==="persona"? perfil: perfil2} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel1"}
          perfil={true}
          user={user}
          />

         
<NavItem2 
          title={'Dashboard'} 
           items={dashboard} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel8"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
        {user && user.tipoCuenta==="empresa" &&
        <NavItem2 
          title={'Buscar Candidatos'} 
           items={buscarCandidatos} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel4"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
        }
        <NavItem2 
          title={'Invitaciones'} 
           items={invitaciones} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel5"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
          <NavItem2 
          title={'Mensajes'} 
           items={mensajes} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel7"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />

<NavItem2 
          title={'Contactos'} 
           items={contactos} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel6"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
        {user && user.tipoCuenta==="persona" &&
        <NavItem2 
          title={'Redes'} 
           items={redes} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel9"}
          perfil={false}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
        }
        <NavItem2 
          title={'Notificaciones'} 
           items={notificaciones} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel2"}
          perfil={false}
          stateDrawer={stateDrawer}
          setStateDrawer={setStateDrawer}
          noto={true}
        // icon={<HelpOutlineIcon sx={{margin:"auto", marginRight:"8px", marginLeft:"0", color:"#241c15", size:"40px"}}/>}
          />
          {/* <NavItem2 
          title={'Candidatos'} 
          items={candidatos} 
          setOpenSidebar={setOpenSidebar} 
          paneleo={"panel3"}
          perfil={false}
          /> */}
          
        {/* <Box>
          <NavItem title={'Para Candidatos'} items={candidatos} />
        </Box>

        <Box>
          <NavItem title={'¡Quiero Contratar!'} items={reclutadores} />
        </Box>
        <Box>
          <NavItem title={'Nosotros'} items={nosotros} />
        </Box>
         */}
         {/*    {user === null ? (<>
            <Box marginTop={2}>
          <Button
           // size='large'
          //  variant="outlined"
            fullWidth
           // component="a"
            href="/login"
            className="boton-login"
          >
            Iniciar Sesión
          </Button>
        </Box>
        <Box marginTop={1}>
          <Button
           // size='large'
           // variant="contained"
           // color="primary"
            fullWidth
           // component="a"
           // target="blank"
            href="/registrarse"
            className="boton-registro"
          >
            Registrarse
          </Button>
        </Box></>) : 
            ( */}
       
      </Box>
      
    </Box>
  );
};


export default SidebarNav2;
