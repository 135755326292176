import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import onboardReducer from "../features/onboard/onboardSlice"
import onempresasReducer from "../features/onempresas/onempresasSlice"
import notificacionesReducer from "../features/notificaciones/notificacionesSlice"
import susMedioReducer from "../features/susMedio/susMedioSlice"
import busquedasReducer from "../features/busquedas/busquedasSlice"
import invitacionesReducer from "../features/invitaciones/invitacionesSlice"
import deleteReducer from "../features/delete/deleteSlice"
import contactosReducer  from "../features/contactos/contactosSlice"
import mensajesReducer from "../features/mensajes/mensajesSlice"
import notosReducer from "../features/notos/notosSlice"
import dashboardReducer from "../features/dashboard/dashboardSlice"
import redesReducer from "../features/redes/redesSlice"

// const preloadedState = window.__PRELOADED_STATE__

// delete window.__PRELOADED_STATE__


export const store = configureStore({
  reducer: {
    auth: authReducer,
    onboard: onboardReducer,
    onempresas: onempresasReducer,
    notificaciones: notificacionesReducer,
    susMedio: susMedioReducer,
    busquedas: busquedasReducer,
    invitaciones: invitacionesReducer,
    delete: deleteReducer,
    contactos: contactosReducer,
    mensajes: mensajesReducer,
    notos: notosReducer,
    dashboard: dashboardReducer,
    redes:redesReducer,
  },
  // preloadedState,
})

 
  // window.snapSaveState = () => ({
  //   __PRELOADED_STATE__: store.getState()
  // });
