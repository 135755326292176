import axios from "axios";
import jwt_decode from "jwt-decode";
import {  refreshToken } from "../features/auth/authSlice";


  let store;

  export const injectStore = _store => {
    store = _store
  }


export const axiosPrivate = axios.create();
/* console.log(store) */
// If Access Token Is expired => Get New Access Token Based On Refresh Token
axiosPrivate.interceptors.request.use(async (config) => {
 /*  const { user2 } = useSelector((state) => state.auth)
  const [user, setUser] = useState(user2); */
  //const dispatch = useDispatch();
  /* const user = useSelector((state) => state.auth).isLoading;
 */

/*   let user = JSON.parse(
    localStorage.getItem("user") 
  ) */
  let user = store.getState().auth.user;

/*   console.log(user)
 */
  if(!user){
   /*  console.log("no user") */
    localStorage.removeItem('user');
    window.location.href = "/"; 
  }
  
/*  console.log(config.headers.Authorization.substring(7, config.headers.Authorization.length))
 */  /* let token = JSON.parse(
    localStorage.getItem("user") 
  ).token; */

  if(!config||!config.headers||!config.headers.Authorization){
   
   localStorage.removeItem('user');
  window.location.href = "/";  
  } 
/*   console.log(config)
  console.log(config.headers)
  console.log(config.headers.Authorization) */
  let token = config.headers.Authorization.split(' ')[1]

   if(!token){
  /*   console.log("no token") */
 localStorage.removeItem('user');
  window.location.href = "/";   
  } 


/*   let refreshToken = JSON.parse(
    localStorage.getItem("user") 
  ).refreshToken; */

/* console.log(user)
console.log(token)
console.log(refreshToken) */

/*   console.log(refreshToken) */

const { exp } = jwt_decode(token);
/* console.log(exp) */

if (exp < (new Date().getTime() + 500 ) / 1000) {
  
  // console.log(refreshToken)
   try {
    /*  console.log("algo pasó") */
     const token2 = await store.dispatch(refreshToken({userId:user._id, remembre:user.rememberMe}));
     /* console.log(token2) */
   /*  console.log("pasótbporaca") */
     if (token2&&token2.payload&&token2.payload.token) {
       config.headers = {
         authorization: `Bearer ${token2.payload.token}`,
       };
       
/*        const token = thunkAPI.getState().auth.user.token
 */       /* store.dispatch(setAuth(token2)) */
/*        localStorage.setItem("user", JSON.stringify({...user, token: token2.token}))
 */       
// ESTE ES EL QUE SIRVE
   /*    await store.dispatch(setActivo(token2.payload.activo)) */
     /*   console.log(token2)
 */
     } else{
     
       localStorage.removeItem('user');
    window.location.href = "/";   
     }
   } catch (error) {
     console.log(error);
   
       localStorage.removeItem('user');
    window.location.href = "/";   
   }
 } else {
  const d = new Date().getTime()
   config.headers = {
     authorization: `Bearer ${token}`,
     d:d,
   };

    
 }
/* console.log(config) */
 return config; 

/* const token2 = await refreshTokenfunc() */

/*   if(!refreshToken) {
    console.log("No refresh token")
    localStorage.removeItem('user');
    window.location.href = "/";
  }

  const { exp } = jwt_decode(token);
  const exp2 = jwt_decode(refreshToken).exp;


  if(exp2 < (new Date().getTime() + 1) / 1000) {
    localStorage.removeItem('user');
    window.location.href = "/";
  }
 

  if (exp < (new Date().getTime() + 1) / 1000) {
   // console.log(refreshToken)
    try {
      console.log("algo pasó")
      const token2 = await refreshTokenfunc(refreshToken);
  
      if (token2) {
        config.headers = {
          authorization: `Bearer ${token2.token}`,
        };
        localStorage.setItem("user", JSON.stringify({...user, token: token2.token}))
        
        store.dispatch(setActivo(token2.activo))
        

      }
    } catch (error) {
      console.log(error);
    }
  } else {
    config.headers = {
      authorization: `Bearer ${token}`,
    };
  }

  return config; */
  
},
(error) => {
console.log(error)
   localStorage.removeItem('user');
    window.location.href = "/";  
    return Promise.reject(error); 
});

/* const refreshTokenfunc = async (userId, remembre) => {

   const response = await axios.post('/api/users/refresh',{userio:userId, remembre: remembre, withCredentials:true})
 
  return response.data
} */