import { axiosPrivate } from '../../API/refreshToken'


const API_URL = "/api/dashboard/";


const getDashboard = async (token, signal) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    };
  
    const response = await axiosPrivate.get(API_URL, config);
   
    return response.data;
  };

  const marcarVisto = async (dashData, token, signal) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    };
  
    const response = /* await */ axiosPrivate.post(API_URL+"/marcarVisto", dashData, config);
   
    return response.data;
  };

  

  const deleteDashboard = async (userId, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  await axiosPrivate.delete(API_URL+"deleteDash/"+userId, config)
  
    return response.data
  }

  const deleteAllDashboard = async (userId, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  await  axiosPrivate.delete(API_URL+"deleteAllDash/"+userId, config)
  
    return response.data
  }


  const dashboardService = {
    getDashboard,
    marcarVisto,
    deleteDashboard,
    deleteAllDashboard,
  }

  export default dashboardService