import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";
import _ from "lodash";

const initialState = {
    dashboard: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message:'',
}

export const getDashboard = createAsyncThunk(
    'dashboard/get',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await dashboardService.getDashboard(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getDashboard2 = createAsyncThunk(
    'dashboard/get2',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await dashboardService.getDashboard(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const marcarVisto = createAsyncThunk(
    'dashboard/marcarVisto',
    async (dashData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await dashboardService.marcarVisto(dashData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteDashboard = createAsyncThunk(
    'dashboard/deleteDashboard',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await dashboardService.deleteDashboard(userId,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteAllDashboard = createAsyncThunk(
    'dashboard/deleteAllDashboard',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await dashboardService.deleteAllDashboard(userId,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const dashboardSlice = createSlice({
    name:'dashboard',
    initialState,
    reducers:{
        reset: (state)=>initialState,
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getDashboard.pending, (state) => {
            state.isLoading = true
          })
          .addCase(getDashboard.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.dashboard = action.payload
          })
          .addCase(getDashboard.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(getDashboard2.pending, (state) => {
            //state.isLoading = true
          })
          .addCase(getDashboard2.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.dashboard = action.payload
          })
          .addCase(getDashboard2.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(marcarVisto.pending, (state) => {
           // state.isLoading = true
          })
          .addCase(marcarVisto.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
           // state.dashboard = action.payload
          })
          .addCase(marcarVisto.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteDashboard.pending, (state) => {
            state.isLoading = true
          })
          
          .addCase(deleteDashboard.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message=action.payload
          })
          .addCase(deleteDashboard.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteAllDashboard.pending, (state) => {
            state.isLoading = true
          })
          .addCase(deleteAllDashboard.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteAllDashboard.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
    }
  })

  export const { reset } = dashboardSlice.actions
  export default dashboardSlice.reducer