const pages = {
  Candidatos: [
    {
      title: "¿Cómo funciona?",
      href: "/como-funciona",
      state:{ state: { targetId: "comoFunca" } },
    },
    {
      title: "¿Porqué Rekloot?",
      href: "/porque",
      state:{ state: { targetId: "porqueRekloot" } },
    },

    {
      title: "Planes",
      href: "/planes",
    },
 

    {
      title: "FAQ",
      href: "/faq",
    },
    
  ],
  Reclutadores: [
    {
      title: "Para empresas",
      href: "/reclutadores",
    },
    {
      title: "¿Porqué Rekloot?",
      href: "/porque",
    },

    {
      title: "Planes",
      href: "/planes",
    },
    {
      title: "Refiere",
      href: "/invita",
    },

    {
      title: "FAQ",
      href: "/faq",
    },
    
  ],

  Nosotros: [
    {
      title: '¿Quiénes somos?',
      href: '/quienes-somos',
    },
    {
      title: 'Blog',
      href: '/blog',
    },
    {
      title: 'Noticias',
      href: '/noticias',
    },
    {
      title: 'Contacto',
      href: '/contacto',
    },

    {
      title: 'Trabaja con Nosotros',
      href: '/trabajos',
    },

    {
      title: 'Ayuda',
      href: '/ayuda',
    },
   
  ],

  Notificaciones: [
    {
      title: "Notificaciones",
      href: "/notificaciones",
    },
    
  ],
  Dash: [
    {
      title: "Dashboard",
      href: "/dashboard",
    },
    
  ],
  BuscarCandidatos: [
    {
      title: "Buscar Candidatos",
      href: "/busqueda-candidatos",
    },
    
  ],
  Invitaciones: [
    {
      title: "Invitaciones",
      href: "/invitaciones/pendientes",
    },
    
  ],
  Contactos: [
    {
      title: "Contactos",
      href: "/contactos",
    },
    
  ],
  Mensajes: [
    {
      title: "Mensajes",
      href: "/mensajes/bandeja-de-entrada",
    },
    
  ],
  Redes: [
    {
      title: "Redes",
      href: "/redes",
    },
    
  ],
  

  Perfil: [
    {
      title: 'Perfil',
      href: '/perfil',
    },
    
    {
      title: 'Vista Reclutadores',
      href: '/perfil/vista-empresa',
    },
    {
      title: 'Preferencias',
      href: '/preferencias/general',
    },
    {
      title: 'Ayuda',
      href: '/ayuda',
    },
    {
      title: 'Cerrar sesión',
      href: '/logout',
    },
   
  ],
  Perfil2: [
    {
      title: 'Perfil',
      href: '/perfil/empresas',
    },
    {
      title: 'Equipo',
      href: '/perfil/equipo',
    },
    {
      title: 'Preferencias',
      href: '/preferencias/general',
    },
   
    {
      title: 'Ayuda',
      href: '/ayuda',
    },
    {
      title: 'Cerrar sesión',
      href: '/logout',
    },
   
  ],
 
};

export default pages;
