import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
//  import { hydrate, render } from "react-dom";
import {injectStore} from "./API/refreshToken" 
import iNoBounce from './hooks/inobounce';



 

// ReactDOM.render(
// //  <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>,
//  // </React.StrictMode>,
//   document.getElementById('root')
// );
injectStore(store); 

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  // delete window.__PRELOADED_STATE__

  // window.snapSaveState = () => ({
  //   __PRELOADED_STATE__: store.getState()
  // });

  
  ReactDOM.hydrate( <Provider store={store}><App /></Provider>, rootElement);

} else {

  injectStore(store); 
 
  ReactDOM.render(<Provider store={store}><App /></Provider>, rootElement);
}

iNoBounce.disable(); 



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
