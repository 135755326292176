import axios from 'axios'
import { axiosPrivate } from '../../API/refreshToken'


const API_URL = '/api/users/'


// Get user
const setActivo = async (pay) => {
 /*  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } */

  return pay
}


/* 
// Get user
const setAuth = async (pay) => {

   return pay
 } */

 // Get user
const getUser = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axiosPrivate.get(API_URL+"me", config)
   
    return response.data
  }


// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData)

  if (response.data) {
    
  
     localStorage.setItem('user', JSON.stringify(response.data))
    const response2 = axios.post("/api/email/contacto", {
      email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
    });
  }

  return response.data
}

// Register user Equipo
const registerEquipo = async (userData) => {
  const response = await axios.post(API_URL+"equipo", userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    const response2 = axios.post("/api/email/contacto", {
      email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
    });
  }
/*   delete response.data.token */
  /* delete response.data.refreshToken */
  return response.data
}

//register LinkedIn
const registerLinkedIn = async (userInfo) => {

  
  const resp2 = await axios.post(API_URL+"getLinkedIn", userInfo).catch(error => {
    console.log(error.response)});
let image
if(resp2?.data?.[2]){
 // console.log(resp2)
  image = await axios.post("/api/files/upload2",{datos:resp2?.data?.[2]})
}

  const {localizedFirstName, localizedLastName, id } = resp2.data[0];

  const genero = await getGenero(localizedFirstName)

 const user = {
  linkedId: id,
  firstname: localizedFirstName,
  lastname: localizedLastName.split(" ")[0],
  email: resp2.data[1],
  tipoCuenta: userInfo.tipoCuenta,
  cuenta: userInfo.cuenta,
  terminoOnboard: userInfo.terminoOnboard,
  profilePicture:image?.data && image?.data,
  genero:genero,
}

/* console.log(user) */

 const response = await axios.post(API_URL, user)
  
/*  console.log(response) */

if (response.data) {

  
  localStorage.setItem('user', JSON.stringify(response.data))
  const response2 = axios.post("/api/email/contacto", {
    email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
  });
}
/* delete response.data.token */
   /* delete response.data.refreshToken */
return response.data
 
  
}

//register LinkedIn
const registerEquipoLinkedIn = async (userInfo) => {

  
  const resp2 = await axios.post(API_URL+"getLinkedIn", userInfo).catch(error => {
    console.log(error.response)});

  // let image
  // if(resp2?.data?.[2]){
  //  // console.log(resp2)
  //   image = await axios.post("/api/files/upload2",{datos:resp2?.data?.[2]})
  // }

/*  console.log(resp2)  */
  const {localizedFirstName, localizedLastName, id } = resp2.data[0];

  const genero = await getGenero(localizedFirstName)

 const user = {
  linkedId: id,
  firstname: localizedFirstName,
  lastname: localizedLastName.split(" ")[0],
  email: resp2.data[1],
  tipoCuenta: userInfo.tipoCuenta,
  hashedCuenta: userInfo.hashedCuenta,
  hashedEmail: userInfo.hashedEmail,
  terminoOnboard: userInfo.terminoOnboard,
  onempresasId: userInfo.onempresasId,
  genero:genero,
}

/* console.log(user) */

 const response = await axios.post(API_URL+"equipo", user)
  
/*  console.log(response) */

if (response.data) {

  
  localStorage.setItem('user', JSON.stringify(response.data))
  const response2 = axios.post("/api/email/contacto", {
    email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
  });
}
/* delete response.data.token */
   /* delete response.data.refreshToken */
return response.data
 
  
}

const getGenero = async (firstname) => {
  
  try{
    if (!firstname) {
     throw new Error("No hay nombre")
    }

    const nombre = firstname.split(" ")[0];

    
    const response = await axios.get(`https://api.genderize.io/?name=${nombre}`);

    return response.data.gender;
  } catch (error) {
    return "error";
  }

};

//loginGoogle
const registerGoogle = async (userInfo) => {
  
  const resp1 = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?access_token="+userInfo.response.access_token) 
/* console.log(resp1) */

  const {given_name, family_name, email, id, picture} = resp1.data;
  let image

// console.log(picture)
  if(picture){
    image = await axios.post("/api/files/upload2",{datos:picture})
  }

  const genero = await getGenero(given_name)

  const user = {
    googleId: id,
    firstname: given_name,
    lastname: family_name,
    email: email,
    tipoCuenta: userInfo.tipoCuenta,
    cuenta: userInfo.cuenta,
    terminoOnboard: userInfo.terminoOnboard,
    profilePicture: image?.data && image?.data,
    genero:genero,
  }
  
/*   console.log(userInfo)
  console.log(resp1) */

  if(user.tipoCuenta === "empresa") {
    if(userInfo.response.hd){
      user["sitioWeb"]=userInfo.response.hd;
     // user["empresa"]=capitalize(userInfo.response.hd.split(".").shift());
    }
   
  }

  const response = await axios.post(API_URL, user)
  
  if (response.data) {
  
    
    localStorage.setItem('user', JSON.stringify(JSON.stringify(response.data)))
    const response2 = axios.post("/api/email/contacto", {
      email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
    });
  }
/*   delete response.data.token */
/*   delete response.data.refreshToken */
  return response.data
};

//loginGoogle
const registerEquipoGoogle = async (userInfo) => {
  
  const resp1 = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?access_token="+userInfo.response.access_token) 
/* console.log(resp1) */
  const {given_name, family_name, email, id, picture} = resp1.data;
  
  const genero = await getGenero(given_name)

  const user = {
    googleId: id,
    firstname: given_name,
    lastname: family_name,
    email: email,
    tipoCuenta: userInfo.tipoCuenta,
    hashedCuenta: userInfo.hashedCuenta,
    hashedEmail: userInfo.hashedEmail,
    terminoOnboard: userInfo.terminoOnboard,
    onempresasId: userInfo.onempresasId,
    profilePicture: picture,
    genero: genero,
  }
  


  const response = await axios.post(API_URL+"equipo", user)
  
  if (response.data) {
  
    
    localStorage.setItem('user', JSON.stringify(response.data))
    const response2 = axios.post("/api/email/contacto", {
      email: response.data.email,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      tipoCuenta: response.data.tipoCuenta,
      id: response.data._id,
      genero: response.data.genero,
      cuenta: response.data.cuenta,
      empresa: response.data.empresa,
      sitioWeb: response.data.sitioWeb,
      lastLogin: response.data.lastLogin,
    });
  }
 /*  delete response.data.token */
  /* delete response.data.refreshToken */
  return response.data
};

//mofificar contraseña user
const editContraUser = async(userId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal: signal,
  }
  /* console.log(userId)
  console.log(modData) */
  const response = await axiosPrivate.patch(API_URL + "cambiar/" + userId, modData, config)
/* console.log(response) */

  return response.data
}


//edit user
const editUser = async(userId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal: signal,
  }

  const response = await axiosPrivate.patch(API_URL + userId, modData, config)
/*   console.log(response)
 */   
/* if (response.data) {
    const userAntiguoTok = JSON.parse(localStorage.getItem("user")).token;
    localStorage.setItem('user', JSON.stringify({...response.data, token:userAntiguoTok})) */
  /*  console.log(userAntiguoTok) */
/*   }  */
/*   delete response.data.token */
  /* delete response.data.refreshToken */
 
  return response.data
}

//edit user
const editUser2 = async(userId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal: signal,
  }

  const response = /* await */ axiosPrivate.patch(API_URL + userId, modData, config)

 
  return response.data
}




// Delete user 
const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL + "deleteUser/" + userId, config)

  return response.data
}


//loginGoogle
const loginGoogle = async (userInfo) => {
  
  const resp1 = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?access_token="+userInfo.response.access_token) 
 


//console.log(rememberMe)
  const {given_name, family_name, email, id} = resp1.data;
  const user = {
    googleId: id,
    firstname: given_name,
    lastname: family_name,
    email: email,
    rememberMe: userInfo.rememberMe,
    linkedId: userInfo.linkedId
  }



  const response = await axios.post(API_URL + 'login', user)
  
  if (response.data) {
  
    
    localStorage.setItem('user', JSON.stringify(response.data))
    const response3 = axios.put("/api/email/modificarContactoLogin", {
      email: response.data.email,
      lastLogin: response.data.lastLogin,
    });
  }
/*   delete response.data.token */
/*   delete response.data.refreshToken */
  return response.data
};

//login linkedIn
const loginLinkedIn = async (userInfo) => {
  const resp2 = await axios.post(API_URL+"getLinkedIn", userInfo).catch(error => {
    console.log(error.response)});

  const {localizedFirstName, localizedLastName, id } = resp2.data[0];

 const user = {
  linkedId: id,
  firstname: localizedFirstName,
  lastname: localizedLastName,
  email: resp2.data[1],
  rememberMe: userInfo.rememberMe,
  googleId: userInfo.googleId
}


  const response = await axios.post(API_URL + 'login', user)
  
  if (response.data) {

    localStorage.setItem('user', JSON.stringify(response.data))
    const response3 = axios.put("/api/email/modificarContactoLogin", {
      email: response.data.email,
      lastLogin: response.data.lastLogin,
    });
  }
 /*  delete response.data.token */
 /*  delete response.data.refreshToken */
  return response.data
};


// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)

  if (response.data) {
     localStorage.setItem('user', JSON.stringify(response.data))
    const response3 = axios.put("/api/email/modificarContactoLogin", {
      email: response.data.email,
      lastLogin: response.data.lastLogin,
    });
  }
/*   delete response.data.token */
/*   delete response.data.refreshToken */
  return response.data
}

const refreshToken = async (userId, remembre) => {
/*  console.log(userId, remembre)
 */
  const response = await axios.post('/api/users/refresh',{userio:userId, remembre: remembre, withCredentials:true})

/*   const userAntiguoTok = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem("user", JSON.stringify({...userAntiguoTok, token: response.data.token}))
  */
  return response.data
}


// Logout user
const logout = async () => {

/*   const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } */
/*   await axiosPrivate.post(API_URL+"logout",{withCredentials:true},config)
 */  await axios.post(API_URL+"logout",{withCredentials:true})

  localStorage.removeItem('user')
  return
}

const editarGeneral = async (userData, token, signal) => {
 
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.post("/api/users/editarGeneral",userData,config);
// console.log(JSON.parse(localStorage.getItem('user')))
//   console.log(response.data)
//   console.log({...JSON.parse(localStorage.getItem('user')), ...response.data})
  return response.data;
};

const enviarConsulta = async (emailData, signal) => {
 
  const config = {
   
    signal:signal,
  };

  const response = await axios.post("/api/email/enviarConsulta",emailData,config);

  return response.data;
};

// Upload File uplaod
const uploadFile = async (imageData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/upload", imageData, config)
/* console.log(response.data) */
  
  return response.data
}


const authService = {
  register,
  registerEquipo,
  registerEquipoGoogle,
  registerEquipoLinkedIn,
  logout,
  login,
  editUser,
  editUser2,
  editContraUser,
  loginGoogle,
  registerGoogle,
  registerLinkedIn,
  loginLinkedIn,
  getUser, 
  deleteUser,
  setActivo,
  refreshToken,
  editarGeneral,
  enviarConsulta,
  uploadFile,
}

export default authService
