import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mensajesService from "./mensajesService";
import _ from "lodash";

const initialState = {
    mensajes: [],
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:'',
}

export const getMensajes = createAsyncThunk(
    'mensajes/get',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.getMensajes(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const getMensajes2 = createAsyncThunk(
    'mensajes/get2',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.getMensajes(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const createMensaje = createAsyncThunk(
    'mensajes/create',
    async (mensajeData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.createMensaje(mensajeData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const leerMensaje = createAsyncThunk(
    'mensajes/leerMensaje',
    async (idEnviador, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.leerMensaje(idEnviador,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const eliminarMensajeEnviado = createAsyncThunk(
    'mensajes/elimMensajeEnv',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.eliminarMensajeEnviado(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const eliminarArchivoEnviado = createAsyncThunk(
    'mensajes/elimArchivoEnv',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.eliminarArchivoEnviado(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const setCarpeta = createAsyncThunk(
    'mensajes/setCarpeta',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.setCarpeta(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const eliminarConversacion = createAsyncThunk(
    'mensajes/eliminarConversacion',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.eliminarConversacion(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const eliminarDef = createAsyncThunk(
    'mensajes/eliminarDef',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.eliminarDef(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const restaurarMensaje = createAsyncThunk(
    'mensajes/restaurar',
    async (dataNec, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await mensajesService.restaurarMensaje(dataNec,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  // Modificar Contacto email
export const uploadFileImageMessage = createAsyncThunk(
  'mensajes/uploadFileImageMessage',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      return await mensajesService.uploadFileImageMessage(imageData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteMensajes = createAsyncThunk(
  'mensajes/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await mensajesService.deleteMensajes(id,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteOtrosMensajes = createAsyncThunk(
  'mensajes/deleteOtros',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await mensajesService.deleteOtrosMensajes(id,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

  export const mensajesSlice = createSlice({
    name: 'mensajes',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
      .addCase(getMensajes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMensajes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.mensajes = action.payload
      })
      .addCase(getMensajes.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getMensajes2.pending, (state) => {
        /* state.isLoading = true */
      })
      .addCase(getMensajes2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.mensajes = action.payload
      })
      .addCase(getMensajes2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(leerMensaje.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(leerMensaje.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        //state.mensajes[0] = action.payload
        // console.log(action)

        if(action.meta.arg.eliminado===true){
          const encon  = state.mensajes[0].eliminados.find((item)=>_.isEqual(item,action.meta.arg.idEnviador))
         
          if(action.meta.arg.leer===true){
            if(encon){
              const itemMen =  state.mensajes[0].eliminados.indexOf(encon)
              if(state.mensajes[0].eliminados[itemMen]?.carpeta?.includes("unread")){
                const encon2 = state.mensajes[0].eliminados[itemMen]?.carpeta?.find((item)=>item==="unread")
               
                if(encon2){
                    const indexoIt = state.mensajes[0].eliminados[itemMen]?.carpeta?.indexOf(encon2)
                  //  const nuevoArr = [].concat(encon.carpeta).slice(indexoIt,encon.carpeta.length)
                
                    state.mensajes[0].eliminados[itemMen].carpeta=[...state.mensajes[0].eliminados[itemMen]?.carpeta?.slice(0, indexoIt), ...state.mensajes[0].eliminados[itemMen]?.carpeta?.slice(indexoIt + 1)]
                  }
              }
              
            }
          } 
        } else{
          const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idEnviador)

          if(action.meta.arg.leer===true){
            if(encon){
              const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
  
              if(state.mensajes[0].mensajes[itemMen]?.carpeta?.includes("unread")){
                const encon2 = state.mensajes[0].mensajes[itemMen]?.carpeta?.find((item)=>item==="unread")
                  if(encon2){
                    const indexoIt = state.mensajes[0].mensajes[itemMen]?.carpeta?.indexOf(encon2)
                  //  const nuevoArr = [].concat(encon.carpeta).slice(indexoIt,encon.carpeta.length)
    
                    state.mensajes[0].mensajes[itemMen].carpeta=[...state.mensajes[0].mensajes[itemMen]?.carpeta?.slice(0, indexoIt), ...state.mensajes[0].mensajes[itemMen]?.carpeta?.slice(indexoIt + 1)]
                  }
              }
              
            }
          } 
        }

      
        // else if(action.meta.arg.leer===false){
        //   if(encon){
        //     const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
        //     for(let k=0;k<=state.mensajes[0].mensajes[itemMen].cuerpo.length-1;k++){
        //       if(state.mensajes[0].mensajes[itemMen].cuerpo[k].enviado===false&&state.mensajes[0].mensajes[itemMen].cuerpo[k].leido===true){
        //         state.mensajes[0].mensajes[itemMen].cuerpo[k].leido=false
        //       }
        //     }
        //   }
        // }
       
      })
      .addCase(leerMensaje.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(eliminarConversacion.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(eliminarConversacion.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        for(let r=0;r<=action.meta.arg.itemEliminar.length-1;r++){
        const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.itemEliminar[r].id)

    
          if(encon){

            state.mensajes[0]?.eliminados?.push(encon)
            const itemMen =  state.mensajes[0].mensajes.indexOf(encon)

            state.mensajes[0].mensajes=[...state.mensajes[0].mensajes?.slice(0, itemMen), ...state.mensajes[0].mensajes?.slice(itemMen + 1)]

            
          }

        }
      })
      .addCase(eliminarConversacion.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(eliminarDef.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(eliminarDef.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        for(let r=0;r<=action.meta.arg.itemEliminar.length-1;r++){
        const encon  = state.mensajes[0].eliminados.find((item)=>_.isEqual(item, action.meta.arg.itemEliminar[r]))
          // console.log(encon)
    
          if(encon){

            
            const itemMen =  state.mensajes[0].eliminados.indexOf(encon)

            state.mensajes[0].eliminados=[...state.mensajes[0].eliminados?.slice(0, itemMen), ...state.mensajes[0].eliminados?.slice(itemMen + 1)]

            
          }

        }
      })
      .addCase(eliminarDef.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(restaurarMensaje.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(restaurarMensaje.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        let encon
        let enconElim
        let itemMen
        let itemMen2
        for(let r=0;r<=action.meta.arg.itemRestaurar.length-1;r++){
         encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.itemRestaurar[r].id)
         enconElim  = state.mensajes[0].eliminados.find((item)=>_.isEqual(item, action.meta.arg.itemRestaurar[r]))
        
    //  console.log(encon)
    
          if(encon){

            
             itemMen =  state.mensajes[0].mensajes.indexOf(encon)
           // console.log(itemMen)
            if(action.meta.arg.itemRestaurar[r].cuerpo&&action.meta.arg.itemRestaurar[r].cuerpo.length>0){
               //console.log(state.mensajes[0]?.mensajes[itemMen]?.cuerpo)
              // console.log(action.meta.arg.itemRestaurar[r].cuerpo)
              // if(state?.mensajes?.[0]?.mensajes?.[itemMen]?.cuerpo){
              //   console.log([...state.mensajes[0].mensajes[itemMen].cuerpo, ...action.meta.arg.itemRestaurar[r].cuerpo])
              //   state.mensajes[0].mensajes[itemMen].cuerpo=[...state.mensajes[0].mensajes[itemMen].cuerpo, ...JSON.parse(JSON.stringify(action.meta.arg.itemRestaurar[r].cuerpo))]
              // } else{
              //   state.mensajes[0].mensajes[itemMen].cuerpo=[...action.meta.arg.itemRestaurar[r].cuerpo]
              // }
              //state.mensajes[0].mensajes[itemMen].cuerpo.push(...action.meta.arg.itemRestaurar[r].cuerpo)
              state.mensajes[0].mensajes[itemMen] = {...state.mensajes[0].mensajes[itemMen] , cuerpo:[...state.mensajes[0].mensajes[itemMen].cuerpo, ...action.meta.arg.itemRestaurar[r].cuerpo]}
            } else{
              state.mensajes[0]?.mensajes[itemMen]?.cuerpo?.push(action.meta.arg.itemRestaurar[r].cuerpo)

            }
             itemMen2 =  state.mensajes[0].eliminados.indexOf(enconElim)
            state.mensajes[0].eliminados=[...state.mensajes[0].eliminados?.slice(0, itemMen2), ...state.mensajes[0].eliminados?.slice(itemMen2 + 1)]
          }

          else{
            state.mensajes[0].mensajes?.push(action.meta.arg.itemRestaurar[r])
             itemMen2 =  state.mensajes[0].eliminados.indexOf(enconElim)
            state.mensajes[0].eliminados=[...state.mensajes[0].eliminados?.slice(0, itemMen2), ...state.mensajes[0].eliminados?.slice(itemMen2 + 1)]
            
          }

        }
      })
      .addCase(restaurarMensaje.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(setCarpeta.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(setCarpeta.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const carpeta = action.meta.arg.carpeta
        //state.mensajes[0] = action.payload
        // console.log(action)
        
        for(let r=0;r<=action.meta.arg.idReceptor.length-1;r++){
          const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor[r])
        //  console.log(encon)
        
          if(encon){
            const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
            if(encon?.carpeta&&encon?.carpeta.length>0){
              if(encon.carpeta.includes(carpeta)&&action.meta.arg.checker===true){
                
                const encon2 = state.mensajes[0].mensajes[itemMen].carpeta.find((item)=>item===carpeta)
                if(encon2){
                  const indexoIt = state.mensajes[0].mensajes[itemMen].carpeta.indexOf(encon2)
                //  const nuevoArr = [].concat(encon.carpeta).slice(indexoIt,encon.carpeta.length)
  
                  state.mensajes[0].mensajes[itemMen].carpeta=[...state.mensajes[0].mensajes[itemMen].carpeta.slice(0, indexoIt), ...state.mensajes[0].mensajes[itemMen].carpeta.slice(indexoIt + 1)]
                }
               
  
              } else if(encon.carpeta.includes(carpeta)&&action.meta.arg.checker===false){
                
              }
              else{
                state.mensajes[0].mensajes[itemMen].carpeta=[...encon.carpeta, carpeta];
              }
            } else{
              state.mensajes[0].mensajes[itemMen].carpeta=[carpeta];
            }
  
            
            
          }
        }
      
      })
      .addCase(setCarpeta.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(eliminarMensajeEnviado.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(eliminarMensajeEnviado.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        //state.mensajes[0] = action.payload
        // console.log(action)
        if(action.meta.arg?.deleted===true){
          const encon  = state.mensajes[0].eliminados.find((item)=>_.isEqual(item,action.meta.arg.contacto))
      
          if(encon){
            const itemMen =  state.mensajes[0].eliminados.indexOf(encon)
  
            const encon2 = state.mensajes[0].eliminados[itemMen].cuerpo.find((item)=>item.texto===action.meta.arg.itemCompleto.texto&&item.fecha===action.meta.arg.itemCompleto.fecha)
  
            if(encon2){
              const indexoIt = state.mensajes[0].eliminados[itemMen].cuerpo.indexOf(encon2)
           
              state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].texto="Mensaje eliminado"
            }
           
          }
        } else{
          const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor)
      
          if(encon){
            const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
  
            const encon2 = state.mensajes[0].mensajes[itemMen].cuerpo.find((item)=>item.texto===action.meta.arg.itemCompleto.texto&&item.fecha===action.meta.arg.itemCompleto.fecha)
  
            if(encon2){
              const indexoIt = state.mensajes[0].mensajes[itemMen].cuerpo.indexOf(encon2)
           
              state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].texto="Mensaje eliminado"
            }
           
          }
        }

        
      })
      .addCase(eliminarMensajeEnviado.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(eliminarArchivoEnviado.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(eliminarArchivoEnviado.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        //state.mensajes[0] = action.payload
        // console.log(action)
        if(action.meta?.arg?.imagenDel===true){
          if(action.meta.arg?.deleted===true){
            const encon  = state.mensajes[0].eliminados.find((item)=> _.isEqual(item,action.meta.arg.contacto))
           // console.log(encon)
            if(encon){
              const itemMen =  state.mensajes[0].eliminados.indexOf(encon)
           //   console.log(itemMen)
              //const lero = state.mensajes[0].eliminados[itemMen].images.find((kilo)=>kilo===action.meta.arg.itemo)
           //   console.log(action.meta.arg.itemCompleto)
              const encon2 = state.mensajes[0].eliminados[itemMen].cuerpo.find((item)=>_.isEqual(item.images,action.meta.arg.itemCompleto.images)&&item.fecha===action.meta.arg.itemCompleto.fecha)
            //  console.log(encon2)
              if(encon2){
                const indexoIt = state.mensajes[0].eliminados[itemMen].cuerpo.indexOf(encon2)
                const lero = state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].images.find((kilo)=>kilo===action.meta.arg.itemo)
                const indexoIt2 = state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].images.indexOf(lero)
             //   console.log(indexoIt)
             //   console.log(lero)
              //  console.log(indexoIt2)
                state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].images[indexoIt2]="Imagen eliminada"
              }
             
            }
          } else{
            const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor)
        
            if(encon){
              const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
              const encon2 = state.mensajes[0].mensajes[itemMen].cuerpo.find((item)=>_.isEqual(item.images,action.meta.arg.itemCompleto.images)&&item.fecha===action.meta.arg.itemCompleto.fecha)
             

              if(encon2){
                const indexoIt = state.mensajes[0].mensajes[itemMen].cuerpo.indexOf(encon2)
                //console.log(state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt])
                const lero = state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].images.find((kilo)=>kilo===action.meta.arg.itemo)
                const indexoIt2 = state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].images.indexOf(lero)
                state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].images[indexoIt2]="Imagen eliminada"
              }
             
            }
          }
        } else if(action.meta?.arg?.archivoDel===true){
          if(action.meta.arg?.deleted===true){
            const encon  = state.mensajes[0].eliminados.find((item)=>_.isEqual(item,action.meta.arg.contacto))
        
            if(encon){
              const itemMen =  state.mensajes[0].eliminados.indexOf(encon)
    
              const encon2 = state.mensajes[0].eliminados[itemMen].cuerpo.find((item)=>_.isEqual(item.archivos,action.meta.arg.itemCompleto.archivos)&&item.fecha===action.meta.arg.itemCompleto.fecha)
    
              if(encon2){
                const indexoIt = state.mensajes[0].eliminados[itemMen].cuerpo.indexOf(encon2)
                const lero = state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].archivos.find((kilo)=>_.isEqual(kilo,action.meta.arg.itemo))
                const indexoIt2 = state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].archivos.indexOf(lero)
                state.mensajes[0].eliminados[itemMen].cuerpo[indexoIt].archivos[indexoIt2]="Archivo eliminado"
              }
             
            }
          } else{
            const encon  = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor)
        //console.log(encon)
            if(encon){
              const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
          //    console.log(itemMen)
              const encon2 = state.mensajes[0].mensajes[itemMen].cuerpo.find((item)=> _.isEqual(item.archivos,action.meta.arg.itemCompleto.archivos)&&item.fecha===action.meta.arg.itemCompleto.fecha)
             // console.log(encon2)
              if(encon2){
                const indexoIt = state.mensajes[0].mensajes[itemMen].cuerpo.indexOf(encon2)
                const lero = state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].archivos.find((kilo)=>_.isEqual(kilo,action.meta.arg.itemo))
                const indexoIt2 = state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].archivos.indexOf(lero)
             //   console.log(indexoIt,lero, indexoIt2)
                state.mensajes[0].mensajes[itemMen].cuerpo[indexoIt].archivos[indexoIt2]="Archivo eliminado"
              }
             
            }
          }
        }
        

        
      })
      .addCase(eliminarArchivoEnviado.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createMensaje.pending, (state) => {
        /*  state.isLoading = true  */
      })
      .addCase(createMensaje.fulfilled, (state, action) => {
       /*   state.isLoading = false  */
        state.isSuccess = true
        //state.mensajes[0]= action.payload
        if(state.mensajes&&state.mensajes.length>=1){
         /*  console.log(state.mensajes[0]) */
          const encon = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor)
          if(encon){
           const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
           state.mensajes[0].mensajes[itemMen].cuerpo.push({texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true})
          } else{
          
            state.mensajes[0].mensajes.push({id:action.meta.arg.idReceptor, cuerpo:[{texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true}]})
          }
        } else{
         
          state.mensajes=[{user: action.meta.arg.userId,mensajes:[{id:action.meta.arg.idReceptor, cuerpo:[{texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true}]}]}]
        /*   console.log(state.mensajes[0]) */
        }
     })
      .addCase(createMensaje.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(uploadFileImageMessage.pending, (state) => {
        /*  state.isLoading = true  */
      })
      .addCase(uploadFileImageMessage.fulfilled, (state, action) => {
          state.isLoading = false  
        state.isSuccess = true
        if(state.mensajes&&state.mensajes.length>=1){
          /*  console.log(state.mensajes[0]) */
           const encon = state.mensajes[0].mensajes.find((item)=>item.id===action.meta.arg.idReceptor)
           if(encon){
            const itemMen =  state.mensajes[0].mensajes.indexOf(encon)
            state.mensajes[0].mensajes[itemMen].cuerpo.push({texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true,images:action.payload.urls, archivos:action.payload.arch})
           } else{
           
             state.mensajes[0].mensajes.push({id:action.meta.arg.idReceptor, cuerpo:[{texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true ,images:action.payload.urls, archivos:action.payload.arch}]})
           }
         } else{
          
           state.mensajes=[{user: action.meta.arg.userId,mensajes:[{id:action.meta.arg.idReceptor, cuerpo:[{texto:action.meta.arg.cuerpo, fecha: action.meta.arg.fecha.toISOString(), enviado:true,images:action.payload.urls, archivos:action.payload.arch}]}]}]
         /*   console.log(state.mensajes[0]) */
         }
        
     })
      .addCase(uploadFileImageMessage.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteMensajes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteMensajes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message=action.payload
      })
      .addCase(deleteMensajes.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteOtrosMensajes.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOtrosMensajes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
      })
      .addCase(deleteOtrosMensajes.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
        
    },
  })
  
  export const { reset } = mensajesSlice.actions
  export default mensajesSlice.reducer