import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/busquedas/'

// Get user notificaciones
const getBusquedas = async (token,signal) => {
    /*   console.log("Ha llegado acá") */
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response = await axiosPrivate.get(API_URL + "get", config)
    /* console.log(response) */
      return response.data
}


//edit notificaciones
const editBusquedas = async(busId, modData, token,signal) => {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }

      const response = /* await */ axiosPrivate.patch(API_URL + "update/" + busId, modData, config)

      return response.data
    }

    // Delete notificaciones 
const deleteBusquedas = async (busId, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }
/* console.log(busId) */
  const response = /* await */ axiosPrivate.delete(API_URL + "deleteBusquedas/" + busId, config)
/* console.log(response) */
  return response.data
}

    // Delete notificaciones 
    const deleteAllBusquedas = async (userId, token) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    /* console.log(busId) */
      const response = await axiosPrivate.delete(API_URL + "deleteAllBusquedas/" + userId, config)
    /* console.log(response) */
      return response.data
    }


      const deleteOtrosAllBusquedas = async (userId, token) => {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      /* console.log(busId) */
        const response = await axiosPrivate.delete(API_URL + "deleteOtrosAllBusquedas/" + userId, config)
      /* console.log(response) */
        return response.data
      }

    // Delete Otros notificaciones 
    const createBusquedas = async (data, token,signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
   /*  console.log(config) */
      const response = await axiosPrivate.post(API_URL + "create/", data, config)
    
      return response.data
    }

    const seleccionarBusqueda = async (data, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response =/*  await  */axiosPrivate.post(API_URL + "seleccionarBusqueda/", data, config)
    
      return response.data
    }

    const buscarUsuarios = async (data, token,signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response = await axiosPrivate.post(API_URL + "buscarUsuarios/", data, config)
      /* console.log(response.data) */
      return response.data
    }

    // Get user invitaciones
const getIdsInvitaciones = async (token,signal) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get("/api/invitaciones/getIds", config)

  return response.data
}

const getIdsContactos = async (token,signal) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get("/api/contactos/getIds", config)

  return response.data
}



    const busquedasService = {
        getBusquedas,
        editBusquedas,
        deleteBusquedas,
        createBusquedas,
        seleccionarBusqueda,
        buscarUsuarios,
        deleteAllBusquedas,
        deleteOtrosAllBusquedas,
        getIdsInvitaciones,
        getIdsContactos,
      }

      export default busquedasService;