import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))



const initialState = {
  user: user?user:null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Register user equipo
export const registerEquipo = createAsyncThunk(
  'auth/registerEquipo',
  async (user, thunkAPI) => {
    try {
      return await authService.registerEquipo(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

 // Get user
export const getUser = createAsyncThunk(
  'user/getMe',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.getUser(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
) 

export const getUser2 = createAsyncThunk(
  'user/getMe2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await authService.getUser(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
) 

// Delete user
export const deleteUser = createAsyncThunk(
  'user/delete',
  async (id, thunkAPI) => {
    try {
       const token = thunkAPI.getState().auth.user.token 
       /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await authService.deleteUser(id,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



// Register user google
export const registerGoogle = createAsyncThunk('auth/registerGoogle', async (user, thunkAPI) => {
  try {
    
    return await authService.registerGoogle(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// RegisterEquipo user google
export const registerEquipoGoogle = createAsyncThunk('auth/registerEquipoGoogle', async (user, thunkAPI) => {
  try {
    
    return await authService.registerEquipoGoogle(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// register user linkedin
export const registerLinkedIn = createAsyncThunk('auth/registerLinkedIn', async (userInfo, thunkAPI) => {
  try {
    
    return await authService.registerLinkedIn(userInfo)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// register user linkedin
export const registerEquipoLinkedIn = createAsyncThunk('auth/registerEquipoLinkedIn', async (userInfo, thunkAPI) => {
  try {
    
    return await authService.registerEquipoLinkedIn(userInfo)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Login user
export const loginGoogle = createAsyncThunk('auth/loginGoogle', async (userInfo, thunkAPI) => {
  try {
    
    return await authService.loginGoogle(userInfo)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// Login linkedin
export const loginLinkedIn = createAsyncThunk('auth/loginLinkedIn', async (userInfo, thunkAPI) => {
  try {
    
    return await authService.loginLinkedIn(userInfo)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})




// Register user
export const setActivo = createAsyncThunk(
  'auth/setActivo',
  async (data, thunkAPI) => {
    try {
      return await authService.setActivo(data)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {susMedio}=getState();
      /* console.log(onboard,onboard.length) */
      if(susMedio&&susMedio.susMedio&&susMedio.susMedio.length===0){
        return false
      }
    }
  }
)


export const logout = createAsyncThunk('auth/logout', async () => {
/*   const token = JSON.parse(localStorage.getItem("user")).token;
 */  await authService.logout()
})

//edit user
export const editUser = createAsyncThunk(
  "auth/editUser", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await authService.editUser(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit user
export const enviarConsulta = createAsyncThunk(
  "auth/enviarConsulta", async(emailData, thunkAPI) =>{
    try {
      
    
      return await authService.enviarConsulta(emailData,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



//edit user
export const editUser3 = createAsyncThunk(
  "auth/editUser3", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await authService.editUser2(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

//edit contra user
export const editContraUser = createAsyncThunk(
  "auth/editContraUser", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await authService.editContraUser(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const refreshToken = createAsyncThunk(
  "auth/rt_rek", async({userId, remembre}, thunkAPI) =>{
    try {
/* const token = thunkAPI.getState().auth.user.token */ 

return await authService.refreshToken(userId, remembre)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const editarGeneral = createAsyncThunk(
  "auth/editarGeneral",
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await authService.editarGeneral(userData, token, thunkAPI.signal);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);


// Modificar Contacto email
export const uploadFile = createAsyncThunk(
  'auth/uploadFile',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await authService.uploadFile(imageData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(registerEquipo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerEquipo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(registerEquipo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(registerGoogle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerGoogle.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(registerGoogle.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(registerEquipoGoogle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerEquipoGoogle.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(registerEquipoGoogle.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(registerLinkedIn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerLinkedIn.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(registerLinkedIn.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(registerEquipoLinkedIn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(registerEquipoLinkedIn.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(registerEquipoLinkedIn.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(loginLinkedIn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginLinkedIn.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(loginLinkedIn.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null
      })
      .addCase(editUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
       state.user = {...state.user, ...action.payload}
       localStorage.setItem('user', JSON.stringify(state.user))
/*        state.user.token = JSON.parse(localStorage.getItem("user")).token;
 *//*        localStorage.setItem('user', JSON.stringify(state.user))
 */      })
      .addCase(editUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
     
      .addCase(editUser3.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editUser3.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
       state.user = {...state.user, ...action.meta.arg.modData}
       localStorage.setItem('user', JSON.stringify(state.user))
/*        state.user.token = JSON.parse(localStorage.getItem("user")).token;
 *//*        localStorage.setItem('user', JSON.stringify(state.user))
 */      })
      .addCase(editUser3.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editContraUser.pending, (state) => {
        //state.isLoading = true
      })
      .addCase(editContraUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
       //state.user = action.payload
      })
      .addCase(editContraUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
       .addCase(refreshToken.fulfilled, (state, action) => {
        state.user.token = action.payload.token
        state.user.activo = action.payload.activo
         localStorage.setItem("user", JSON.stringify({...state.user})) 
/*         localStorage.setItem("user", JSON.stringify({...user, token: action.payload.token}))
 */      /*   state.message = "todo ok" */
       })
       .addCase(refreshToken.rejected, (state, action) => {
         localStorage.removeItem('user');
        window.location.href = "/"; 
       })
       .addCase(loginGoogle.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginGoogle.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(loginGoogle.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
     .addCase(getUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = {...state.user, ...action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      }) 
      .addCase(getUser2.pending, (state) => {
     //   state.isLoading = true
      })
      .addCase(getUser2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = {...state.user, ...action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
      })
      .addCase(getUser2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      }) 
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = null
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(setActivo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = {...state.user, activo: action.payload}
      })
      .addCase(setActivo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editarGeneral.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editarGeneral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
       /*  console.log(user)
        console.log(action.payload)
        console.log({...user, ...action.payload}) */
        state.user = {...state.user, ...action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
      /*   state.user.token = JSON.parse(localStorage.getItem("user")).token; */
        
      })
      .addCase(editarGeneral.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      /* .addCase(enviarConsulta.pending, (state) => {
        state.isLoading = true;
      }) */
      .addCase(enviarConsulta.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
       /*  state.user = {...user, ...action.payload.user} */
      /*   state.user.token = JSON.parse(localStorage.getItem("user")).token; */
        
      })
      .addCase(enviarConsulta.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
       /* .addCase(uploadFile.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = {...state.user, ...action.payload}
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
   

  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
