import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notosService from "./notosService";
import _ from "lodash";

const initialState = {
    notos: [],
    isError: false,
    isSuccess:false,
    isLoading:false,
    message:'',
}

export const getNotos = createAsyncThunk(
    'notos/get',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.getNotos(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const createNotos = createAsyncThunk(
    'notos/create',
    async (notosData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.createNotos(notosData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const createNotosPersonal = createAsyncThunk(
    'notos/createPersonal',
    async (notosData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.createNotosPersonal(notosData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const updateNotos = createAsyncThunk(
    'notos/update',
    async (notosData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.updateNotos(notosData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const updateNotosTodos = createAsyncThunk(
    'notos/updateTodos',
    async (notosData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.updateNotosTodos(notosData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteSingleNotos = createAsyncThunk(
    'notos/deleteSingleNotos',
    async (idNotos, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.deleteSingleNotos(idNotos,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteNotosTodosSin = createAsyncThunk(
    'notos/deleteNotosTodosSin',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.deleteNotosTodosSin(userId,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  export const deleteNotosTodos = createAsyncThunk(
    'notos/deleteNotosTodos',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.deleteNotosTodos(userId,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteAllTodosNotos = createAsyncThunk(
    'notos/deleteAllTodosNotos',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await notosService.deleteAllTodosNotos(userId,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const notosSlice = createSlice({
    name:'notos',
    initialState,
    reducers:{
        reset: (state)=> initialState,
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getNotos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(getNotos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.notos = action.payload
          })
          .addCase(getNotos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(createNotos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(createNotos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            //state.message = action.payload
          })
          .addCase(createNotos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(createNotosPersonal.pending, (state) => {
            state.isLoading = true
          })
          .addCase(createNotosPersonal.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.notos = action.payload
            //state.message = action.payload
          })
          .addCase(createNotosPersonal.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(updateNotos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(updateNotos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true

            const encon = state.notos?.notos.find((item)=>item._id===action.meta.arg.idNotos)
           
            if(encon){
                const itemMen = state.notos.notos.indexOf(encon)
                state.notos.notos[itemMen].unread=false
            }
           
            //state.message = action.payload
          })
          .addCase(updateNotos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(updateNotosTodos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(updateNotosTodos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true

            // const encon = state.notos?.notos.find((item)=>item._id===action.meta.arg)
           
            // if(encon){
            //     const itemMen = state.notos.notos.indexOf(encon)
            //     state.notos.notos[itemMen].unread=false
            // }

            for(let t=0;t<=state.notos.notos.length-1;t++){
                state.notos.notos[t].unread=false
            }
           
            //state.message = action.payload
          })
          .addCase(updateNotosTodos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteSingleNotos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(deleteSingleNotos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true

             const encon = state.notos?.notos.find((item)=>item._id===action.meta.arg.idNotos)

            if(encon){
                // const itemMen = state.notos.notos.indexOf(encon)
              
                state.notos.notos = state.notos.notos.filter((item)=>item._id!==action.meta.arg.idNotos)
            }

          })
          .addCase(deleteSingleNotos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteNotosTodos.pending, (state) => {
            state.isLoading = true
          })
          
          .addCase(deleteNotosTodos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message=action.payload
          })
          .addCase(deleteNotosTodos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteNotosTodosSin.pending, (state) => {
            state.isLoading = true
          })
          
          .addCase(deleteNotosTodosSin.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.notos=initialState
          })
          .addCase(deleteNotosTodosSin.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(deleteAllTodosNotos.pending, (state) => {
            state.isLoading = true
          })
          .addCase(deleteAllTodosNotos.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteAllTodosNotos.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
    }
  })


  export const { reset } = notosSlice.actions
export default notosSlice.reducer
