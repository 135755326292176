import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/contactos/'


// Get user contactos
const getContactos = async (token,signal) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get(API_URL, config)

  return response.data
}

// Delete user invitaciones
const deleteSingleContacto = async (userId,userElimId,tipoCuentaEliminar, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = /* await */ axiosPrivate.delete(API_URL + userId + "/" + userElimId + "/" + tipoCuentaEliminar, config)

  return response.data
}

// Delete user invitaciones
const deleteContactos = async (userId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axiosPrivate.delete(API_URL + userId, config)
  
    return response.data
  }
  
  // Delete user invitaciones
  const deleteOtrosContactos = async (userId, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    const response = await axiosPrivate.delete(API_URL+ "deleteAllOtros/" + userId, config)
  
    return response.data
  }
  

  const contactosService = {
    deleteContactos,
    deleteOtrosContactos,
    deleteSingleContacto,
    getContactos,
  }

  export default contactosService