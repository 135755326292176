import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificacionesService from "./notificacionesService";

const initialState = {
  notificaciones: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get user onboard
export const getNotificaciones = createAsyncThunk(
  "notificaciones/get",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await notificacionesService.getNotificaciones(token, thunkAPI.signal);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


// Delete user
export const deleteNotificaciones = createAsyncThunk(
  "notificaciones/delete",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await notificacionesService.deleteNotificaciones(userId, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



// Delete otro user
export const deleteOtrosNotificaciones = createAsyncThunk(
  "notificaciones/deleteOtros",
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await notificacionesService.deleteOtrosNotificaciones(
        userId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const cambiarUnNotificacion = createAsyncThunk(
  "notificaciones/cambiarUnNotificacion",
  async (notData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await notificacionesService.cambiarUnNotificacion(notData, token, thunkAPI.signal);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
/*   {
    condition: (notData,{getState})=>{
      const {notificaciones}=getState();
      if(notificaciones.isLoading===true){
        return false
      }
    }
  } */
);

export const cambiarTodasNotificacion = createAsyncThunk(
  "notificaciones/cambiarTodasNotificacion",
  async (notData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await notificacionesService.cambiarTodasNotificacion(notData, token, thunkAPI.signal);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  },
);


export const notificacionesSlice = createSlice({
  name: "notificaciones",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificaciones.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotificaciones.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificaciones = action.payload;
      })
      .addCase(getNotificaciones.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
     
      .addCase(deleteNotificaciones.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotificaciones.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificaciones = null;
      })
      .addCase(deleteNotificaciones.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteOtrosNotificaciones.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOtrosNotificaciones.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        /* state.notificaciones = null */
      })
      .addCase(deleteOtrosNotificaciones.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      
     
      .addCase(cambiarUnNotificacion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cambiarUnNotificacion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificaciones[0] = action.payload;
      })
      .addCase(cambiarUnNotificacion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(cambiarTodasNotificacion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cambiarTodasNotificacion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notificaciones[0] = action.payload;
      })
      .addCase(cambiarTodasNotificacion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })


  },
});

export const { reset } = notificacionesSlice.actions;
export default notificacionesSlice.reducer;
