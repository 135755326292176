import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "../Varios/Container";
import { Topbar, Sidebar } from "./components";
import {  useSelector } from "react-redux";
import pages from "./navigation";
import "./header.styles.css";
import { Grid } from "@mui/material";
import {BufferHead} from "./components/Buffer";
import FallbackPageWrapper from "../../composables/FallbackPageWrapper";

function Header2({ children, refer, selectedTab, setSelectedTab, colorInvert = false, bgcolor = "transparent", openedMen, setOpenedMen }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const { user } = useSelector((state) => state.auth);

 /*  const refUser = useRef() */

/*    refUser.current = JSON.parse(localStorage.getItem('user'));
   */
/* 
refUser.current = JSON.parse(localStorage.getItem('user'));

 useEffect(()=>{
     refUser.current = JSON.parse(localStorage.getItem('user'));
    if(refUser.current.tipoCuenta==="empresa"){

    }
  },[]) */
/*   const dispatch=useDispatch()

  useEffect(()=>{
    let d
    
      d = dispatch(getUser())
   

    return ()=>{
      
      d?.abort();
      
    }
  },[dispatch]) */

  return (

    <Grid ref={refer}>

{!user ? ( <Box >
      {/* <Box bgcolor={bgcolor} position={"relative"} zIndex={theme.zIndex.appBar}>
        <Container paddingTop={"8px !important"} paddingBottom={"0 !important"}>
          <TopNav colorInvert={colorInvert} />
        </Container>
      </Box> */}

    

      <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : theme.palette.background.paper,
          // zIndex: (theme) => theme.zIndex.drawer + 1 ,
        }}
        elevation={trigger ? 1 : 0}
        
        className={"navi1"}
        
      >
        <Container paddingY={1} className="cont-nav" maxWidth={"100%"}>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
            user={user}
          />
        </Container>
      </AppBar>
      
      <Sidebar
        onClose={handleSidebarClose}
        setOpenSidebar={setOpenSidebar}
        open={open}
        variant="temporary"
        pages={pages}
        user={user}
      />
 <main>
        {children}
       
      </main>
      
    </Box>) : (
     <BufferHead
     trigger={trigger}
     theme={theme}
     handleSidebarOpen={handleSidebarOpen}
     handleSidebarClose={handleSidebarClose}
     pages={pages}
     colorInvert={colorInvert}
     user={user}
     selectedTab={selectedTab}
     setSelectedTab={setSelectedTab}
     open={open}
     setOpenSidebar={setOpenSidebar}
     children={children}
     openedMen={openedMen}
     setOpenedMen={setOpenedMen}
      />
    )}

   
    </Grid>
  
    );
}



//   return (
//     <header className="header">
//       <div className="logo">
//         <Link to="/">GoalSetter</Link>
//       </div>
//       <ul>
//       {user ? (<li>
//             <button className="btn" onClick={onLogout}>
//                 <FaSignOutAlt /> Logout
//             </button>
//         </li>) : (<><li>
//             <Link to="/login">
//                 <FaSignInAlt /> Login
//             </Link>
//         </li>
//         <li>
//             <Link to="/register">
//                 <FaUser /> Register
//             </Link>
//         </li></>)}

//       </ul>
//     </header>
//   );
// }

export default Header2;
