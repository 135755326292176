import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import redesService from './redesService'

const initialState = {
    redes: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    getIds:[],
    getIdsCon:[],
  }

  // Get user onboard
export const getRedes = createAsyncThunk(
    'redes/get',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.getRedes(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  export const getIdsInvitaciones = createAsyncThunk(
    'redes/getIds',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.getIdsInvitaciones(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  export const getIdsContactos = createAsyncThunk(
    'redes/getIdsCon',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.getIdsContactos(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  //edit notificaciones
export const editRedes = createAsyncThunk(
    "redes/editredes", async({id, modData}, thunkAPI) =>{
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
   //     console.log("SLICE" + token)
        return await redesService.editRedes(id, modData, token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  //edit notificaciones
  export const editRedes2 = createAsyncThunk(
    "redes/editRedes2", async({id, modData}, thunkAPI) =>{
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
   //     console.log("SLICE" + token)
        return await redesService.editRedes(id, modData, token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    },
    {
      condition: (_,{getState})=>{
        const {redes}=getState();
        /* console.log(onboard,onboard.length) */
        if(redes&&redes.redes&&redes.redes.length===0){
          return false
        }
      }
    }
  )
  
  // Post buscarUsuarios
export const buscarUsuarios = createAsyncThunk(
    'redes/buscarUsuarios',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.buscarUsuarios(data, token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  // Delete user
  export const deleteRedes = createAsyncThunk(
    'redes/delete',
    async (id, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */ 
        return await redesService.deleteRedes(id,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  // Post busqueda
export const createRedes = createAsyncThunk(
    'redes/crear',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.createRedes(data, token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  

  // Post seleccionar
export const seleccionarRedes = createAsyncThunk(
    'redes/seleccionar',
    async (data, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await redesService.seleccionarRedes(data, token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  

  export const redesSlice = createSlice({
    name: 'redes',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getRedes.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getRedes.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.redes = action.payload
        })
        .addCase(getRedes.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getIdsInvitaciones.pending, (state) => {
          //state.isLoading = true
        })
        .addCase(getIdsInvitaciones.fulfilled, (state, action) => {
          //state.isLoading = false
          state.isSuccess = true
          state.getIds = action.payload
        })
        .addCase(getIdsInvitaciones.rejected, (state, action) => {
          //state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getIdsContactos.pending, (state) => {
          //state.isLoading = true
        })
        .addCase(getIdsContactos.fulfilled, (state, action) => {
          //state.isLoading = false
          state.isSuccess = true
          state.getIdsCon = action.payload
        })
        .addCase(getIdsContactos.rejected, (state, action) => {
          //state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(editRedes.pending, (state) => {
            state.isLoading = true
          })
          .addCase(editRedes.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.redes.forEach((item, index)=>{
              if(item._id=== action.meta.arg.id){
                state.redes[index]={...state.redes[index], ...action.meta.arg.modData};
              }
            })
           
          })
          .addCase(editRedes.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.meta.arg.id
          
          })
          .addCase(editRedes2.pending, (state) => {
            state.isLoading = true
          })
          .addCase(editRedes2.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.redes.forEach((item, index)=>{
              if(item._id=== action.payload.redId){
                state.redes[index]=action.payload.mod;
              }
            })
           
          })
          .addCase(editRedes2.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          
          })

          .addCase(deleteRedes.pending, (state) => {
            state.isLoading = true
          })
          .addCase(deleteRedes.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.redes = state.redes.filter(
                (redes) => redes._id !== action.meta.arg
              )
          })
          .addCase(deleteRedes.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.meta.arg
          })
        .addCase(createRedes.pending, (state) => {
          state.isLoading = true
        })
        .addCase(createRedes.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.redes.forEach((item, index)=>{
            if(item.activo=== true){
              state.redes[index].activo=false;
            }
          })
          state.redes.push(action.payload)
         
        })
        .addCase(createRedes.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(seleccionarRedes.pending, (state) => {
          state.isLoading = true
        })
        .addCase(seleccionarRedes.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.redes.forEach((item, index)=>{
            if(item._id=== action.meta.arg.redId){
              state.redes[index].activo=true;
            } else{
              if(item.activo===true){
                state.redes[index].activo=false;
              }
            }
          })
          state.message = action.meta.arg.redId
        })
        .addCase(seleccionarRedes.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.meta.arg.redId
        })
        .addCase(buscarUsuarios.pending, (state) => {
            state.isLoading = true
          })
          .addCase(buscarUsuarios.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.redes.forEach((item, index)=>{
              if(item._id=== action.payload.busId){
                state.redes[index]=action.payload.mod;
               
              } 
            })
           /*  state.resultados = action.payload */
          })
          .addCase(buscarUsuarios.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
      
    },
  })
  
  export const { reset } = redesSlice.actions
  export default redesSlice.reducer
  