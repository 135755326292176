import { Avatar, Badge, Card, Grid, IconButton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { ellipsis } from "polished";
import spanishLocale from "date-fns/locale/es"
import { deleteSingleNotos, updateNotos } from "../../../../features/notos/notosSlice";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

function countWords(str) {
  return str.trim().split(/\s+/).length;
}

function capitalize2(string) {
  /*     console.log(string)
   */
  const words = string.trim();
  return words.charAt(0).toUpperCase();
}


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function capitalize(string) {
  /*     console.log(string)
   */ return string.charAt(0).toUpperCase() + string.slice(1);
}

function stringAvatar(name) {
  if (countWords(name) === 0) {
    return {
      sx: {
        bgcolor: "red",
      },
      children: "NN",
    };
  } else if (countWords(name) === 1 && name.length > 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0]}${capitalize(name[1][0])}`,
    };
  } else if (countWords(name) === 1 && name.length === 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0]}`,
    };
  } else if (countWords(name) === 2) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  } else if (countWords(name) >= 3) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${capitalize(name.split(" ")[1][0])}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0]}${capitalize(name[0][1])}`,
    };
  }
}


function CartaNotificacion({item,setStateDrawer, botonNoto, performMyAction, user}) {

    //const variant = item?.variant || '';
    const handleClose = (ev,item) => {
        ev.preventDefault();
        ev.stopPropagation();
    
        performMyAction(botonNoto,deleteSingleNotos,{
            idNotos:item?._id
        })
        // if (props.onClose) {
        //   props.onClose(item.id);
        // }
      };

      const navigate= useNavigate()
     

  return (
    <Card
    sx={{display:"flex", alignItems:"center", position:"relative", width:"100%", padding:"20px", minHeight:"64px",
    borderRadius:"16px",
    marginBottom:"16px",
    cursor:"pointer",
    }}
    className="shadoweoNoto"
    // className={
        
    //     clsx(
    //   'flex items-center relative w-full rounded-16 p-20 min-h-64 shadow space-x-8',
    //   variant === 'success' && 'bg-green-600 text-white',
    //   variant === 'info' && 'bg-blue-700 text-white',
    //   variant === 'error' && 'bg-red-600 text-white',
    //   variant === 'warning' && 'bg-orange-600 text-white',
    //   className
    // )}
    elevation={0}
    component={'div'}
    to={item.link || ''}
   onClick={()=>{
    if(user?.tipoCuenta==="persona"&&user?.terminoOnboard===true&&item.tipo==="registro"){
      navigate("/perfil")
      setStateDrawer(false)
    performMyAction(botonNoto, updateNotos,{
        idNotos:item._id
    })
    }
    else if(user?.tipoCuenta==="empresa"&&user?.terminoOnboard===true&&item.tipo==="registro"){
      navigate("/perfil/empresas")
      setStateDrawer(false)
    performMyAction(botonNoto, updateNotos,{
        idNotos:item._id
    })
    }
    else{
      navigate(item.link)
    setStateDrawer(false)
    performMyAction(botonNoto, updateNotos,{
        idNotos:item._id
    })
    }
    
   }}
   
   // role={item.link && 'button'}
  >
   {/*  {item.icon && !item.image && ( */}
      <Box
        sx={{ backgroundColor: 'background.default',
        display:"flex", alignItems:"center",
        flexShrink:"0", justifyContent:"center", width:"32px", height:"32px", marginRight:"12px", borderRadius:"9999px"
         }}
      >
        <IconButton 
        sx={{opacity:"1",
        backgroundColor:"transparent",
        }}
        color="inherit"
      
        disableRipple
        >
        <Badge
        overlap="circular"
        anchorOrigin={{vertical:"top", horizontal:"right"}}
        color="error"
        variant="dot"
        invisible={item?.unread===true?false:true}
       // invisible={false}
     //   badgeContent={0}
        >

       
        {(item.tipo==="mensaje"||item.tipo==="invitaciones"||item.tipo==="aceptaInv"||item.tipo==="cambioCuenta"||item.tipo==="aceptaRegistro") && (

          item?.avatar ? (
            <Avatar
              sx={{ 
                height:40,
                width:40,
                                    // backgroundColor: (theme) =>
                                    //   theme.palette.primary[500],
                                  }}
                                  alt={capitalize2(item?.nombre)
                                   + " " + capitalize2(item?.apellido)}
                                  src={
                                    item?.avatar
                                  }
                                  
            />
          ):
       ( item?.nombre ? (
        <Avatar
       
              sx={{ 
                height:40,
                width:40,
                                    // backgroundColor: (theme) =>
                                    //   theme.palette.primary[500],
                                  }}
                                  alt={capitalize2(item?.nombre)
                                   + " " + capitalize2(item?.apellido)}
                                  {...stringAvatar(
                                   capitalize2(item?.nombre)
                                   + " " + capitalize2(item?.apellido)
                                  )}
                                  
            />
       ): 


       (<MailIcon/>))
        
        )}

       


        {item.tipo==="ignoraInv" &&
        <PersonOffIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
        }

        {item.tipo==="terminoOnboard" &&
        <AccountCircleIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
 }

 {item.tipo==="registro" &&
        <ThumbUpOffAltIcon sx={{
          height:"40px",
          width:"40px",
          color:"#004e56"
        }}/>
 }
        </Badge>
        </IconButton>
      </Box>
 {/*    )} */}

 


    <Grid 
    sx={{display:"flex", flexDirection:"column", flex:"1 1 auto"}}
    style={ellipsis(undefined,1)}
    >
      {item.tipo && 
        <Typography 
      sx={{fontWeight:"600",
      overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"14px",
      }}
      //className="font-semibold line-clamp-1"
      
      >
      {item.tipo==="mensaje" ? "Nuevo mensaje" : item.tipo==="invitaciones" ? "Nueva invitación" : item.tipo==="aceptaInv" ? "Invitación aceptada" : item.tipo==="ignoraInv" ? "Invitación ignorada" : item.tipo==="terminoOnboard" ? "Completaste el onboarding" : item.tipo==="registro" ? "Bienvenido a Rekloot" : item.tipo==="cambioCuenta" ?"Han cambiado tus permisos":item.tipo==="aceptaRegistro"&&"Se han unido a tu equipo"}
      </Typography>
     }

      {item.mensaje && (
        <Typography 
       // className="line-clamp-2" 
       sx={{marginTop:"4px",
       overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"14px",
       }}
        
        style={ellipsis(undefined,3)}

        >
        {item.mensaje}
        </Typography>
      )} 

      {item.mensaje && (
        <Typography className="mt-8 text-sm leading-none " 
        sx={{marginTop:"4px",
        overflow:"hidden",
       whiteSpace:"nowrap",
       textOverflow:"ellipsis",
       fontSize:"12px",
         }}
        color="text.secondary"
        
        >
          {formatDistanceToNow(new Date(item.fecha), { locale:spanishLocale ,addSuffix: true,  })}
        </Typography>
      )}
    </Grid>

    <IconButton
      disableTouchRipple
      sx={{top:"8px", right:"8px", position:"absolute", padding:"8px"}}
      color="inherit"
      size="small"
      onClick={(ev)=>handleClose(ev,item)}
    >
      
          <CloseIcon sx={{fontSize:"0.7rem"}}/>
        
    </IconButton>
    
  </Card>
  )
}

export default CartaNotificacion