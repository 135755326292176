import { Grid, IconButton, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CartaNotificacion from "./CartaNotificacion";
import { deleteNotosTodosSin, updateNotosTodos } from "../../../../features/notos/notosSlice";
import iNoBounce from "../../../../hooks/inobounce";
import { isMobile } from "react-device-detect";



function DrawerNotificaciones({setStateDrawer, stateDrawer, botonNoto, performMyAction,user}) {

    const notoIni = useSelector((state) => state.notos)
//console.log(notoIni?.notos?.notos?.length)

  return (
    <Grid>
    
    <Grid
    sx={{ top:"0px", right:"0px"}}
    >
     <IconButton onClick={()=>{
       iNoBounce.disable();
      setStateDrawer(false)
    }} size="medium" 
    
    className='Nopruee22' sx={{display:"inline-flex",
    position:isMobile?"absolute":"fixed"
   
    }}>
<CloseOutlinedIcon sx={{fontSize:"24px"}} />
</IconButton>
    </Grid>
    {notoIni?.notos?.notos?.length > 0 ? (
       
          <Grid 
          sx={{display:"flex", flexDirection:"column",padding:"16px"}}
        >
            <Grid 
          
            sx={{display:"flex", justifyContent:"space-between", alignItems:"end",
            marginTop:"0px",
             marginBottom:"20px"}}
            >
              <Typography 
              sx={{fontSize:"28px", fontWeight:"600", lineHeight:"1"}}
              >Notificaciones
              </Typography>
           
            </Grid>
            <Grid sx={{
              display:"flex",justifyContent:"space-between",
              marginBottom:"16px"
            }}>
             <Typography
                sx={{fontSize:"12px", textDecorationLine:"underline", cursor:"pointer"}}
                color="primary"
                //onClick={handleDismissAll}
                onClick={()=>{
                  performMyAction(botonNoto,updateNotosTodos)
                }}
              >
                Marcar todas como leídas
              </Typography>
            <Typography
                sx={{fontSize:"12px", textDecorationLine:"underline", cursor:"pointer"}}
                color="primary"
                //onClick={handleDismissAll}
                onClick={()=>{
                 // console.log(notoIni)
                  performMyAction(botonNoto,deleteNotosTodosSin,notoIni?.notos?.user)
                }}
              >
                Eliminar todas
              </Typography>

             
            </Grid>
            {[].concat(notoIni?.notos?.notos)
              .sort(function (a, b) {
                    return (
                      new Date(b.fecha) -
                      new Date(a.fecha)
                    );
                  })
            .map((item, index) => (
              <CartaNotificacion
              user={user}
                key={item._id}
                item={item}
                setStateDrawer={setStateDrawer}
                botonNoto={botonNoto}
                performMyAction={performMyAction}
              />
        
            ))}
          </Grid>
        
      ) : (
        <Grid 
        sx={{display:"flex", flex:"1 1", alignItems:"center", justifyContent:"center", padding:"16px",
        marginTop:"0px"
        }}
       
        >
        <Grid sx={{height:"calc(100vh - 32px - 0px)", display:"flex", flexDirection:"column"}}>
        <Typography 
              sx={{fontSize:"28px", fontWeight:"600", lineHeight:"1"}}
              >Notificaciones
              </Typography>
          <Typography 
          sx={{textAlign:"center", fontSize:"24px", marginTop:"auto", marginBottom:"auto"}}
          color="text.secondary"
          >
           No tienes notificaciones por ahora.
          </Typography>
        </Grid>
        
        </Grid>
      )}
      
      </Grid>
  )
}

export default DrawerNotificaciones