import { useEffect, useState } from 'react';
import {  useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const NavItem = ({ title, items, setOpenSidebar, paneleo }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  const [expanded, setExpanded] = useState("")
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
    for(let r=0; r<=items.length-1;r++){
      if(items[r].href===window.location.pathname){
        setExpanded(paneleo)
      }
      else{
  
      }
    }
  }, []);

  const navigate = useNavigate();

  const hasActiveLink = () => items.find((i) => i.href === activeLink);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
};


  return (
    <>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent',
  '&:before': {
          display: 'none',
      } }}
      square
      expanded={expanded===paneleo}
      onChange={handleChange(paneleo)}
      >
        <AccordionSummary
          expandIcon={items.length<=1 ? null : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0, paddingLeft:"24px",
      paddingRight:"24px",
      backgroundColor: items.length<=1 && hasActiveLink() && "#241c1514"
       }}
       className={"hovereo"}
    onClick={items.length<=1 ? (()=>{
     
      navigate(items[0].href)
    
      setOpenSidebar(false);
      }
      ):(()=>{})}
        >
          <Typography
            fontWeight={hasActiveLink() ? 600 : 400}
            color={hasActiveLink() ? '#241c15' : '#241c15'}
            sx={{lineHeight:"2", marginTop:"auto", marginBottom:"auto", marginLeft:"8px"}}
          >
            {title}
          </Typography>
        </AccordionSummary>
        {items.length>1 &&
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={0}>
            {items.map((p, i) => (
              <Grid item key={i} xs={12}>
                <Button
                  size={'large'}
                  component={'a'}
                  onClick={()=>{
                    if(p.state){
                      navigate('/', p.state)
                      setOpenSidebar(false);
                //  window.scrollTo(0,0)
                    } else{
                      navigate(p.href)
                  setOpenSidebar(false);
                    }
                 
                }}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color:
                      activeLink === p.href
                      ? "#241c15"
                  : "#241c15",
                    backgroundColor:
                      activeLink === p.href
                      ? "#241c1514"
                  : 'transparent',
                    fontWeight: activeLink === p.href ? 600 : 400,
                    fontSize:"0.85rem",
              borderRadius:"0px",
              lineHeight:"1.5",
              paddingLeft:"40px",
                  }}
                  className={"hovereo"}
                >
                  {p.title}
                
                </Button>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>}
      </Accordion>
    </>
  );
};

export default NavItem;
