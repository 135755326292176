import { createContext, useCallback, useState } from "react";


  
  export const ModalContext = createContext({
    isOpen: false,
    updateIsOpen: () => {},
  });
  
  
  export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const updateIsOpen = useCallback((isOpen) => {
      setIsOpen(() => isOpen);
    }, []);
  
    return (
      <ModalContext.Provider value={{ isOpen, updateIsOpen }}>
        {isOpen && <div>Fake Modal</div>}
        {children}
      </ModalContext.Provider>
    );
  };