import { Avatar, AvatarGroup, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormHelperText, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Slide, TextField, Typography } from "@mui/material"
import { forwardRef, Fragment, useCallback, useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { ellipsis } from "polished";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmojiPicker, { Categories} from "emoji-picker-react";
import SendIcon from "@mui/icons-material/Send";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useDispatch, useSelector } from "react-redux";
import { createMensaje, eliminarArchivoEnviado, eliminarMensajeEnviado, getMensajes, reset, uploadFileImageMessage } from "../../features/mensajes/mensajesSlice";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { motion } from "framer-motion";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide 
    
    direction="up" ref={ref} {...props} />;
  });

  function capitalizar(string) {
    /* console.log(string) */
    const words = string.trim().split(" ");
  
    for (let i = 0; i < words.length; i++) {
      if (
        words[i] === "de" ||
        words[i] === "la" ||
        words[i] === "las" ||
        words[i] === "lo" ||
        words[i] === "los" ||
        words[i] === " " ||
        words[i] === "" ||
        words[i] === "en" ||
        words[i] === "y" ||
        words[i] === "del"
      ) {
      } else if (
        words[i] === "De" ||
        words[i] === "La" ||
        words[i] === "Las" ||
        words[i] === "Lo" ||
        words[i] === "Los" ||
        words[i] === "En" ||
        words[i] === "Y" ||
        words[i] === "Del"
      ) {
        words[i] = words[i][0].toLowerCase() + words[i].substr(1);
      } else {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
    }
  
    return words.join(" ");
  }

function MensajesAfuera({openedMen,setOpenedMen,user}) {
    const mounted = useRef(false);
    const enviarMen = useRef();
    const enviarMen2 = useRef();
    //const [min, setMin] = useState(openedMen?.min)
    const [mess, setMess]=useState("")
    const [isLoadImage, setIsLoadImage] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState("");
    const [emoji, setEmoji] = useState(false);
    const [value, setValue] = useState([]);
    const [previewUrl, setPreviewUrl] = useState([]);
  
    const [value2, setValue2] = useState([]);
    const [previewUrl2, setPreviewUrl2] = useState([]);

    const onDownload = async (url2) => {
      await fetch(url2).then((response) => {
        response.blob().then((blob) => {
          let url3 = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url3;
          a.download = url2.split("/").pop();
          a.click();
        });
      });
    };
    
  const handleImageChange = (event) => {
    for (let t = 0; t <= event.target.files.length - 1; t++) {
      let file = event.target.files[t];
      /*  console.log(file) */
      // console.log(file)
      /*  console.log([...value, { name: file.name, size: file.size }]) */
      setValue((prevState) => [
        ...prevState,
        { name: file.name, size: file.size },
      ]);

      const reader = new FileReader();
      reader.onload = (e) => {
        /*  console.log(e.target.result) */
        setPreviewUrl((prevState) => [...prevState, e.target.result]);
        // insertImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event) => {
    for (let t = 0; t <= event.target.files.length - 1; t++) {
      let file = event.target.files[t];
      /*  console.log(file) */
      // console.log(file)
      /*  console.log([...value, { name: file.name, size: file.size }]) */
      setValue2((prevState) => [
        ...prevState,
        { name: file.name, size: file.size },
      ]);

      const reader = new FileReader();
      reader.onload = (e) => {
        /*  console.log(e.target.result) */
        setPreviewUrl2((prevState) => [...prevState, e.target.result]);
        // insertImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickAway = () => {
    setEmoji(false);
  };


  const dispatch = useDispatch();
  const performMyAction = useCallback(
    (Ref, action, data) => {
      return (Ref.current = dispatch(action(data)));
    },
    [dispatch]
  );

      const { mensajes, isLoading } = useSelector((state) => state.mensajes);


    useEffect(()=>{
        mounted.current = true;
        
        setMess("")
        setEmoji(false)
        setIsLoadImage(false)
        setSelectedEmoji("")
        setValue([])
        setValue2([])
        setPreviewUrl([])
        setPreviewUrl2([])
        return () => {
            mounted.current = false;
            
            // dispatch(reset());
            enviarMen.current?.abort();
            enviarMen2.current?.abort();
            //reset()
        }
     },[openedMen?.id])
   
     const texto2=useRef()
    
  const handleClickOpen = () => {
    setOpenedMen({...openedMen,open:true});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick"){
        return;
    } 
   
    setOpenedMen({...openedMen,open:false});
    //setMin(false)
  };
  //console.log(openedMen?.min)

  const useStyles = makeStyles({
    dialog: {
      position: 'fixed',
      right: '-20px',
      bottom: '-20px',
      width:"300px",
      zIndex:"9999",
      
    }
  });
  const classes = useStyles();
  const gridoRef = useRef(null);
  const gridoRef2 = useRef(null);

  const [flek, setFlek] = useState(false);
  const [flek2, setFlek2] = useState(false);

  useEffect(() => {
    //console.log("huevo")
    if (gridoRef.current && flek === false) {
      //  console.log("hueva")
      gridoRef.current.scrollTop = gridoRef.current.scrollHeight;
      if (flek2 !== false) {
        // console.log(flek2)
        if (flek2 === 0) {
          gridoRef.current.scrollTop = flek2;
        } else {
          gridoRef.current.scrollTop = flek2;
        }
      }
    }
  }, [gridoRef, openedMen?.id, mensajes]);

  //console.log(footerRef.current.offsetTop)
  return (
    
    <Dialog

   // BackdropProps={{invisible:true}}
     classes={{
  paper: classes.dialog
}}
disableEnforceFocus
disableScrollLock 
disableRestoreFocus
hideBackdrop
// closeAfterTransition
// disableAutoFocus
style={{pointerEvents:"none"}}
PaperProps={{style:{pointerEvents:"auto"}}}
  open={openedMen?.open?true:false}
  TransitionComponent={Transition}
  keepMounted
  onClose={handleClose}
  aria-describedby="alert-dialog-slide-description"
  sx={{p:"0px", zIndex:"13000!important",}}
>
   <DialogTitle
    sx={{
                      backgroundColor: "#f9b934",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
   >
     <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        fontSize={"1rem"}
                        component="p"
                        fontWeight={700}
                        lineHeight={"2"}
                        sx={{ marginTop: "auto", marginBottom: "auto" }}
                        style={ellipsis(undefined,1)}
                      >
                       {openedMen?.user && (capitalizar(openedMen?.user?.firstname) +
                  " " +
                  capitalizar(openedMen?.user.lastname).split(" ")[0])}
                      </Typography>
                      <Grid sx={{display:"flex"}}>
                         <Button
                        onClick={()=>{
                            setOpenedMen({...openedMen,min:!openedMen?.min})
                        }}
                        disableRipple
                        size="small"
                        variant="text"
                        className="noHover45"
                       // disabled={isLoad2}
                        sx={{ minWidth: "35px", width:"24px!important" }}
                      >
                        <ExpandMoreIcon
                          size="small"
                          sx={{ height: "20px", color: "black", transform:openedMen?.min?"rotate(180deg)":"none", transition:"transform 100ms ease" }}
                        />
                      </Button>
                      <Button
                        onClick={handleClose}
                        disableRipple
                        size="small"
                        variant="text"
                        className="noHover45"
                       // disabled={isLoad2}
                       sx={{ minWidth: "35px", width:"24px!important" }}
                      >
                        <CloseIcon
                          size="small"
                          sx={{ height: "20px", color: "black" }}
                        />
                      </Button>
                      </Grid>
                     
                    </Grid>
   </DialogTitle>
   
  
   <>
    
    <DialogContent
    sx={{height:openedMen?.min===false?"220px":"0px",
    maxHeight:openedMen?.min===false?"220px":"0px",
    transition:"height 100ms ease, max-height 100ms ease, padding 100ms ease",
    padding:openedMen?.min===true?"0px":"0px",
    
    }}
    >
    {((mensajes && mensajes.length === 0)||(mensajes &&
              mensajes.length > 0 &&
              mensajes[0].mensajes &&
              mensajes[0]?.mensajes?.length === 0)||
              [].concat(mensajes[0].mensajes).filter((item)=>{
                if(item.id===openedMen?.id)
                return item
              })?.length===0  )&&
              <PerfectScrollbar>
              <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="noMensajesMail"
            >
            
           
              <DraftsOutlinedIcon
                color="disabled"
                sx={{ marginTop: "16px", marginBottom: "0px" }}
                style={{ fontSize: 120 }}
              />
           
              <Typography
                sx={{
                  marginTop: "0px",
                  letterSpacing: "-0.025em",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
                color="text.secondary"
              >
                ¡No hay mensajes!
              </Typography>
             
            </motion.div>
            </PerfectScrollbar>
             }

    {mensajes && mensajes.length>0 && mensajes[0].mensajes&&
      [].concat(mensajes[0].mensajes)
      .filter((item) => {
                      if (item.id === openedMen?.id) {

                      
                        return item;
                      }
                    }).map((item2, index2) => (
                      <Grid
                        ref={gridoRef2}
                        key={item2.id}
                        sx={{
                          height: "200px", 
                          maxHeight: "200px" ,
                          mr: "0px",
                          ml: "16px",
                          mt: "8px",
                          mb: "8px",
                        }}
                      >
                    
                        <PerfectScrollbar
                          containerRef={(ref) => {
                            gridoRef.current = ref;
                          }}
                        >
                          <Grid ref={gridoRef} sx={{ pr: "16px" }}>
                            {[]
                              .concat(item2.cuerpo)
                              .sort(function (a, b) {
                                return new Date(a.fecha) - new Date(b.fecha);
                              })
                              .map((item, index) => (
                                <Grid key={item.fecha}>
                               
                                  {(index === 0 ||
                                    (index >= 1 &&
                                      new Date(item.fecha).getDate() +
                                        "/" +
                                        (new Date(item.fecha).getMonth() + 1) +
                                        "/" +
                                        new Date(item.fecha).getFullYear() !==
                                        new Date(
                                          item2.cuerpo[index - 1].fecha
                                        ).getDate() +
                                          "/" +
                                          (new Date(
                                            item2.cuerpo[index - 1].fecha
                                          ).getMonth() +
                                            1) +
                                          "/" +
                                          new Date(
                                            item2.cuerpo[index - 1].fecha
                                          ).getFullYear())) && (
                                    <Divider
                                      sx={{ fontSize: "0.8rem", mb: "8px" }}
                                    >
                                      {new Date(item.fecha).getDate() +
                                        "/" +
                                        (new Date(item.fecha).getMonth() + 1) +
                                        "/" +
                                        new Date(item.fecha).getFullYear()}
                                    </Divider>
                                  )}
                                 
                                  {item.texto && (
                                    <>
                                      <Grid
                                        
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            item?.enviado === false
                                              ? "flex-start"
                                              : "flex-end",
                                        }}
                                      >
                                        {/*         {console.log(index, item2.cuerpo.length-1)} */}

                                        <Typography
                                          sx={{
                                            fontSize: "0.85rem",
                                            display: "flex",
                                            justifyContent:
                                              item?.enviado === false
                                                ? "flex-start"
                                                : "flex-end",
                                            textAlign: "start",
                                            position: "relative",
                                            backgroundColor:
                                              item?.enviado === false
                                                ? "rgba(0, 0, 0, 0.06)"
                                                : "rgba(0, 0, 0, 0.25)",
                                            borderRadius: "10px",
                                            paddingRight:
                                              item?.enviado === true &&
                                              item?.texto !==
                                                "Mensaje eliminado"
                                                ? "16px"
                                                : "8px",
                                            paddingLeft: "8px",
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            maxWidth: "90%",
                                            whiteSpace: "pre-wrap",
                                          }}
                                        >
                                          {item.texto ===
                                          "Mensaje eliminado" ? (
                                            <em>Mensaje eliminado</em>
                                          ) : (
                                            item.texto
                                          )}
                                          {item?.enviado === true &&
                                            item.texto !==
                                              "Mensaje eliminado" && (
                                              <IconButton
                                                size="small"
                                                sx={{
                                                  position: "absolute",
                                                  zIndex: "22",
                                                  top: "4px",
                                                  right: "4px",
                                                  padding: "1px",
                                                  margin: "0px",
                                                  background: "##fff",
                                                  opacity: "0.5",
                                                  "&:hover": {
                                                    opacity: "0.3",
                                                    backgroundColor: "#fff",
                                                    height: "12px",
                                                    width: "12px",
                                                    color: "black",
                                                  },
                                                }}
                                                onClick={async () => {
                                                  setFlek(true);
                                                  await performMyAction(
                                                    enviarMen2,
                                                    eliminarMensajeEnviado,
                                                    {
                                                      itemCompleto: item,
                                                      idReceptor: item2.id,
                                                    }
                                                  );
                                                  setFlek(false);
                                                }}
                                              >
                                                <CloseIcon
                                                  sx={{
                                                    fontSize: "10px!important",

                                                    // backgroundColor:"rgba(30, 32, 34, 0.15)"
                                                  }}
                                                />
                                              </IconButton>
                                            )}
                                        </Typography>

                                        {/* <div ref={messagesEndRef} /> */}
                                      </Grid>
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          justifyContent:
                                            item?.enviado === false
                                              ? "flex-start"
                                              : "flex-end",
                                          mb: "8px",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "0.7rem" }}>
                                          {new Date(item.fecha).getHours() +
                                            ":" +
                                            (new Date(item.fecha).getMinutes() <
                                            10
                                              ? "0" +
                                                new Date(
                                                  item.fecha
                                                ).getMinutes()
                                              : new Date(
                                                  item.fecha
                                                ).getMinutes())}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                  {item.images &&
                                    item.images.length > 0 &&
                                    item.images.map((itemo, index2) => (
                                      <Fragment key={itemo + index2}>
                                        <Grid
                                          sx={{
                                            display: "flex",
                                            justifyContent:
                                              item?.enviado === false
                                                ? "flex-start"
                                                : "flex-end",
                                            position: "relative",
                                            width: "auto",
                                          }}
                                        >
                                          {/*         {console.log(index, item2.cuerpo.length-1)} */}
                                          {itemo === "Imagen eliminada" ? (
                                            <Typography
                                              sx={{
                                                fontSize: "0.85rem",
                                                display: "flex",
                                                justifyContent:
                                                  item?.enviado === false
                                                    ? "flex-start"
                                                    : "flex-end",
                                                textAlign: "start",
                                                position: "relative",
                                                backgroundColor:
                                                  item?.enviado === false
                                                    ? "rgba(0, 0, 0, 0.06)"
                                                    : "rgba(0, 0, 0, 0.25)",
                                                borderRadius: "10px",
                                                paddingRight:
                                                  item?.enviado === true &&
                                                  itemo !== "Imagen eliminada"
                                                    ? "16px"
                                                    : "8px",
                                                paddingLeft: "8px",
                                                paddingTop: "8px",
                                                paddingBottom: "8px",
                                                maxWidth: "90%",
                                                whiteSpace: "pre-wrap",
                                              }}
                                            >
                                              <em>Imagen eliminada</em>
                                            </Typography>
                                          ) : (
                                            <Grid
                                              sx={{
                                                display: "flex",
                                                justifyContent: item?.enviado === false
                                                ? "flex-start"
                                                : "flex-end",
                                                overflow:"hidden",
                                              }}
                                            >
                                              <Grid
                                                //ref={(element) => typoRef.current.push(element)}
                                                id="olo"
                                                onClick={() => {
                                                  onDownload(itemo);
                                                }}
                                                sx={{
                                                  fontSize: "0.85rem",
                                                  display: "flex",
                                                  justifyContent:
                                                  item?.enviado === false
                                                    ? "flex-start"
                                                    : "flex-end",
                                                  textAlign: "start",
                                                  cursor: "pointer",

                                                  // backgroundColor:
                                                  //   item?.enviado === false
                                                  //     ? "rgba(0, 0, 0, 0.06)"
                                                  //     : "rgba(0, 0, 0, 0.25)",
                                                  borderRadius: "10px",
                                                  paddingRight: "8px",
                                                  paddingLeft: "8px",
                                                  paddingTop: "8px",
                                                  paddingBottom: "8px",
                                                  maxWidth: "90%",
                                                  whiteSpace: "pre-wrap",
                                                  height: "100px",
                                                  overflow: "hidden",
                                                  //pointerEvents: "none",

                                                  "&:hover": {
                                                    pl: "4px",
                                                    pr: "4px",
                                                    pt: "4px",
                                                    pb: "4px",
                                                  },
                                                  //width:"100%",
                                                }}
                                              >
                                                <img src={itemo} alt="imagen enviada" className="imequeMensa"/>
                                                <Grid
                                                  id="download"
                                                  sx={{
                                                    // height:"calc(100% - 8px)",
                                                    top: "calc(46px - 12px)",
                                                    opacity: "0",
                                                    position: "absolute",
                                                    //right:"0px",
                                                    "&:hover": {
                                                      //  display:"flex",
                                                      opacity: "0.3",
                                                      cursor: "pointer",
                                                    },
                                                  }}
                                                >
                                                  <FileDownloadOutlinedIcon />
                                                </Grid>
                                              </Grid>
                                              {item?.enviado === true &&
                                                itemo !==
                                                  "Imagen eliminada" && (
                                                  <IconButton
                                                    id="leop44"
                                                    size="small"
                                                    sx={{
                                                      position: "absolute",
                                                      zIndex: "22",
                                                      top: "12px",
                                                      right: "8px",
                                                      padding: "1px",
                                                      margin: "0px",
                                                      background: "#fff",
                                                      opacity: "0.5",
                                                      "&:hover": {
                                                        opacity: "0.8",
                                                        backgroundColor: "#fff",
                                                        height: "12px",
                                                        width: "12px",
                                                        color: "black",
                                                      },
                                                    }}
                                                    onClick={async () => {
                                                      setFlek(true);
                                                      //console.log(itemo)
                                                      await performMyAction(
                                                        enviarMen2,
                                                        eliminarArchivoEnviado,
                                                        {
                                                          itemCompleto: item,
                                                          idReceptor: item2.id,
                                                          imagenDel: true,
                                                          itemo: itemo,
                                                        }
                                                      );
                                                      setFlek(false);
                                                    }}
                                                  >
                                                    <CloseIcon
                                                      sx={{
                                                        fontSize: "10px!important",

                                                        // backgroundColor:"rgba(30, 32, 34, 0.15)"
                                                      }}
                                                    />
                                                  </IconButton>
                                                )}
                                            </Grid>
                                          )}

                                          {/* <FileDownloadOutlinedIcon
                                 sx={{ height:"100%",
                                 //width:"auto",
                                  opacity: 0.5,
            
                                  position:"absolute",
                                  fontSize:"10px",
                                 "&:hover":{
                                  display:"flex",
                                  opacity:"0.5",
                                  cursor:"pointer",
                                 }
                                 }}
                               /> */}
                                        </Grid>
                                        <Grid
                                          sx={{
                                            display: "flex",
                                            justifyContent:
                                              item?.enviado === false
                                                ? "flex-start"
                                                : "flex-end",
                                            mb: "8px",
                                          }}
                                        >
                                          <Typography
                                            sx={{ fontSize: "0.7rem" }}
                                          >
                                            {new Date(item.fecha).getHours() +
                                              ":" +
                                              (new Date(
                                                item.fecha
                                              ).getMinutes() < 10
                                                ? "0" +
                                                  new Date(
                                                    item.fecha
                                                  ).getMinutes()
                                                : new Date(
                                                    item.fecha
                                                  ).getMinutes())}
                                          </Typography>
                                        </Grid>
                                      </Fragment>
                                    ))}
                                  {item.archivos &&
                                    item.archivos.length > 0 && (
                                      <List>
                                        {item.archivos.map((iteme, index) => (
                                          <Fragment key={iteme + index}>
                                            <Grid
                                              sx={{
                                                display: "flex",
                                                justifyContent:
                                                  item?.enviado === false
                                                    ? "flex-start"
                                                    : "flex-end",
                                              }}
                                            >
                                              {iteme === "Archivo eliminado" ? (
                                                <Typography
                                                  sx={{
                                                    fontSize: "0.85rem",
                                                    display: "flex",
                                                    justifyContent:
                                                      item?.enviado === false
                                                        ? "flex-start"
                                                        : "flex-end",
                                                    textAlign: "start",
                                                    position: "relative",
                                                    backgroundColor:
                                                      item?.enviado === false
                                                        ? "rgba(0, 0, 0, 0.06)"
                                                        : "rgba(0, 0, 0, 0.25)",
                                                    borderRadius: "10px",
                                                    paddingRight:
                                                      item?.enviado === true &&
                                                      iteme !==
                                                        "Archivo eliminado"
                                                        ? "16px"
                                                        : "8px",
                                                    paddingLeft: "8px",
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    maxWidth: "90%",
                                                    whiteSpace: "pre-wrap",
                                                  }}
                                                >
                                                  <em>Archivo eliminado</em>
                                                </Typography>
                                              ) : (
                                                <>
                                                  <ListItem
                                                    sx={{
                                                      fontSize: "0.85rem",
                                                      display: "flex",
                                                      // justifyContent:
                                                      //   item?.enviado === false
                                                      //     ? "flex-start"
                                                      //     : "flex-end",
                                                      textAlign: "start",
                                                      cursor: "pointer",

                                                      backgroundColor:
                                                        item?.enviado === false
                                                          ? "rgba(0, 0, 0, 0.06)"
                                                          : "rgba(0, 0, 0, 0.06)",
                                                      borderRadius: "10px",
                                                      paddingRight: "8px",
                                                      paddingLeft: "8px",
                                                      paddingTop: "8px",
                                                      paddingBottom: "8px",
                                                      maxWidth: "100%",
                                                      whiteSpace: "pre-wrap",
                                                      //  height:"10px",
                                                      width: "auto",
                                                    }}
                                                    onClick={() => {
                                                      onDownload(iteme.url);
                                                    }}
                                                  >
                                                    <ListItemAvatar>
                                                      <Avatar
                                                        alt="Preview"
                                                        sx={{
                                                          height: "40px",
                                                          padding: "0px",
                                                          width: "40px",
                                                        }}
                                                      >
                                                        <AttachFileOutlinedIcon />
                                                      </Avatar>
                                                    </ListItemAvatar>

                                                    <ListItemText
                                                      sx={{
                                                        marginBottom: "auto",
                                                        marginTop: "auto",
                                                        mr:
                                                          item?.enviado ===
                                                            true &&
                                                          iteme !==
                                                            "Archivo eliminado"
                                                            ? "40px"
                                                            : "4px",
                                                      }}
                                                      primary={
                                                        <Grid
                                                          component="span"
                                                          sx={{
                                                            marginBottom:
                                                              "auto",
                                                            marginTop: "auto",
                                                            display: "flex",
                                                            maxWidth: "120px",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              paddingRight:
                                                                // width <= 600
                                                                //   ? "40px"
                                                                //   :
                                                                   "auto",
                                                              fontSize:
                                                                "0.80rem",
                                                              display: "flex",
                                                              marginTop: "auto",
                                                              marginBottom:
                                                                "auto",
                                                              fontWeight: "500",
                                                            }}
                                                            style={ellipsis(
                                                              undefined,
                                                              1
                                                            )}
                                                          >
                                                            {iteme.name}
                                                          </Typography>
                                                        </Grid>
                                                      }
                                                      secondary={
                                                        <Grid
                                                          component="span"
                                                          sx={{
                                                            marginBottom:
                                                              "auto",
                                                            marginTop: "auto",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Typography
                                                            component="span"
                                                            color="rgba(0, 0, 0, 0.6)"
                                                            sx={{
                                                              fontSize:
                                                                "0.8rem",
                                                              marginTop: "auto",
                                                              marginBottom:
                                                                "auto",
                                                              pr: "2px",
                                                            }}
                                                            style={ellipsis(
                                                              undefined,
                                                              1
                                                            )}
                                                          >
                                                            <em>
                                                              {Math.round(
                                                                ((iteme.size +
                                                                  Number.EPSILON) /
                                                                  1000000) *
                                                                  100
                                                              ) /
                                                                100 +
                                                                "MB"}
                                                            </em>
                                                          </Typography>
                                                        </Grid>
                                                      }
                                                    />

                                                    {iteme !==
                                                      "Archivo eliminado" &&
                                                      item?.enviado ===
                                                        true && (
                                                        <ListItemSecondaryAction
                                                        //  sx={{top:"10px!important", right:"8px!important"}}
                                                        >
                                                          <IconButton
                                                            onClick={async () => {
                                                              setFlek(true);
                                                              await performMyAction(
                                                                enviarMen2,
                                                                eliminarArchivoEnviado,
                                                                {
                                                                  itemCompleto:
                                                                    item,
                                                                  idReceptor:
                                                                   openedMen?.id,
                                                                  archivoDel: true,
                                                                  itemo: iteme,
                                                                }
                                                              );
                                                              setFlek(false);
                                                            }}
                                                          >
                                                            <CloseIcon
                                                              sx={{
                                                                fontSize:
                                                                  "12px!important",
                                                              }}
                                                            />
                                                          </IconButton>
                                                        </ListItemSecondaryAction>
                                                      )}
                                                  </ListItem>
                                                </>
                                              )}
                                            </Grid>
                                            <Grid
                                              sx={{
                                                display: "flex",
                                                justifyContent:
                                                  item?.enviado === false
                                                    ? "flex-start"
                                                    : "flex-end",
                                                mb: "8px",
                                              }}
                                            >
                                              <Typography
                                                sx={{ fontSize: "0.7rem" }}
                                              >
                                                {new Date(
                                                  item.fecha
                                                ).getHours() +
                                                  ":" +
                                                  (new Date(
                                                    item.fecha
                                                  ).getMinutes() < 10
                                                    ? "0" +
                                                      new Date(
                                                        item.fecha
                                                      ).getMinutes()
                                                    : new Date(
                                                        item.fecha
                                                      ).getMinutes())}
                                              </Typography>
                                            </Grid>
                                          </Fragment>
                                        ))}
                                      </List>
                                    )}
                                </Grid>
                              ))}

                            {/*  {item.cuerpo[0].texto} */}
                            {/*  {executeScroll()} */}
                          </Grid>
                        </PerfectScrollbar>
                      </Grid>
                    ))
    }
 
  </DialogContent>
  
{openedMen?.min!==true &&
<>
{previewUrl2 && previewUrl2.length > 0 && (
                  <>
                    <Divider
                      sx={{
                        mr: "0px",
                        ml: "0px",
                      }}
                    />

                    <List
                      sx={{
                        backgroundColor: "rgb(0,0,0,0.03)",
                        mr: "0px",
                        ml:  "0px",
                        pt: "0px",
                        pb: "0px",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <AvatarGroup
                            max={4}
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              width: "100%",
                            }}
                            //variant="rounded"
                          >
                            {previewUrl2.map((item, index) => (
                              <Avatar
                                //variant="rounded"
                                key={item + index}
                                alt="Preview"
                                sx={{
                                  height: "40px",
                                  padding: "0px",
                                  width: "40px",
                                }}
                              >
                                <AttachFileOutlinedIcon />
                              </Avatar>
                            ))}
                          </AvatarGroup>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            ml: "8px",
                            mr: "40px",
                            marginBottom: "auto",
                            marginTop: "auto",
                          }}
                          primary={
                            <Grid
                              component="span"
                              sx={{
                                marginBottom: "auto",
                                marginTop: "auto",
                                display: "flex",
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                                style={ellipsis(undefined, 1)}
                              >
                                {previewUrl2.length === 1
                                  ? value2[0].name
                                  : previewUrl2.length + " archivos a adjuntar"}
                              </Typography>
                            </Grid>
                          }
                          secondary={
                            <Grid
                              component="span"
                              sx={{
                                marginBottom: "auto",
                                marginTop: "auto",
                                display: "flex",
                              }}
                            >
                              <Typography
                                component="span"
                                color="rgba(0, 0, 0, 0.6)"
                                sx={{
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  pr: "2px",
                                }}
                                style={ellipsis(undefined, 1)}
                              >
                                <em>
                                  {Math.round(
                                    ((value2.reduce(
                                      (partialSum, a) => partialSum + a.size,
                                      0
                                    ) +
                                      Number.EPSILON) /
                                      1000000) *
                                      100
                                  ) /
                                    100 +
                                    "MB"}
                                </em>
                              </Typography>
                            </Grid>
                          }
                        />
                      </ListItem>

                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            setValue2([]);
                            setPreviewUrl2([]);
                          }}
                          disabled={isLoadImage}
                          className={isLoadImage ? "desabMui2" : ""}
                        >
                          <CloseIcon sx={{ fontSize: "20px!important" }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </List>
                  </>
                )}
                {previewUrl && previewUrl.length > 0 && (
                  <>
                    <Divider
                      sx={{
                        mr: "0px",
                        ml: "0px",
                      }}
                    />

                    <List
                      sx={{
                        backgroundColor: "rgb(0,0,0,0.03)",
                        mr: "0px",
                        ml: "0px",
                        pt: "0px",
                        pb: "0px",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <AvatarGroup variant="rounded" max={3}>
                            {previewUrl.map((item, index) => (
                              <Avatar
                                key={item + index}
                                variant="rounded"
                                src={item}
                                alt="Preview"
                                sx={{
                                  height: "40px",
                                  padding: "0px",
                                  width: "auto",
                                  maxWidth: "100px",
                                }}
                              />
                            ))}
                          </AvatarGroup>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            ml: "8px",
                            mr: "40px",
                            marginBottom: "auto",
                            marginTop: "auto",
                          }}
                          primary={
                            <Grid
                              component="span"
                              sx={{
                                marginBottom: "auto",
                                marginTop: "auto",
                                display: "flex",
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                                style={ellipsis(undefined, 1)}
                              >
                                {previewUrl.length === 1
                                  ? value[0].name
                                  : previewUrl.length + " imágenes a adjuntar"}
                              </Typography>
                            </Grid>
                          }
                          secondary={
                            <Grid
                              component="span"
                              sx={{
                                marginBottom: "auto",
                                marginTop: "auto",
                                display: "flex",
                              }}
                            >
                              <Typography
                                component="span"
                                color="rgba(0, 0, 0, 0.6)"
                                sx={{
                                  fontSize: "0.8rem",
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                  pr: "2px",
                                }}
                                style={ellipsis(undefined, 1)}
                              >
                                <em>
                                  {Math.round(
                                    ((value.reduce(
                                      (partialSum, a) => partialSum + a.size,
                                      0
                                    ) +
                                      Number.EPSILON) /
                                      1000000) *
                                      100
                                  ) /
                                    100 +
                                    "MB"}
                                </em>
                              </Typography>
                            </Grid>
                          }
                        />
                      </ListItem>

                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            setValue([]);
                            setPreviewUrl([]);
                          }}
                          className={isLoadImage ? "desabMui2" : ""}
                          disabled={isLoadImage}
                        >
                          <CloseIcon sx={{ fontSize: "20px!important" }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </List>
                 
                  </>
                )}
    <Grid
                  sx={{
                    width: "100%",
                    paddingLeft:  "16px" ,
                    paddingRight:"16px",
                    display: "block",
                    position: "relative",
                  }}
                >
<TextField
                    placeholder={"Escribe tu mensaje"}
                    size="small"
                   
                    inputRef={texto2}
                    InputProps={{
                      style: { fontSize: "0.80rem" },
                    }}
                    inputProps={{ style: { transition: "height 100ms ease" } }}
                    InputLabelProps={{
                      style: { fontSize: "0.8rem" },
                    }}
                    value={mess || ""}
                    onChange={(ev) => setMess(ev.target.value)}
                    multiline
                    rows={3}
                    sx={{ width: "inherit" }}
                   
                  />
                </Grid>
                {Math.round(
                  ((value2.reduce((partialSum, a) => partialSum + a.size, 0) +
                    Number.EPSILON) /
                    1000000) *
                    100
                ) /
                  100 +
                  Math.round(
                    ((value.reduce((partialSum, a) => partialSum + a.size, 0) +
                      Number.EPSILON) /
                      1000000) *
                      100
                  ) /
                    100 >
                  10 && (
                  <FormHelperText sx={{ color: "error.main", ml: "16px" }}>
                    Los archivos e imágenes adjuntas no pueden superar los 10MB
                  </FormHelperText>
                )}
                </>
}


  <DialogActions sx={{
    height:openedMen?.min===false?"60.5px":"0px",
    maxHeight:openedMen?.min===false?"60.5px":"0px",
    transition:"height 100ms ease, max-height 100ms ease, padding 100ms ease",
    padding:openedMen?.min===true?"0px":"auto",
  }}>
  {openedMen?.min!==true &&
  <>

 
                
    {/* <Button onClick={handleClose}>Disagree</Button>
    <Button onClick={handleClose}>Agree</Button> */}
    <Grid
                  sx={{
                    display: "flex",
                    marginTop: "8px",
                    width: "100%",
                    justifyContent: "space-between",
                   // paddingLeft: width >= 750 ? "16px" : "0px",
                  //  paddingRight: width < 750 ? "8px" : "16px",
                  }}
                >
                  <Grid sx={{ position: "relative", display: "flex" }}>
                    <IconButton
                      disabled={isLoadImage }
                      className={
                        isLoadImage 
                          ? "desabMui2"
                          : ""
                      }
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      component="label"
                      sx={{ marginRight: "0px" }}
                    >
                      <input
                        disabled={isLoadImage }
                        hidden
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
text/plain, application/pdf, image/*, audio/*, video/*"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                      />
                      <AttachFileOutlinedIcon />
                    </IconButton>
                    <IconButton
                      disabled={isLoadImage }
                      className={
                        isLoadImage 
                          ? "desabMui2"
                          : ""
                      }
                      component="label"
                      //onClick={handleImageChange}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      sx={{ marginRight: "0px" }}
                    >
                      <input
                        disabled={isLoadImage }
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        multiple
                      />
                      <ImageOutlinedIcon />
                    </IconButton>

                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={handleClickAway}
                    >
                      <Grid>
                        <Grid
                          sx={{
                            position: "absolute",
                            top: "-200px",
                            left: "-0px",
                          }}
                        >
                          {emoji && (
                            <EmojiPicker
                              previewConfig={{
                                //defaultEmoji: string; // defaults to: "1f60a"
                                //defaultCaption: string; // defaults to: "What's your mood?"
                                showPreview: false, // defaults to: true
                              }}
                              searchDisabled
                              onEmojiClick={async (e) => {
                                setEmoji(false);
                                setSelectedEmoji(e.emoji);
                                texto2.current?.focus();
                                await setMess(mess + e.emoji);

                                //console.log(selectedEmoji)
                              }}
                              autoFocusSearch={false}
                              height={200}
                              width={280}
                              categories={[
                                {
                                  category: Categories.SUGGESTED,
                                  name: "Recientes",
                                },
                                {
                                  category: Categories.SMILEYS_PEOPLE,
                                  name: "Emojis y emociones",
                                },
                                {
                                  category: Categories.ANIMALS_NATURE,
                                  name: "Animales",
                                },
                                {
                                  name: "Comidas",
                                  category: Categories.FOOD_DRINK,
                                },
                                {
                                  category: Categories.TRAVEL_PLACES,
                                  name: "Viajes y Lugares",
                                },
                                {
                                  category: Categories.ACTIVITIES,
                                  name: "Actividades",
                                },
                                {
                                  category: Categories.OBJECTS,
                                  name: "Objetos",
                                },
                                {
                                  category: Categories.SYMBOLS,
                                  name: "Símbolos",
                                },
                                {
                                  category: Categories.FLAGS,
                                  name: "Banderas",
                                },
                              ]}
                            />
                          )}
                        </Grid>
                        <IconButton
                          disabled={isLoadImage }
                          className={
                            isLoadImage 
                              ? "desabMui2"
                              : ""
                          }
                          onClick={() => {
                            //if(emoji===false){
                            setEmoji(!emoji);
                            // }
                          }}
                        >
                          <EmojiEmotionsOutlinedIcon />
                        </IconButton>
                      </Grid>
                    </ClickAwayListener>
                  </Grid>

                  <Button
                    sx={{
                      borderRadius: "20px",
                      height: "40px",
                      fontWeight: "500",
                      color: "#151a30",
                      width: "97.3px",
                    }}
                    className={isLoadImage ? "desabMui" : ""}
                    disabled={
                      !openedMen?.id ||
                      (!mess &&
                        previewUrl.length === 0 &&
                        previewUrl2.length === 0)  ||
                      isLoadImage || 
                      Math.round(
                        ((value2.reduce(
                          (partialSum, a) => partialSum + a.size,
                          0
                        ) +
                          Number.EPSILON) /
                          1000000) *
                          100
                      ) /
                        100 +
                        Math.round(
                          ((value.reduce(
                            (partialSum, a) => partialSum + a.size,
                            0
                          ) +
                            Number.EPSILON) /
                            1000000) *
                            100
                        ) /
                          100 >
                        10
                    }
                    variant="contained"
                    color="secondary"
                    endIcon={!isLoadImage && <SendIcon size={20} />}
                    onClick={async () => {
                      //  console.log("Hola")
                      if (
                        (previewUrl && previewUrl.length > 0) ||
                        (previewUrl2 && previewUrl2.length > 0)
                      ) {
                        setIsLoadImage(true);

                        // setContactoSele(contacto[0].user)
                        await performMyAction(
                          enviarMen,
                          uploadFileImageMessage,
                          {
                            userId: user._id,
                            idReceptor: openedMen?.id,
                            cuerpo: mess,
                            fecha: new Date(),
                            datos: previewUrl,
                            value: value,
                            datos2: previewUrl2,
                            value2: value2,
                          }
                        );
                        if (mounted.current === true) {
                          setIsLoadImage(false);
                        }
                      } else {
                        performMyAction(enviarMen, createMensaje, {
                          userId: user._id,
                          idReceptor: openedMen?.id,
                          cuerpo: mess,
                          fecha: new Date(),
                        });
                      }
                      if (mounted.current === true) {
                        setPreviewUrl([]);
                        setPreviewUrl2([]);
                        setValue([]);
                        setValue2([]);
                        setMess("");
                      }
                    }}
                  >
                    {isLoadImage ? (
                      <CircularProgress color="primary" size="1.2rem" />
                    ) : (
                      "Enviar"
                    )}
                  </Button>
                </Grid>
    </>
  }
    
  </DialogActions>
  </>

  
 
 
</Dialog>
  )
}

export default MensajesAfuera