import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import  SidebarNav2 from './components/SidebarNav/SidebarNav2';
import {  Grid, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from 'react-device-detect';

const Sidebar2 = ({ pages, open, variant, onClose, user, onLogout, setOpenSidebar, stateDrawer, setStateDrawer }) => {
/*   const dispatch= useDispatch()
  useEffect(()=>{
    const d = dispatch(getUser())
    return ()=>{
      d?.abort()
      dispatch(reset())
    }
  },[dispatch]) */
  
  return (
    <>
   
    <Drawer
    
      anchor="left"
      onClose={() => onClose()
      
      }
      open={open}
      variant={variant}
      sx={{
        
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 300,
        },
      }}
    >
    {isMobile ?( 
      <Box
        sx={{
          height: '100%',
          paddingTop: 1,
          paddingBottom: 1,
        }}

      >
      <Grid >
    
        <SidebarNav2 pages={pages} user={user} onLogout={onLogout} setOpenSidebar={setOpenSidebar}
        stateDrawer={stateDrawer}
        setStateDrawer={setStateDrawer}

        />
{/* <IconButton size="large" className="pruee2">
<CloseOutlinedIcon sx={{fontSize:"30px"}} />
</IconButton> */}


    </Grid>
      </Box>
     ):( <PerfectScrollbar>
      <Box
        sx={{
          height: '100%',
          paddingTop: 1,
          paddingBottom: 1,
        }}

      >
      <Grid >
    
        <SidebarNav2 pages={pages} user={user} onLogout={onLogout} setOpenSidebar={setOpenSidebar}
        stateDrawer={stateDrawer}
        setStateDrawer={setStateDrawer}

        />
{/* <IconButton size="large" className="pruee2">
<CloseOutlinedIcon sx={{fontSize:"30px"}} />
</IconButton> */}


    </Grid>
      </Box>
      </PerfectScrollbar>)}
   
    </Drawer>
    
    {/* <Grid sx={{position:"absolute", top:"0px", left:"50px"}} */}
    <Grid sx={{position:"absolute", top:"0px", left:"50px"}}
              // initial={{ position: "absolute", scale: 0, top: "0px", left:"50px" }}
              // animate={{ position: "absolute", 
              // scale: open?(1):(0), 
              // transition: { delay: 0 },
              // top: "0px",
              // left:"50px"
              //  }}
              // transition={{ duration: 0 }}
            >
    <IconButton onClick={()=>{
      setOpenSidebar(false)
    }} size="large" className='pruee2' sx={{display:open?("inline-flex"):("none")}}>
<CloseOutlinedIcon sx={{fontSize:"30px"}} />
</IconButton>
</Grid>

</>

  );
};



export default Sidebar2;
