import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import deleteService from './deleteService'

const initialState = {
    delete: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
  }
  


export const deleteContacto = createAsyncThunk(
    'deletes/deleteContacto',
    async (userMail, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.deleteContacto(userMail,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteLink = createAsyncThunk(
    'deletes/deleteLink',
    async (link, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.deleteLink(link,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const postDelete = createAsyncThunk(
    'deletes/postDelete',
    async (deleteData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.postDelete(deleteData,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const postDeleteOtros = createAsyncThunk(
    'deletes/postDeleteOtros',
    async ( deleteData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.postDeleteOtros(deleteData,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteSusMedio = createAsyncThunk(
    'deletes/deleteSus',
    async (userId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.deleteSusmedio(userId,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteLinkInvita = createAsyncThunk(
    'deletes/deleteLinkInvita',
    async (onempresasId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.deleteLinkInvita(onempresasId,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteLinkInvitaOtros = createAsyncThunk(
    'deletes/deleteLinkInvitaOtros',
    async (email, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */ 
        return await deleteService.deleteLinkInvitaOtros(email,token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const deleteFileFolder = createAsyncThunk(
    'deletes/deleteFileFolder',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await deleteService.deleteFileFolder(token)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  
  
  export const deleteSlice = createSlice({
    name: 'delete',
    initialState,
    reducers: {
      reset: (state) => initialState,
    },
    extraReducers: (builder) => {
      builder
        
       /*  .addCase(deleteContacto.pending, (state) => {
          state.isLoading = true
        }) */
        .addCase(deleteContacto.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(deleteContacto.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        /* .addCase(deleteLink.pending, (state) => {
          state.isLoading = true
        }) */
        .addCase(deleteLink.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(deleteLink.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        /* .addCase(postDelete.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(postDelete.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(postDelete.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
         /*  .addCase(postDeleteOtros.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(postDeleteOtros.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(postDeleteOtros.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          /* .addCase(deleteSusMedio.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(deleteSusMedio.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteSusMedio.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          /* .addCase(deleteLinkInvita.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(deleteLinkInvita.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteLinkInvita.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
          /* .addCase(deleteLinkInvitaOtros.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(deleteLinkInvitaOtros.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteLinkInvitaOtros.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
           /* .addCase(deleteFileFolder.pending, (state) => {
            state.isLoading = true
          }) */
          .addCase(deleteFileFolder.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
          })
          .addCase(deleteFileFolder.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })
    },
  })

  export const { reset } = deleteSlice.actions
export default deleteSlice.reducer