
import { useEffect, useMemo } from 'react';
import { usePageRoute } from './usePageRoute';


export const FallbackPageWrapper = ({ children }) => {
     const { onLoad } = usePageRoute();

     const render = useMemo(() => children, [children]);
 

    useEffect(() => {
        onLoad(render);
    }, [onLoad, render]);

    return render;
   
};

export default FallbackPageWrapper;