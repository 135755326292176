import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/redes/'


// Get user notificaciones
const getRedes = async (token,signal) => {
    /*   console.log("Ha llegado acá") */
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response = await axiosPrivate.get(API_URL + "get", config)
    /* console.log(response) */
      return response.data
}

//edit notificaciones
const editRedes = async(redId, modData, token,signal) => {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }

    const response = /* await */ axiosPrivate.patch(API_URL + "update/" + redId, modData, config)

    return response.data
  }

  // Delete notificaciones 
const deleteRedes = async (redId, token,signal) => {
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
  signal:signal,
}
/* console.log(busId) */
const response = /* await */ axiosPrivate.delete(API_URL + "deleteRedes/" + redId, config)
/* console.log(response) */
return response.data
}


  // Delete Otros notificaciones 
  const createRedes = async (data, token,signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
 /*  console.log(config) */
    const response = await axiosPrivate.post(API_URL + "create/", data, config)
  
    return response.data
  }

  const seleccionarRedes = async (data, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =/*  await  */axiosPrivate.post(API_URL + "seleccionarRedes/", data, config)
  
    return response.data
  }

  // Get user invitaciones
const getIdsInvitaciones = async (token,signal) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get("/api/invitaciones/getIds", config)
  
    return response.data
  }
  
  const getIdsContactos = async (token,signal) => {
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get("/api/contactos/getIds", config)
  
    return response.data
  }
  

  const buscarUsuarios = async (data, token,signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.post(API_URL + "buscarUsuarios/", data, config)
    /* console.log(response.data) */
    return response.data
  }

  const redesService = {
    getRedes,
    seleccionarRedes,
    createRedes,
    getIdsContactos,
    getIdsInvitaciones,
    deleteRedes,
    editRedes,
    buscarUsuarios,
  }

  export default redesService;