import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import onempresasService from './onempresasService'


const initialState = {
  onempresas: [],
  equipo:[],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new onempresas
export const createOnempresas = createAsyncThunk(
  'onempresas/create',
  async (onempresasData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.createOnempresas(onempresasData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onempresas
export const getOnempresas = createAsyncThunk(
  'onempresas/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getOnempresas(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onboard
export const getOnempresas2 = createAsyncThunk(
  'onempresas/getAll2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getOnempresas(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

// Get user onempresas
export const getUsersOnempresasId = createAsyncThunk(
  'onempresas/getUsersOnempresasId',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getUsersOnempresasId(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onempresas
export const getUsersOnempresasId2 = createAsyncThunk(
  'onempresas/getUsersOnempresasId2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getUsersOnempresasId2(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getUsersOnempresasId4 = createAsyncThunk(
  'onempresas/getUsersOnempresasId4',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getUsersOnempresasId2(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onempresas
export const deleteAndGetSeveralUsers = createAsyncThunk(
  'onempresas/deleteAndGetSeveralUsers',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.deleteAndGetSeveralUsers(userId,token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user onempresas
export const getUsersOnempresasId3 = createAsyncThunk(
  'onempresas/getUsersOnempresasId3',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.getUsersOnempresasId(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

// Delete user onempresas
export const deleteOnempresas = createAsyncThunk(
  'onempresas/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.deleteOnempresas(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete Several users onempresas
export const deleteSeveralUsers = createAsyncThunk(
  'onempresas/users/delete',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await onempresasService.deleteSeveralUsers(userId, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit several users
export const editAnotherUser = createAsyncThunk(
  "onempresas/editAnotherUser", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await onempresasService.editAnotherUser(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)



//edit onempresas
export const editOnempresas = createAsyncThunk(
  "onempresas/editOnempresas", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
  
      return await onempresasService.editOnempresas(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


//edit onempresas
export const editOnempresas2 = createAsyncThunk(
  "onempresas/editOnempresas2", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
  
      return await onempresasService.editOnempresas(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

//edit onempresas
export const editOnempresas3 = createAsyncThunk(
  "onempresas/editOnempresas3", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
  
      return await onempresasService.editOnempresas2(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Modificar Contacto email
export const uploadFileEmpresas = createAsyncThunk(
  'onempresas/uploadFileEmpresas',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.uploadFileEmpresas(imageData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// UploadFile2
export const uploadFile2On = createAsyncThunk(
  'onempresas/uploadFile2On',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.uploadFile2On(imageData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// crear Link
export const crearLink = createAsyncThunk(
  'onempresas/crearLink',
  async (linkData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.crearLink(linkData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

// crear Link
export const reenviarLink = createAsyncThunk(
  'onempresas/reenviarlink',
  async (linkData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.reenviarLink(linkData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)


// crear Link
export const reenviarLink2 = createAsyncThunk(
  'onempresas/reenviarlink2',
  async (linkData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.reenviarLink2(linkData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {onempresas}=getState();
      /* console.log(onboard,onboard.length) */
      if(onempresas&&onempresas.onempresas&&onempresas.onempresas.length===0){
        return false
      }
    }
  }
)

// eliminar Link
export const elimLink = createAsyncThunk(
  'onempresas/elimLink',
  async (linkId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      /* console.log(token) */
      return await onempresasService.elimLink(linkId, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const onempresasSlice = createSlice({
  name: 'onempresas',
  initialState,
  reducers: {
    reset: (state) =>initialState,
    
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOnempresas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createOnempresas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas.push(action.payload)
      })
      .addCase(createOnempresas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getOnempresas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOnempresas.fulfilled, (state, action) => {
        state.isError= false
        state.isLoading = false
        state.isSuccess = true
        state.onempresas = action.payload
      })
      .addCase(getOnempresas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getOnempresas2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getOnempresas2.fulfilled, (state, action) => {
        state.isError= false
        state.isLoading = false
        state.isSuccess = true
        state.onempresas = action.payload
      })
      .addCase(getOnempresas2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteOnempresas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOnempresas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas = state.onempresas.filter(
          (onempresas) => onempresas._id !== action.payload.id
        )
      })
      .addCase(deleteOnempresas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteSeveralUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteSeveralUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.equipo = state.equipo.filter(
          (equipo) => equipo.id !== action.payload.id
        )
      })
      .addCase(deleteSeveralUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteAndGetSeveralUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAndGetSeveralUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        /* state.onempresas = action.payload.onempresas */
        state.equipo = action.payload.equipo
        
      })
      .addCase(deleteAndGetSeveralUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editAnotherUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editAnotherUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
       // console.log(state.equipo)
        const encon = state.equipo?.find((item)=>item.id===action.meta.arg.id)
     //  console.log(encon)
        if(encon){
        const itemMen =  state.equipo.indexOf(encon)
      //  console.log(itemMen)
        state.equipo[itemMen].cuenta=action.meta.arg.modData.cuenta
       }
       
        // state.onempresas.equipo = 
     /*    state.equipo = state.equipo.filter(
          (equipo) => equipo.id !== action.payload.id
        ) */
        state.message = "Todo Ok"
      })
      .addCase(editAnotherUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message ="Algo salió mal"
      })
     
      .addCase(editOnempresas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editOnempresas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
     
      })
      .addCase(editOnempresas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      .addCase(editOnempresas2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editOnempresas2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
     
      })
      .addCase(editOnempresas2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      .addCase(getUsersOnempresasId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUsersOnempresasId.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.equipo = action.payload
      })
      .addCase(getUsersOnempresasId.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getUsersOnempresasId2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUsersOnempresasId2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas = action.payload.onempresas
        state.equipo = action.payload.equipo
      })
      .addCase(getUsersOnempresasId2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getUsersOnempresasId4.pending, (state) => {
       // state.isLoading = true
      })
      .addCase(getUsersOnempresasId4.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas = action.payload.onempresas
        state.equipo = action.payload.equipo
      })
      .addCase(getUsersOnempresasId4.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getUsersOnempresasId3.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUsersOnempresasId3.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.equipo = action.payload
      })
      .addCase(getUsersOnempresasId3.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      /* .addCase(uploadFileEmpresas.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(uploadFileEmpresas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
      })
      .addCase(uploadFileEmpresas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      /* .addCase(uploadFile2On.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(uploadFile2On.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
      })
      .addCase(uploadFile2On.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
       .addCase(crearLink.pending, (state) => {
        state.isLoading = true
      }) 
      .addCase(crearLink.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
      })
      .addCase(crearLink.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(reenviarLink.pending, (state) => {
        state.isLoading = true
      }) 
      .addCase(reenviarLink.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.onempresas[0] = action.payload
      })
      .addCase(reenviarLink.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(reenviarLink2.pending, (state) => {
        state.isLoading = true
      }) 
      .addCase(reenviarLink2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = "Todo ok"
        const encon = state.onempresas[0].equipoInactivo?.find((item)=>item[0]===action.meta.arg.linkId)
          //console.log(encon)
           if(encon){
           const itemMen =  state.onempresas[0].equipoInactivo?.indexOf(encon)
          // console.log(itemMen)
           state.onempresas[0].equipoInactivo[itemMen][2]=action.meta.arg.cuenta
          }
          
       // state.onempresas[0] = action.payload
      })
      .addCase(reenviarLink2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = "Algo salió mal"
      })
      /* .addCase(elimLink.pending, (state) => {
        state.isLoading = true
      }) */
      .addCase(elimLink.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = "Archivo eliminado"
      })
      .addCase(elimLink.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = "Algo salió mal"
      })
      .addCase(editOnempresas3.pending, (state) => {
        /*  state.isLoading = true */
       })
       .addCase(editOnempresas3.fulfilled, (state, action) => {
         state.isLoading = false
         state.isSuccess = true
         /* console.log({...action.meta.arg.modData}) */
         if(action.meta.arg.modData.anoFundacion){
          action.meta.arg.modData.anoFundacion=action.meta.arg.modData.anoFundacion.toISOString()
        }
        
           state.onempresas[0] = {...state.onempresas[0], ...action.meta.arg.modData}
        
         
       })
       .addCase(editOnempresas3.rejected, (state, action) => {
         state.isLoading = false
         state.isError = true
         state.message = action.payload
       
       })
   
  },
})

export const { reset } = onempresasSlice.actions
export default onempresasSlice.reducer
