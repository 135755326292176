import React from "react";
import "./footer.styles.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  /* const [sending, setSending] = useState(false)
  async function subscribeEmail(email) {
    setSending(true)
    await fetch('/subscribe', { method: 'POST', body: { email }})
    setSending(false)
  } */

  const [isActive, setActive] = useState(true);
  const [isActive2, setActive2] = useState(true);
  const [isActive3, setActive3] = useState(true);
  const [isActive4, setActive4] = useState(true);

  const toggleClass1 = () => {
    setActive(!isActive);
  };

  const toggleClass2 = () => {
    setActive2(!isActive2);
  };
  const toggleClass3 = () => {
    setActive3(!isActive3);
  };
  const toggleClass4 = () => {
    setActive4(!isActive4);
  };

  const año = new Date();

  return (
    <div className="fuchi1">
     <svg width="100%" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
  <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" fill="rgb(241, 245, 249)" />
</svg>
      <div className="casi-todo-fuch">
        <div className="primer-div-foot">
          
          <nav className="nav-footer">
            {/* <div className="oculto">
              <a>
                <img src={Flipflap} className="imagew2"></img>
              </a>

              <h3>Suscríbete a nuestro Newsletter:</h3>
              <Newsletter />
            </div> */}

            <ul className="oculto2 nuevo-uno">
              <li className="nuevo-dos">
                <div
                  className={isActive ? "nuevo-tres colapsable" : "nuevo-tres"}
                  onClick={toggleClass1}
                >
                  Candidatos{" "}
                  {isActive ? (
                    <FontAwesomeIcon icon={faChevronRight} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="rotacion"
                    />
                  )}
                </div>
                <ul
                  className={isActive ? "nuevo-cuatro dentro" : "nuevo-cuatro"}
                  onClick={toggleClass1}
                >
                 
                  <li
                    className={isActive ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass1}
                  >
                    <Link className="pruebaLinkUl" to={"/"} state={{targetId: "comoFunca"}}>¿Cómo funciona?</Link>
                  </li>
                  <li
                    className={isActive ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass1}
                  >
                    <Link className="pruebaLinkUl" to={"/"} state={{ targetId: "porqueRekloot" }}>¿Porqué Rekloot?</Link>
                  </li>
                  <li
                    className={isActive ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass1}
                  >
                    <Link className="pruebaLinkUl" to={"/planes"}>Planes</Link>
                  </li>
                  <li
                    className={isActive ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass1}
                  >
                    <Link className="pruebaLinkUl" to={"/registrarse"}>Regístrate</Link>
                  </li>
                  <li
                    className={isActive ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass1}
                  >
                    <Link className="pruebaLinkUl" to={"/login"}>Inicia sesión</Link>
                  </li>
                  
                </ul>
              </li>

              <li className="nuevo-dos">
                <div
                  className={isActive2 ? "nuevo-tres colapsable" : "nuevo-tres"}
                  onClick={toggleClass2}
                >
                  Reclutadores{" "}
                  {isActive2 ? (
                    <FontAwesomeIcon icon={faChevronRight} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="rotacion"
                    />
                  )}
                </div>
                <ul
                  className={isActive2 ? "nuevo-cuatro dentro" : "nuevo-cuatro"}
                  onClick={toggleClass2}
                >
                  <li
                    className={isActive2 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass2}
                  >
                    <Link className="pruebaLinkUl" to={"/reclutadores"}>Para Empresas</Link>
                  </li>
                  
                  <li
                    className={isActive2 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass2}
                  >
                    <Link className="pruebaLinkUl" to={"/empresas"}>Regístrate</Link>
                  </li>
                  <li
                    className={isActive2 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass2}
                  >
                    <Link className="pruebaLinkUl" to={"/login"}>Inicia sesión</Link>
                  </li>
                </ul>
              </li>

              <li className="nuevo-dos">
                <div
                  className={isActive3 ? "nuevo-tres colapsable" : "nuevo-tres"}
                  onClick={toggleClass3}
                >
                  Nosotros{" "}
                  {isActive3 ? (
                    <FontAwesomeIcon icon={faChevronRight} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="rotacion"
                    />
                  )}
                </div>
                <ul
                  className={isActive3 ? "nuevo-cuatro dentro" : "nuevo-cuatro"}
                  onClick={toggleClass3}
                >
                  <li
                    className={isActive3 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass3}
                  >
                    <Link className="pruebaLinkUl" to={"/quienes-somos"}>¿Quienes somos?</Link>
                  </li>
                  <li
                    className={isActive3 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass3}
                  >
                    <Link className="pruebaLinkUl" to={"/blog"}>Blog</Link>
                  </li>
                  <li
                    className={isActive3 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass3}
                  >
                    <Link className="pruebaLinkUl" to={"/trabajos"}>Trabaja con nosotros</Link>
                  </li>
                  <li
                    className={isActive3 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass3}
                  >
                    <Link className="pruebaLinkUl" to={"/noticias"}>Noticias</Link>
                  </li>
                  <li
                    className={isActive3 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass3}
                  >
                    <Link className="pruebaLinkUl" to={"/contacto"}>Contacto</Link>
                  </li>
                </ul>
              </li>

              <li className="nuevo-dos">
                <div
                  className={isActive4 ? "nuevo-tres colapsable" : "nuevo-tres"}
                  onClick={toggleClass4}
                >
                  Recursos{" "}
                  {isActive4 ? (
                    <FontAwesomeIcon icon={faChevronRight} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="rotacion"
                    />
                  )}
                </div>
                <ul
                  className={isActive4 ? "nuevo-cuatro dentro" : "nuevo-cuatro"}
                  onClick={toggleClass4}
                >
                  <li
                    className={isActive4 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass4}
                  >
                    <Link className="pruebaLinkUl" to={"/faq"}>FAQ</Link>
                  </li>
                  <li
                    className={isActive4 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass4}
                  >
                    <Link className="pruebaLinkUl" to={"/ayuda"}>Ayuda</Link>
                  </li>
                  <li
                    className={isActive4 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass4}
                  >
                    <Link className="pruebaLinkUl" to={"/terminos-y-condiciones"}>
                      Términos y condiciones
                    </Link>
                  </li>
                  <li
                    className={isActive4 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass4}
                  >
                    <Link className="pruebaLinkUl" to={"/privacidad"}>Política de Privacidad</Link>
                  </li>
                  <li
                    className={isActive4 ? "nuevo-cinco dentro" : "nuevo-cinco"}
                    onClick={toggleClass4}
                  >
                    <Link className="pruebaLinkUl" to={"/cookies"}>Política de Cookies</Link>
                  </li>
                </ul>
              </li>
            </ul>

            <ul className="oculto3 nuevo-uno">
              <li className="nuevo-dos">
                <span className="nuevo-tres">Candidatos</span>
                <ul className="nuevo-cuatro">
                <li className="nuevo-cinco">
                    <Link to={"/"} state={{targetId: "comoFunca"}}>¿Cómo Funciona?</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/"} state={{ targetId: "porqueRekloot" }}>¿Porqué Rekloot?</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/planes"}>Planes</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/registrarse"}>Regístrate</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/login"}>Inicia sesión</Link>
                  </li>
                  
                </ul>
              </li>

              <li className="nuevo-dos">
                <span className="nuevo-tres">Reclutadores</span>
                <ul className="nuevo-cuatro">
                  <li className="nuevo-cinco">
                    <Link to={"/reclutadores"}>Para Empresas</Link>
                  </li>
                 
                  <li className="nuevo-cinco">
                    <Link to={"/empresas"}>Regístrate</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/login"}>Inicia sesión</Link>
                  </li>
                </ul>
              </li>

              <li className="nuevo-dos">
                <span className="nuevo-tres">Nosotros</span>
                <ul className="nuevo-cuatro">
                  <li className="nuevo-cinco">
                    <Link to={"/quienes-somos"}>¿Quienes somos?</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/blog"}>Blog</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/trabajos"}>Trabaja con nosotros</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/noticias"}>Noticias</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/contacto"}>Contacto</Link>
                  </li>
                </ul>
              </li>

              <li className="nuevo-dos">
                <span className="nuevo-tres">Recursos</span>
                <ul className="nuevo-cuatro">
                  <li className="nuevo-cinco">
                    <Link to={"/faq"}>FAQ</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/ayuda"}>Ayuda</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/terminos-y-condiciones"}>
                      Términos y condiciones
                    </Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/privacidad"}>Política de Privacidad</Link>
                  </li>
                  <li className="nuevo-cinco">
                    <Link to={"/cookies"}>Cookies</Link>
                  </li>
                </ul>
              </li>

             {/*  <div className="link-prueba">
                <a>
                  <img src={Flipflap} className="imagew2"></img>
                </a>

                <h3>Suscríbete a nuestro Newsletter:</h3>
                <Newsletter />
              </div> */}
            </ul>
          </nav>
        </div>
      </div>

      <div className="mod-linea"></div>

      <div className="abajo-foot">
        <div className="medio-uno">
          <ul className="medio-dos supa1">
            <li className="medio-cuatro">
              <Link to={"/quienes-somos"} className="medio-cinco">
                ¿Quienes Somos?
              </Link>
            </li>
            <li className="medio-cuatro">
              <Link  to="/contacto" className="medio-cinco">
                Contacto
              </Link>
            </li>
            <li className="medio-cuatro">
              <Link className="medio-cinco" to={"/terminos-y-condiciones"}>
                Términos y Condiciones
              </Link>
            </li>
            <li className="medio-cuatro">
              <Link to={"/privacidad"} className="medio-cinco">
                Privacidad
              </Link>
            </li>
            <li className="medio-cuatro">
              <Link  to="/cookies" className="medio-cinco">
                Cookies
              </Link>
            </li>
          </ul>

          <ul className="medio-dos supa">
            <li className="medio-seis">
              <div 
              onClick={()=>{
                window.open("https://www.linkedin.com/company/rekloot", '_blank').focus();
                return false
              }}
              className="linkeofoot"
              >
                <svg
                  className="social-foot color-Link"
                  width="32"
                  height="32"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.816 0C15.466 0 16 .512 16 1.152v13.696c0 .63-.533 1.152-1.184 1.152H1.184C.534 16 0 15.477 0 14.848V1.152C0 .512.533 0 1.184 0zM3.563 4.96a1.382 1.382 0 0 0 0-2.763c-.768 0-1.376.619-1.376 1.376 0 .768.608 1.387 1.376 1.387zm10.069 4.49c0-2.058-.437-3.637-2.848-3.637-1.152 0-1.92.63-2.24 1.227h-.032V5.995H6.24v7.637h2.368V9.856c0-.992.181-1.963 1.419-1.963 1.216 0 1.237 1.142 1.237 2.027v3.712h2.368zM2.368 13.633h2.379V5.995H2.368z"
                    fillRule="evenodd"
                    
                  ></path>
                </svg>
              </div>
            </li>

            <li className="medio-seis">
            <div 
              onClick={()=>{
                window.open("https://www.instagram.com/rekloot", '_blank').focus();
                return false
              }}
              className="linkeofoot"
              >
                <svg
                  className="social-foot insta color-Insta"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </div>
            </li>

            <li className="medio-seis">
            <div 
              onClick={()=>{
                window.open("https://www.twitter.com/rekloot", '_blank').focus();
                return false
              }}
              className="linkeofoot"
              >
                <svg
                  className="social-foot color-Twitter"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M31.993 6.077c-1.177.523-2.44.876-3.77 1.033 1.355-.812 2.396-2.098 2.887-3.63-1.27.75-2.673 1.3-4.168 1.592C25.744 3.797 24.038 3 22.15 3c-3.626 0-6.563 2.938-6.563 6.563 0 .514.057 1.016.17 1.496C10.3 10.783 5.464 8.17 2.227 4.2c-.565.97-.89 2.098-.89 3.3 0 2.28 1.16 4.287 2.92 5.465-1.076-.035-2.088-.33-2.973-.82v.08c0 3.182 2.26 5.835 5.264 6.438-.55.15-1.13.23-1.73.23-.423 0-.833-.04-1.233-.117.834 2.606 3.26 4.504 6.13 4.558-2.245 1.76-5.075 2.81-8.15 2.81-.53 0-1.053-.03-1.566-.09C2.905 27.914 6.355 29 10.062 29c12.072 0 18.675-10 18.675-18.675 0-.284-.008-.568-.02-.85 1.283-.925 2.395-2.08 3.276-3.398z"></path>
                </svg>
              </div>
            </li>

            <li className="medio-seis">
            <div 
              onClick={()=>{
                window.open("https://www.facebook.com/rekloot", '_blank').focus();
                return false
              }}
              className="medio-siete linkeofoot"
              >
                <svg
                  className="social-foot color-Facebook"
                  width="30"
                  height="30"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.115 0H.885A.883.883 0 0 0 0 .885v14.23c0 .49.395.885.885.885h7.659V9.803h-2.09v-2.4h2.09V5.62c0-2.069 1.27-3.189 3.104-3.189.875 0 1.643.053 1.867.096v2.155h-1.291c-1.003 0-1.184.469-1.184 1.173v1.547h2.379l-.31 2.4H11.04V16h4.075c.49 0 .885-.395.885-.885V.885A.883.883 0 0 0 15.115 0z" />
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="copyright-foot">
        <div className="ultimo-div">
          <span>
            ©{año.getFullYear()} Rekloot SpA. Todos los derechos reservados.
          </span>
        </div>
      </div>
    </div>
    /*  <footer className="fut">
      <div className="todo-foot">
        <div className="primero-foot">
        
          <div className="footerCol2">
          <img src={Flipflap} className="imagew" ></img>

          <Newsletter />
          
          </div>

          <div className="footerCol">
            <h3 className="footerTit">Productos</h3>
            <ul className="footer-li">
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
            </ul>
          </div>
          <div className="footerCol">
            <h3 className="footerTit">Productos</h3>
            <ul className="footer-li">
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>

              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
            </ul>
          </div>
          <div className="footerCol">
            <h3 className="footerTit">Productos</h3>
            <ul className="footer-li">
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
            </ul>
          </div>
          <div className="footerCol">
            <h3 className="footerTit">Productos</h3>
            <ul className="footer-li">
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
              <li className="footerIt">
                <a className="linkeo-foot">porque</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer> */
  );
};

export default Footer;
