import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import susMedioService from './susMedioService'

const initialState = {
    susMedio: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    isLoadingCl: false,
  }

  
// Get user onempresas
export const getSusMedio = createAsyncThunk(
    'susMedio/getAll',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.getSusMedio(token, thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  // Get user onboard
export const getSusMedio2 = createAsyncThunk(
  'susMedio/getAll2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await susMedioService.getSusMedio(token, thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {susMedio}=getState();
      /* console.log(onboard,onboard.length) */
      if(susMedio&&susMedio.susMedio&&susMedio.susMedio.length===0){
        return false
      }
    }
  }
)

  export const oneClickFinish = createAsyncThunk(
    'susMedio/oneClickFinish',
    async (oneData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.oneClickFinish(oneData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const oneClickDelete = createAsyncThunk(
    'susMedio/oneClickDelete',
    async (_, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.oneClickDelete(token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const oneClickK = createAsyncThunk(
    'susMedio/oneClickK',
    async (oneData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.oneClickK(oneData,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    },
    {
      condition: (_,{getState})=>{
        const {susMedio}=getState();
        /* console.log(onboard,onboard.length) */
        if(susMedio&&susMedio.susMedio&&susMedio.susMedio.length===0){
          return false
        }
      }
    }
  )

  export const oneClickVal = createAsyncThunk(
    'susMedio/oneClickVal',
    async (val, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.oneClickVal(val,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )

  export const oneClickInscripVal = createAsyncThunk(
    'susMedio/oneClickInscripVal',
    async (val, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token 
        /* const token = JSON.parse(localStorage.getItem("user")).token;  */
        return await susMedioService.oneClickInscripVal(val,token,thunkAPI.signal)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


  export const susMedioSlice = createSlice({
    name: 'susMedio',
    initialState,
    reducers: {
      reset: (state) =>initialState,
      
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getSusMedio.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getSusMedio.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.susMedio = action.payload
        })
        .addCase(getSusMedio.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(getSusMedio2.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getSusMedio2.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.susMedio = action.payload
        })
        .addCase(getSusMedio2.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        /* .addCase(oneClickFinish.pending, (state) => {
          state.isLoading = true
        }) */
        .addCase(oneClickFinish.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(oneClickFinish.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        /* .addCase(oneClickDelete.pending, (state) => {
          state.isLoading = true
        }) */
        .addCase(oneClickDelete.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(oneClickDelete.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        /* .addCase(oneClickK.pending, (state) => {
          state.isLoading = true
        }) */
        .addCase(oneClickK.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(oneClickK.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
         .addCase(oneClickVal.pending, (state) => {
          state.isLoadingCl = true
        }) 
        .addCase(oneClickVal.fulfilled, (state, action) => {
          state.isLoadingCl = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(oneClickVal.rejected, (state, action) => {
          state.isLoadingCl = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(oneClickInscripVal.pending, (state) => {
          state.isLoadingCl = true
        }) 
        .addCase(oneClickInscripVal.fulfilled, (state, action) => {
          state.isLoadingCl = false
          state.isSuccess = true
          state.message = action.payload
        })
        .addCase(oneClickInscripVal.rejected, (state, action) => {
          state.isLoadingCl = false
          state.isError = true
          state.message = action.payload
        })
     
    },
  })
  
  export const { reset } = susMedioSlice.actions
  export default susMedioSlice.reducer