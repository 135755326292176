import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {  useTheme } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const NavItemLi = ({ title, id, items, colorInvert = false, propmod, clase }) => {
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
      
    },
    popoverContent: {
      pointerEvents: "auto",
      opacity: "1",
      
      //transform: "translateX(60px)!important",
      
 //     transition: "visibility .3s,opacity .3s ease,transform .5s cubic-bezier(.33,0,.6,1.58)!important",
    },

   


  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);


  const navigate = useNavigate();

  const popoverAnchor = useRef(null);
  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = (e) => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  });

  const hasActiveLink = () => items.find((i) => i.href === activeLink);
  const linkColor = colorInvert ? "common.white" : "text.primary";
  const classes = useStyles();

  return (
    <li
      className={clase}
      onMouseEnter={(e) => handleClick(e, id)}
     onMouseLeave={handleClose}
    >
     
      <Box
      //sx={{ display: { xs: "none", md: "flex" } }}
      
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          aria-describedby={id}
          sx={{ cursor: "pointer" }}
          ref={popoverAnchor}
          //aria-owns={id}
        >
          <Typography
            fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 700}
            color={linkColor}
            fontSize="14px"
            lineHeight={"50px"}
          >
            {title}
          </Typography>
          <ExpandMoreIcon
            sx={{
              marginLeft: theme.spacing(1 / 4),
              width: 16,
              height: 16,
              transition: "transform 200ms ease",
              transform: openedPopoverId === id ? "rotate(180deg)" : "none",
              color: linkColor,
            }}
          />
        </Box>
        <Popover
        disableAutoFocus
        disableScrollLock
          className={classes.popover+" anima"}
          classes={{
            paper: classes.popoverContent,
          }}
        
          elevation={3}
          id={id}
          open={openedPopoverId === id}
          anchorEl={popoverAnchor.current}
    // PaperProps={{ onMouseLeave: handleClose}}
        onMouseEnter={(e)=>handleClick(e,id)}
         onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            ".MuiPaper-root": {
              maxWidth: items.length > 12 ? 350 : 250,
              padding: "0.5rem 0",
              // top: "2.75rem!important",
              marginTop: 2,
              border: "1px solid #ebeaeb",
              borderRadius: "8px",
              width: "220px",
              minWidth: "unset",
              // transformOrigin: "0px 0px!important",
              transform: propmod,
              mountOnEnter:true, 
              unmountOnExit:true
            },
          }}
          
          // TransitionComponent={Slide}
          TransitionProps={{timeout:400 }}
          
          
        >
          <Grid container spacing={0.5}>
            {items.map((p, i) => (
              <Grid item key={i} xs={items.length > 12 ? 6 : 12}>
                <Button
                  component={"a"}
                  onClick={()=>{
                    if(p.state){
                      navigate('/', p.state)
                //  window.scrollTo(0,0)
                    } else{
                      navigate(p.href)
                  window.scrollTo(0,0)
                    }
                
                }}
                  fullWidth
                  sx={{
                    justifyContent: "flex-start",
                    color:
                      activeLink === p.href
                        ? theme.palette.text.primary
                        : theme.palette.primary.dark,
                    backgroundColor:
                      activeLink === p.href
                        ? theme.palette.common.white
                        : theme.palette.common.white,
                    fontWeight: activeLink === p.href ? 600 : 400,
                    borderRadius: "0px",
                    height: "30px",
                    
                  }}
                  className={"recto"}
                  
                >
                  {p.title}
                  
                </Button>
              </Grid>
            ))}
          </Grid>
        </Popover>
      </Box>
    </li>
  );
};

export default NavItemLi;
