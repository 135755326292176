const dataSearch = [


        {
            id:1,
            question:"¿Cómo funciona?",
            topico:"preguntas más comunes",
            answer:"Rekloot es la primera plataforma de reclutamiento inverso en Chile. Dímos vuelta el modelo tradicional de búsqueda profesional, donde en este caso, son las empresas las que te buscan a tí y no al revés. Creemos que dando garantía de nuestros candidatos, con claridad de condiciones en sueldos, beneficios y otros, contribuirá a un proceso de selección más rápido, más eficiente, transparente y menos frustrante para ambas partes. Apuntamos a hacer un calze perfecto entre empresa / candidato."
        },
        {
            id:2,
            question:"¿Existe un periodo de prueba?",
            topico:"preguntas más comunes",
            answer:"¡En Rekloot, apenas te registras, tienes un periodo de prueba de forma gratuita! Si quieres potenciar tu perfil y estar en los primeros lugares de búsqueda, debes suscribirte a un plan premium mensual o anual. Los distintos planes los puedes encontrar en <a href='/planes'>www.rekloot.com/planes</a>."
        },
        {
            id:3,
            topico:"preguntas más comunes",
            question:"¿Cómo se protege mi privacidad?",
            answer:"Para nosotros es fundamental la privacidad de nuestros usuarios. Para esto, puedes seleccionar que se oculten tus datos reales a las empresas reclutadoras (nombre, apellido, contacto y nombre de empresas anteriores). Sólo se les notificará estos datos si es que aceptas su invitación. Para no perjudicar a los candidatos, se usará un seudónimo, que también puedes revisar en tu Perfil o en Preferencias / Privacidad. En cualquier momento puedes ver como te ven las empresas, en la pestaña Reclutadores, en la esquina superior derecha de tu pantalla."
        },
        {
            id:4,
            topico:"preguntas más comunes",
            question:"¿Cuáles son los planes existentes?",
            answer:"Para ver los planes, te recomendamos revisar www.rekloot.com/planes. Puedes contratar el plan mensual o anual de acceso a la plataforma. Aprovecha los valores de lanzamiento de: $8.990 / mes o con un 20% de descuento de forma anual: $86.300 / año."
        },
        {
            id:5,
            topico:"preguntas más comunes",
            question:"¿Existe un cobro para un usuario empresa o reclutador?",
            answer:"Queremos que el mayor número de empresas y reclutadores puedan usar el servicio de Rekloot y que los candidatos tengan visibilidad de las mejores y del mayor número de empresas posibles ¡En este caso, usar Rekloot no tiene costo alguno!"
        },
        {
            id:6,
            topico:"preguntas generales",
            question:"¿Quiénes somos?",
            answer:"Somos un grupo de emprendedores y profesionales chilenos, que vimos un espacio en el mercado de reclutamiento profesional que era muy necesario para los candidatos. Queremos potenciar y hacer que las personas puedan alcanzar sus sueños en el lugar preciso para ellos. El trabajo no significa sufrir, sino todo lo contrario, poder alcanzar todos tus sueños. Existimos para potenciar el desarrollo profesional de cada uno. Para saber más de nostros visita www.rekloot.com/quienes-somos."
        },
        {
            id:7,
            topico:"preguntas generales",
            question:"¿Cómo puedo modificar mis datos?",
            answer:"Para modificar tus datos de la cuenta, anda a Preferencias / General, donde podrás modificar tus datos generales. Si quieres modificar tu contraseña, navega a Preferencias / Contraseña. Si quieres modificar detalles de tu Perfil, anda a la pestaña Perfil en la esquina superior derecha de tu pantalla. Si eres usuario empresa, para modificar a tu empresa (sólo si eres administrador o jefatura), puedes hacerlo en la pestaña Perfil, ubicada en la parte superior derecha de tu menú principal."
        },
        {
            id:8,
            topico:"preguntas generales",
            question:"He olvidado mi contraseña",
            answer:"Si olvidaste tu contraseña navega a www.rekloot.com/login, selecciona Olvidaste tu contraseña e indica tu mail para que puedas reestablecer una nueva contraseña."
        },
        {
            id:9,
            topico:"preguntas generales",
            question:"¿Cómo funcionan las entrevistas / invitaciones?",
            answer:"Recuerda que en Rekloot eres tú el que acepta a las empresas y no al revés, por lo que dependerá 100% de tí el aceptar una invitación para una posición laboral. Si no la aceptas, la empresa no tendrá tu contacto (dependiendo de tu configuración de privacidad). Si la aceptas, podrán ponerse en contacto y seguir con el proceso de selección."
        },
        {
            id:10,
            topico:"preguntas generales",
            question:"¿Necesito un software especial para utilizar Rekloot?",
            answer:"No lo necesitas. Rekloot sólo necesita de un navegador web y una conexión a internet para funcionar. Puedes acceder desde tu computador, de tu tablet o de tu celular."
        },
        {
            id:11,
            topico:"preguntas generales",
            question:"¿Cuál es la política de notificaciones en Rekloot?",
            answer:"En general, sólo te contactaremos por mail. Una vez que te registres, recibirás de nosotros mails transaccionales, es decir, si olvidaste la contraseña, el resumen del pago de un mes, si quieres invitar a alguien al equipo de tu empresa u otro. Por defecto, hay tres listas en Rekloot, una que te guiará en tus primeros pasos, otra de novedades y noticias (máximo de 4 mails al mes) y una de ofertas especiales (máximo 1 mail al mes). Puedes modificar tu configuración de notificaciones en Preferencias / Notificaciones. Recuerda que puedes desuscribirte de la lista que quieras."
        },

        {
            id:12,
            topico:"preguntas de candidatos",
            question:"¿El periodo de prueba tiene restricciones?",
            answer:"El periodo de prueba no tiene ninguna restricción. Podrás aprovechar Rekloot con funcionalidades básicas de forma gratuita."
        },
        
        {
            id:13,
            topico:"preguntas de candidatos",
            question:"¿Me puedo cambiar de plan?",
            answer:"Para cambiarte de plan, en Preferencias / Suscripción, elimina tu forma de pago, selecciona tu plan nuevo, su forma de pago y estarás listo. El nuevo cobro se realizará cuando se termine el periodo activo, por lo que te puedes cambiar de plan cuando lo estimes conveniente."
        },
       
        {
            id:14,
            topico:"preguntas de candidatos",
            question:"¿Cuánto tiempo me debería demorar en encontrar un nuevo trabajo?",
            answer:"Esta pregunta no tiene una respuesta fija. Es variable y depende de tus habilidades, aptitudes, las necesidades del mercado, etc. De todas maneras, como referencia, para un cargo de primera línea, un proceso de búsqueda debería demorarse no menos de unos 6 meses."
        },
        {
            id:15,
            topico:"preguntas de candidatos",
            question:"¿Cómo puedo cancelar mi suscripción?",
            answer:"Puedes cancelar tu suscripción en el momento que quieras. Para hacerlo navega a: Preferencias / Suscripción y elimina tu forma de pago. Es importante mencionar que si no se agrega otra forma de pago, en el próximo periodo no podrás acceder a nuevas oportunidades en Rekloot, ni saldrás en la busqueda de los reclutadores."
        },
        
        {
            id:16,
            topico:"preguntas de candidatos",
            question:"¿Cómo puedo cancelar mi cuenta?",
            answer:"Puedes cerrar tu cuenta en el momento que quieras. Para hacerlo navega a: Preferencias / Eliminar Cuenta y confirma eliminar tu cuenta. Es importante mencionar que esta acción es irreversible y se perderán todos tus datos."
        },
   
  
       
        {
            id:17,
            topico:"preguntas de empresas",
            question:"¿Cómo agrego a alguien al equipo de mi empresa?",
            answer:"Para agregar a una persona al equipo, debes ir a Equipo / Agregar usuario. Recuerda revisar los distintos roles y permisos para cada usuario que crees."
        },
        {
            id:18,
            topico:"preguntas de empresas",
            question:"Permisos de usuarios",
            answer:"Para una cuenta de empresa a continuación se pueden revisar los distintos roles y los permisos asociados:"
        },
        {
            id:19,
            topico:"preguntas de empresas",
            question:"¿Cómo funcionan las invitaciones o búsqueda para un puesto?",
            answer:"Como usuario de empresa tu creas una búsqueda para una posición específica. Puedes buscar candidatos para estos puestos con muchos filtros, siendo sueldo, beneficios, habilidades, experiencia, educación, tipo de contrato, jornada, lugar donde trabajarían, sólo algunos de ellos. Si ves un candidato a preseleccionar puedes enviarle una invitación. Si este candidato la acepta, podrán ponerse en contacto por Rekloot y seguir el proceso de contratación respectivo."
        },
        {
            id:20,
            topico:"preguntas de empresas",
            question:"Debo contratar varios puestos ¿puedo enviar varias invitaciones?",
            answer:"Puedes enviar las invitaciones que creas necesarias para los puestos que estás buscando. Recuerda que los candidatos pueden aceptarlas o rechazarlas."
        },
        {
            id:21,
            topico:"preguntas de empresas",
            question:"¿Cómo puedo cancelar mi cuenta?",
            answer:"Puedes cerrar tu cuenta en el momento que quieras. Para hacerlo navega a: Preferencias / Eliminar Cuenta y confirma eliminar tu cuenta. Es importante mencionar que esta acción es irreversible y se perderán todos tus datos. Si existen más personas en el equipo, éstas no se eliminarán. Si quieres eliminar por completo tu empresa, debes ir a Preferencias / Eliminar Empresa."
        },
        {
            id:22,
            topico:"preguntas de empresas",
            question:"¿Cómo puedo eliminar mi empresa?",
            answer:"Puedes eliminar tu empresa en el momento que quieras. Para hacerlo navega a: Preferencias / Eliminar Empresa y confirma eliminar tu empresa. Esto sólo lo pueden hacer los administradores de la cuenta. Es importante mencionar que esta acción es irreversible y se perderán todos tus datos. Si existen más personas en el equipo, éstas también se eliminarán, en conjunto con sus datos. Si no quieres eliminar a otros usuarios, o a tu empresa, y quieres cerrar tu cuenta, debes ir a Preferencias / Eliminar Cuenta."
        },
  
        {
            id:23,
            topico:"preguntas de pagos",
            question:"¿Cómo funciona la suscripción?",
            answer:"Una vez que eligas un plan, ya sea mensual o anual, se activará la plataforma por el tiempo indicado. Posteriormente, se cobrará el valor del plan de forma mensual o anual de forma automática. Este cobro se hará a la forma de pago inscrita. Recuerda que puedes cancelar tu suscripción en cualquier momento."
        },
        {
            id:24,
            topico:"preguntas de pagos",
            question:"Formas de pago disponibles",
            answer:"Se pueden realizar pagos a través de Webpay, con tarjeta de crédito o débito según tu preferencia. Próximamente podrás pagar con PayPal y otros métodos seguros de pago."
        },
        {
            id:25,
            topico:"preguntas de pagos",
            question:"¿Los precios incluyen IVA?",
            answer:"Todos los precios de los planes de suscripción mensual o anual incluyen IVA."
        },
        {
            id:26,
            topico:"preguntas de pagos",
            question:"¿Dónde puedo ver el detalle de mis cobros?",
            answer:"Puedes revisar el estado de tus cobros históricos en la pestaña Preferencias / Suscripción / Historial. De todas maneras, enviaremos el detalle de los cobros a tu correo registrado en Rekloot."
        },
        {
            id:27,
            topico:"preguntas de pagos",
            question:"Políticas de reembolso",
            answer:"En Rekloot reembolsamos sólo en los casos donde haya un error técnico por nuestra parte. No podemos reembolsar meses no utilizados o casos donde los candidatos han olvidado detener la suscripción."
        },
        {
            id:28,
            topico:"preguntas de soporte",
            question:"¿Cómo me puedo contactar con el equipo de Rekloot?",
            answer:"Si tienes cualquier duda, comentario u otro, navega a www.rekloot.com/contacto y deja tu mensaje. Intentaremos siempre de responder dentro de las próximas 24 hrs hecha la consulta. Existe un equipo revisando cada uno de estos mensajes. Si quieres que te llamemos, en el mensaje, agrega tu número de contacto."
        },
        {
            id:29,
            topico:"preguntas de soporte",
            question:"Denunciar contenido o miembros inapropiados",
            answer:"Si ves algo en Rekloot que te moleste, te parezca ofensivo o molesto, porfavor denúncialo. Nos tomamos muy en serio las denuncias y que nuestra comunidad sea una libre de prejuicios, con un comportamiento adecuado de nuestros usuarios. Para denuncias, favor enviar un mail a equipo@rekloot.com con los detalles que causan el contacto. No se notificará a la persona que denuncies y se resguardará tu privacidad."
        },
        {
            id:30,
            topico:"preguntas de soporte",
            question:"Quiero trabajar con ustedes",
            answer:"¡Que buena noticia! Para contactarnos, porfavor navega a www.rekloot.com/trabajos y revisa si existen oportunidades que sean acorde a tu perfil. Siempre nos puedes dejar un mensaje en www.rekloot.com/contacto."
        },
        {
            id:31,
            topico:"preguntas de soporte",
            question:"Términos y Condiciones, Privacidad y Cookies",
            answer:"Es muy importante que antes de registrarte leas los términos y condiciones (www.rekloot.com/terminos-y-condiciones), la política de privacidad (www.rekloot.com/privacidad) y la política de cookies (www.rekloot.com/cookies). Para poder registrarte y usar la plataforma debes aceptar que has leído y estás de acuerdo con estos tres documentos."
        },
        {
            id:32,
            topico:"página de contacto",
            question:"Contáctanos",
            link:"/contacto",
            answer:"Si quieres contactarnos, favor rellena el siguiente formulario y te contactaremos en menos de 24 horas."
        },
        {
            id:33,
            topico:"centro de ayuda",
            question:"Ayuda",
            link:"/ayuda",
            answer:"Visita este link si quieres revisar toda la documentación de ayuda, guías, preguntas frecuentes, búsqueda y página de contacto."
        },
        {
            id:34,
            topico:"planes de suscripción",
            question:"Planes mensuales y anuales",
            link:"/planes",
            answer:"Revisa los detalles de los planes de acceso a la plataforma y sus valores mensuales / anuales."
        },
  
]


export default dataSearch;