import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import busquedasService from './busquedasService'


const initialState = {
  busquedas: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  getIds:[],
  getIdsCon:[],
}


// Get user onboard
export const getBusquedas = createAsyncThunk(
  'busquedas/get',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.getBusquedas(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getIdsInvitaciones = createAsyncThunk(
  'busquedas/getIds',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.getIdsInvitaciones(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getIdsContactos = createAsyncThunk(
  'busquedas/getIdsCon',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.getIdsContactos(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit notificaciones
export const editBusquedas = createAsyncThunk(
  "busquedas/editBusquedas", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
 //     console.log("SLICE" + token)
      return await busquedasService.editBusquedas(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

//edit notificaciones
export const editBusquedas2 = createAsyncThunk(
  "busquedas/editBusquedas2", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
 //     console.log("SLICE" + token)
      return await busquedasService.editBusquedas(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  },
  {
    condition: (_,{getState})=>{
      const {busquedas}=getState();
      /* console.log(onboard,onboard.length) */
      if(busquedas&&busquedas.busquedas&&busquedas.busquedas.length===0){
        return false
      }
    }
  }
)


// Delete user
export const deleteBusquedas = createAsyncThunk(
  'busquedas/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */ 
      return await busquedasService.deleteBusquedas(id,token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteAllBusquedas = createAsyncThunk(
  'busquedas/deleteAll',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.deleteAllBusquedas(userId,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteOtrosAllBusquedas = createAsyncThunk(
  'busquedas/deleteOtrosAll',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.deleteOtrosAllBusquedas(userId,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Post busqueda
export const createBusqueda = createAsyncThunk(
  'busquedas/crear',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.createBusquedas(data, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Post buscarUsuarios
export const buscarUsuarios = createAsyncThunk(
  'busquedas/buscarUsuarios',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.buscarUsuarios(data, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Post seleccionar
export const seleccionarBusqueda = createAsyncThunk(
  'busquedas/seleccionar',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await busquedasService.seleccionarBusqueda(data, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const busquedasSlice = createSlice({
  name: 'busquedas',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusquedas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getBusquedas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas = action.payload
      })
      .addCase(getBusquedas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getIdsInvitaciones.pending, (state) => {
        //state.isLoading = true
      })
      .addCase(getIdsInvitaciones.fulfilled, (state, action) => {
        //state.isLoading = false
        state.isSuccess = true
        state.getIds = action.payload
      })
      .addCase(getIdsInvitaciones.rejected, (state, action) => {
        //state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getIdsContactos.pending, (state) => {
        //state.isLoading = true
      })
      .addCase(getIdsContactos.fulfilled, (state, action) => {
        //state.isLoading = false
        state.isSuccess = true
        state.getIdsCon = action.payload
      })
      .addCase(getIdsContactos.rejected, (state, action) => {
        //state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(editBusquedas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editBusquedas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas.forEach((item, index)=>{
          if(item._id=== action.meta.arg.id){
            state.busquedas[index]={...state.busquedas[index], ...action.meta.arg.modData};
          }
        })
       
      })
      .addCase(editBusquedas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.meta.arg.id
      
      })
      .addCase(editBusquedas2.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editBusquedas2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas.forEach((item, index)=>{
          if(item._id=== action.payload.busId){
            state.busquedas[index]=action.payload.mod;
          }
        })
       
      })
      .addCase(editBusquedas2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      .addCase(deleteBusquedas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteBusquedas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas = state.busquedas.filter(
            (busquedas) => busquedas._id !== action.meta.arg
          )
      })
      .addCase(deleteBusquedas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.meta.arg
      })
      .addCase(deleteAllBusquedas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAllBusquedas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas = []
      })
      .addCase(deleteAllBusquedas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteOtrosAllBusquedas.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOtrosAllBusquedas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
      })
      .addCase(deleteOtrosAllBusquedas.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(createBusqueda.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createBusqueda.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas.forEach((item, index)=>{
          if(item.activo=== true){
            state.busquedas[index].activo=false;
          }
        })
        state.busquedas.push(action.payload)
       
      })
      .addCase(createBusqueda.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(seleccionarBusqueda.pending, (state) => {
        state.isLoading = true
      })
      .addCase(seleccionarBusqueda.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas.forEach((item, index)=>{
          if(item._id=== action.meta.arg.busId){
            state.busquedas[index].activo=true;
          } else{
            if(item.activo===true){
              state.busquedas[index].activo=false;
            }
          }
        })
        state.message = action.meta.arg.busId
      })
      .addCase(seleccionarBusqueda.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.meta.arg.busId
      })
      .addCase(buscarUsuarios.pending, (state) => {
        state.isLoading = true
      })
      .addCase(buscarUsuarios.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.busquedas.forEach((item, index)=>{
          if(item._id=== action.payload.busId){
            state.busquedas[index]=action.payload.mod;
           
          } 
        })
       /*  state.resultados = action.payload */
      })
      .addCase(buscarUsuarios.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = busquedasSlice.actions
export default busquedasSlice.reducer
