import { useEffect, useState } from 'react';
import {  useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { lineHeight } from '@mui/system';
import { Avatar, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../../../../../features/auth/authSlice';
import{ reset as resetMensajes} from '../../../../../../../../features/mensajes/mensajesSlice'
import{ reset as resetNotos} from '../../../../../../../../features/notos/notosSlice'
import iNoBounce from '../../../../../../../../hooks/inobounce';

const NavItem2 = ({ title, items, setOpenSidebar, paneleo, perfil, user, icon, stateDrawer, setStateDrawer, noto }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  const [expanded, setExpanded] = useState("")
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');

    for(let r=0; r<=items.length-1;r++){
      if(items[r].href===window.location.pathname){
        setExpanded(paneleo)
      }
      else{
  
      }
    }
  }, []);



/* window && window.location && window.location.pathname &&
 */




/*   const [expanded, setExpanded] = useState(window && window.location && window.location.pathname &&
    items && 
    items.incl); */

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const load = useSelector((state) => state.auth).isLoading;
  const navigate = useNavigate();

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function countWords(str) {
   
    return str.trim().split(/\s+/).length;
  }

  function stringAvatar(name) {

    if(countWords(name)===0){
      return {
        sx: {
          bgcolor: "red",
        },
        children: "NN",
      };
    } else if (countWords(name)===1  && name.length>1 ){
    
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[1][0])}`,
      };
      

    } else if (countWords(name)===1 && name.length===1 ){
      
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}`,
      };
      

    }
    
    else if(countWords(name)===2){
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    } else if(countWords(name)>=3){
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${capitalize(name.split(' ')[1][0])}`,
      };
    } else{
      return {
      
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name[0][0]}${capitalize(name[0][1])}`,
      };
    }
   

  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function capitalize2(string) {
    const words = string.trim()
    return words.charAt(0).toUpperCase() ;

  }

  function capitalizar(string) {
    /* console.log(string) */
    const words = string.trim().split(" ");
   
    for (let i = 0; i < words.length; i++) {
      if(words[i]==="de" || words[i]==="la" || words[i]==="las"|| words[i]==="lo" || words[i]==="los"|| words[i]===" " || words[i]==="" || words[i]==="en" || words[i]==="y" || words[i]==="del"){
        
      }
  
      else if(words[i]==="De" || words[i]==="La" || words[i]==="Las"|| words[i]==="Lo" || words[i]==="Los" || words[i]==="En" || words[i]==="Y" || words[i]==="Del"){
        words[i] = words[i][0].toLowerCase() + words[i].substr(1);
      }
  
      else{
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
       
    }
    
    return words.join(" ");
  }


  const dispatch = useDispatch();
 // const { user } = useSelector((state) => state.auth);


/*  const updateAndThenLogout = () => async (dispatch) => {
  return await dispatch(logout());
};
 */

  const onLogout = async () => {
     await dispatch(logout());
     dispatch(resetMensajes())
     dispatch(resetNotos())
    /*  dispatch(reset()); */
    navigate("/login");
  };

  const hasActiveLink = () => items.find((i) => i.href === activeLink);

  return (<>
{perfil?(<Accordion
  disableGutters
  elevation={0}
  sx={{ backgroundColor: 'transparent',
  '&:before': {
          display: 'none',
      } }}
  square
  expanded={expanded===paneleo}
  onChange={handleChange(paneleo)}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    sx={{ padding: 0, paddingLeft:"24px",
      paddingRight:"24px",  }}
     // background:"#241c1514"
    className={"hovereo"}
    
  >
  {/* {icon} */}

  {(user) && ((user.tipoCuenta==="persona") ? (user.profilePicture ? (
    <>
   
          <Avatar className="avatop avatop2" alt="avatar usuario"  imgProps={{referrerPolicy:"no-referrer"}}  src={user.profilePicture} />
          </>
        ) : (<>
          {load ? (<Avatar className="avatop"
         
          ><CircularProgress size="20px"/></Avatar>) : (<Avatar className="avatop"
          {...stringAvatar(capitalize2(user.firstname)+" "+capitalize2(user.lastname))}
          ></Avatar>)}
          </>
        )):(user.profilePicture ? (
          <>
          <Grid className="overlay3" sx={{background:"#fff!important"}}>
                       
                       </Grid>
          <Avatar className="avatop avatop2" alt="avatar usuario"  imgProps={{referrerPolicy:"no-referrer"}}  src={user.profilePicture} />
          </>
        ) : (
          <>
            {load? (<Avatar className="avatop"
         
         ><CircularProgress size="20px"/></Avatar>):(<Avatar className="avatop"
          {...stringAvatar(user.empresa ? capitalizar(user.empresa):(capitalizar(user.firstname) + " " + capitalizar(user.lastname)))}
          ></Avatar>)}
          </>
        )
          
        ))}
    <Typography
      fontWeight={hasActiveLink() ? 600 : 400}
      color={hasActiveLink() ? '#241c15' : '#241c15'}
    sx={{lineHeight:"2", marginTop:"auto", marginBottom:"auto", marginLeft:"8px"}}
    >
   
      {capitalize(title)}
    </Typography>
  </AccordionSummary>
  <AccordionDetails sx={{ padding: 0 }}>
    <Grid container spacing={0}>
      {items.map((p, i) => (
        <Grid item key={i} xs={12}>
          <Button
            size={'large'}
            component={'a'}
            onClick={p.href==="/logout" ? (()=>{
              setOpenSidebar(false);
              onLogout();
            }):(()=>{
              navigate(p.href)
              setOpenSidebar(false);
            })}
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              color:
                activeLink === p.href
                  ? "#241c15"
                  : "#241c15",
              backgroundColor:
                activeLink === p.href
                  ? "#241c1514"
                  : 'transparent',
              fontWeight: activeLink === p.href ? 400 : 400,
              fontSize:"0.85rem",
              borderRadius:"0px",
              paddingLeft:"40px",
              lineHeight:"1.5",
               

            }}
            className={"hovereo"}
          >

             {p.title}
            
          </Button>
        </Grid>
      ))}
    </Grid>
  </AccordionDetails>
</Accordion>):(<Accordion
  disableGutters
  elevation={0}
  sx={{ backgroundColor: 'transparent',
  '&:before': {
          display: 'none',
      } }}
  square
  expanded={expanded===paneleo}
  onChange={handleChange(paneleo)}
>
  <AccordionSummary
    expandIcon={items.length<=1 ? null : <ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
    sx={{ padding: 0, paddingLeft:"24px",
      paddingRight:"24px",
      backgroundColor: items.length<=1 && hasActiveLink() && "#241c1514"
       }}
    className={"hovereo"}
    onClick={items.length<=1 ? (()=>{
     if(noto===true){
setStateDrawer(true)
iNoBounce.enable();
     } else{
      navigate(items[0].href)
     }
      
      setOpenSidebar(false);
      }
      ):(()=>{})}
  >

  
  {/* {icon} */}
    <Typography
      fontWeight={hasActiveLink() ? 600 : 400}
      color={hasActiveLink() ? '#241c15' : '#241c15'}
      sx={{lineHeight:"2", marginTop:"auto", marginBottom:"auto", marginLeft:"8px"}}
    >
   
      {capitalize(title)}
    </Typography>
  </AccordionSummary>
  {items.length>1 &&
    <AccordionDetails sx={{ padding: 0 }}>
    <Grid container spacing={0}>
      {items.map((p, i) => (
        <Grid item key={i} xs={12}>
          <Button
            size={'large'}
            component={'a'}
            onClick={p.href==="/logout" ? (()=>{
              setOpenSidebar(false);
              onLogout();
            }):(()=>{
              navigate(p.href)
              setOpenSidebar(false);
            })}
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              color:
                activeLink === p.href
                  ? "#241c15"
                  : "#241c15",
              backgroundColor:
                activeLink === p.href
                  ? "#241c1514"
                  : 'transparent',
              fontWeight: activeLink === p.href ? 400 : 400,
              fontSize:"0.85rem",
              borderRadius:"0px",
           
              lineHeight:"1.5",
              paddingLeft:"40px",
            }}
            className={"hovereo"}
          >

             {p.title}
            
          </Button>
        </Grid>
      ))}
    </Grid>
  </AccordionDetails> 
  }
 
</Accordion>)}
      
</>
  );
};

export default NavItem2;
