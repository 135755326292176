import { axiosPrivate } from '../../API/refreshToken'
import {socket} from "../../components/Header2/components/Buffer"



const API_URL = "/api/notos/";


const getNotos = async (token, signal) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    };
  
    const response = await axiosPrivate.get(API_URL, config);
   
    return response.data;
  };

  // Create new goal
  const createNotos = async (notosData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL, notosData, config)
  
    socket?.emit("new notification", {
      userReceptor:notosData.userNotos,
      fecha: notosData.fecha,
      mensaje:notosData.mensaje,
      tipo:notosData.tipo,
      link:notosData.link,
      avatar:notosData?.avatar,
      nombre: notosData?.nombre,
      apellido:notosData?.apellido,
    })
    
    return response.data
  }


  const createNotosPersonal = async (notosData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =   await axiosPrivate.post(API_URL + "personal", notosData, config)
  
    socket?.emit("new notification", {
      userReceptor:notosData.userNotos,
      fecha: notosData.fecha,
      mensaje:notosData.mensaje,
      tipo:notosData.tipo,
      link:notosData.link,
    })
    return response.data
  }

  const updateNotos = async (notosData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL+"updateNotos", notosData, config)
  
    return response.data
  }


  const updateNotosTodos = async (notosData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL+"updateNotosTodos", notosData, config)
  
    return response.data
  }

  const deleteSingleNotos = async (notosData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL+"deleteNotos/",notosData, config)
  
    return response.data
  }


  const deleteNotosTodos = async (userId, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  await axiosPrivate.delete(API_URL+"deleteNotosTodos/"+userId, config)
  
    return response.data
  }

  const deleteNotosTodosSin = async (userId, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */ axiosPrivate.delete(API_URL+"deleteNotosTodos/"+userId, config)
  
    return response.data
  }


  const deleteAllTodosNotos = async (userId, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  await  axiosPrivate.delete(API_URL+"deleteAllTodosNotos/"+userId, config)
  
    return response.data
  }


  const notosService = {
   getNotos,
   createNotos,
   createNotosPersonal,
   updateNotos,
   updateNotosTodos,
   deleteSingleNotos,
   deleteNotosTodos,
   deleteNotosTodosSin,
   deleteAllTodosNotos,
}

export default notosService

