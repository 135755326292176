import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import invitacionesService from './invitacionesService'


const initialState = {
  invitaciones: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  isLoadingDel: false,
}


export const getInvitaciones = createAsyncThunk(
  'invitaciones/get',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await invitacionesService.getInvitaciones(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getInvitaciones2 = createAsyncThunk(
  'invitaciones/get2',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await invitacionesService.getInvitaciones(token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)




export const updateInvitaciones = createAsyncThunk(
  "invitaciones/updateInvitaciones", async({id, modData}, thunkAPI) =>{
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await invitacionesService.updateInvitaciones(id, modData, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const deleteInvitaciones = createAsyncThunk(
  'invitaciones/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.deleteInvitaciones(id,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteOtrosInvitaciones = createAsyncThunk(
  'invitaciones/deleteOtros',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.deleteOtrosInvitaciones(id,token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteInvitacionesEnviadas = createAsyncThunk(
  'invitaciones/deleteEnviadas',
  async ({id, idUserReceptor}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.deleteInvitacionesEnviadas(id,idUserReceptor,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteInvitacionesAceptadas = createAsyncThunk(
  'invitaciones/deleteAceptadas',
  async ({id, idUserReceptor}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.deleteInvitacionesAceptadas(id,idUserReceptor,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteInvitacionesIgnoradas = createAsyncThunk(
  'invitaciones/deleteIgnoradas',
  async ({id, idUserReceptor}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.deleteInvitacionesIgnoradas(id,idUserReceptor,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const ignorarInvitacion = createAsyncThunk(
  'invitaciones/ignorar',
  async ({id, idUserEnviador}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.ignorarInvitacion(id,idUserEnviador,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const aceptarInvitacion = createAsyncThunk(
  'invitaciones/aceptar',
  async ({id, idUserEnviador}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.aceptarInvitacion(id,idUserEnviador,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const ignorarInvitacionPersonas = createAsyncThunk(
  'invitaciones/ignorarPersonas',
  async ({id, idUserEnviador}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.ignorarInvitacionPersonas(id,idUserEnviador,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const aceptarInvitacionPersonas = createAsyncThunk(
  'invitaciones/aceptarPersonas',
  async ({id, idUserEnviador}, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */

      return await invitacionesService.aceptarInvitacionPersonas(id,idUserEnviador,token,thunkAPI.signal)
    } catch (error) {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createInvitaciones = createAsyncThunk(
  'invitaciones/crear',
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token 
      /* const token = JSON.parse(localStorage.getItem("user")).token;  */
      return await invitacionesService.createInvitaciones(data, token,thunkAPI.signal)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)


export const invitacionesSlice = createSlice({
  name: 'invitaciones',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvitaciones.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getInvitaciones.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invitaciones = action.payload
      })
      .addCase(getInvitaciones.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })

      .addCase(getInvitaciones2.pending, (state) => {
        /* state.isLoading = true */
      })
      .addCase(getInvitaciones2.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invitaciones = action.payload
      })
      .addCase(getInvitaciones2.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      
      .addCase(updateInvitaciones.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateInvitaciones.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
       /*  state.invitaciones.forEach((item, index)=>{
          if(item._id=== action.payload.busId){
            state.invitaciones[index]=action.payload.mod;
          }
        }) */
       
      })
      .addCase(updateInvitaciones.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      
      })
      .addCase(deleteInvitaciones.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteInvitaciones.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
       state.invitaciones= []
      })
      .addCase(deleteInvitaciones.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteOtrosInvitaciones.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteOtrosInvitaciones.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload
      })
      .addCase(deleteOtrosInvitaciones.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteInvitacionesEnviadas.pending, (state,action) => {
        /* state.isLoadingDel = true */
      /*   state.invitaciones[0].enviadas = state.invitaciones[0].enviadas.filter(
          (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
        )  */
      })
      .addCase(deleteInvitacionesEnviadas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      /*   state.isLoadingDel = false */
          state.invitaciones[0].enviadas = state.invitaciones[0].enviadas.filter(
            (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
          )  
      })
      .addCase(deleteInvitacionesEnviadas.rejected, (state, action) => {
        state.isLoading = false
        /*  state.isLoadingDel = false */
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteInvitacionesAceptadas.pending, (state,action) => {
      /*   state.isLoadingDel = true
        state.invitaciones[0].aceptadas = state.invitaciones[0].aceptadas.filter(
          (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
        )  */
      })
      .addCase(deleteInvitacionesAceptadas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      /*   state.isLoadingDel = false */
         state.invitaciones[0].aceptadas = state.invitaciones[0].aceptadas.filter(
            (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
          )  
      })
      .addCase(deleteInvitacionesAceptadas.rejected, (state, action) => {
        state.isLoading = false
         /* state.isLoadingDel = false */
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteInvitacionesIgnoradas.pending, (state,action) => {
    /*     state.isLoadingDel = true
        state.invitaciones[0].ignoradas = state.invitaciones[0].ignoradas.filter(
          (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
        )  */
      })
      .addCase(deleteInvitacionesIgnoradas.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isLoadingDel = false
          state.invitaciones[0].ignoradas = state.invitaciones[0].ignoradas.filter(
            (item) => item.userReceptor._id !== action.meta.arg.idUserReceptor
          ) 
      })
      .addCase(deleteInvitacionesIgnoradas.rejected, (state, action) => {
        state.isLoading = false
         /* state.isLoadingDel = false */
        state.isError = true
        state.message = action.payload
      })
      .addCase(ignorarInvitacion.pending, (state,action) => {
    //    state.isLoadingDel = true
   /*  state.isLoadingDel = true
    state.invitaciones[0].recibidas = state.invitaciones[0].recibidas.filter(
      (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
    )  */
      })
      .addCase(ignorarInvitacion.fulfilled, (state, action) => {
      /*   state.isLoadingDel = false */
        state.isLoading = false
        state.isSuccess = true
        //state.isLoadingDel = false
       
        state.invitaciones[0].recibidas = state.invitaciones[0].recibidas.filter(
            (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
          )  
      })
      .addCase(ignorarInvitacion.rejected, (state, action) => {
       /*  state.isLoadingDel = false */
        state.isLoading = false
      //   state.isLoadingDel = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(aceptarInvitacion.pending, (state,action) => {
    //    state.isLoadingDel = true
/*     state.isLoadingDel = true
    state.invitaciones[0].recibidas = state.invitaciones[0].recibidas.filter(
      (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
    )  */
      })
      .addCase(aceptarInvitacion.fulfilled, (state, action) => {
       /*  state.isLoadingDel = false */
        state.isLoading = false
        state.isSuccess = true
        //state.isLoadingDel = false
        /* console.log(action.meta.arg) */
          state.invitaciones[0].recibidas = state.invitaciones[0].recibidas.filter(
            (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
          )  
      })
      .addCase(aceptarInvitacion.rejected, (state, action) => {
        /* state.isLoadingDel = false */
        state.isLoading = false
      //   state.isLoadingDel = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(ignorarInvitacionPersonas.pending, (state,action) => {
        //    state.isLoadingDel = true
      /*   state.isLoadingDel = true
        state.invitaciones[0].recibidasPersonas = state.invitaciones[0].recibidasPersonas.filter(
          (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
        )  */
          })
          .addCase(ignorarInvitacionPersonas.fulfilled, (state, action) => {
            /* state.isLoadingDel = false */
            state.isLoading = false
            state.isSuccess = true
            //state.isLoadingDel = false
            /* console.log(action.meta.arg) */
             state.invitaciones[0].recibidasPersonas = state.invitaciones[0].recibidasPersonas.filter(
                (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
              ) 
          })
          .addCase(ignorarInvitacionPersonas.rejected, (state, action) => {
          /*   state.isLoadingDel = false */
            state.isLoading = false
          //   state.isLoadingDel = false
            state.isError = true
            state.message = action.payload
          })
          .addCase(aceptarInvitacionPersonas.pending, (state,action) => {
        //    state.isLoadingDel = true
       /*  state.isLoadingDel = true
        state.invitaciones[0].recibidasPersonas = state.invitaciones[0].recibidasPersonas.filter(
          (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
        )  */
          })
          .addCase(aceptarInvitacionPersonas.fulfilled, (state, action) => {
           /*  state.isLoadingDel = false */
            state.isLoading = false
            state.isSuccess = true
            //state.isLoadingDel = false
            /* console.log(action.meta.arg) */
            state.invitaciones[0].recibidasPersonas = state.invitaciones[0].recibidasPersonas.filter(
                (item) => item.userEnviador._id !== action.meta.arg.idUserEnviador
              )  
          })
          .addCase(aceptarInvitacionPersonas.rejected, (state, action) => {
           /*  state.isLoadingDel = false */
            state.isLoading = false
          //   state.isLoadingDel = false
            state.isError = true
            state.message = action.payload
          })
      .addCase(createInvitaciones.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createInvitaciones.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        /* state.invitaciones.forEach((item, index)=>{
          if(item.activo=== true){
            state.invitaciones[index].activo=false;
          }
        }) */
        // state.getIds = {...state.getIds,...action.meta.arg.userReceptor}
       
      })
      .addCase(createInvitaciones.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.meta.arg.userReceptor
      })
      
  },
})

export const { reset } = invitacionesSlice.actions
export default invitacionesSlice.reducer
