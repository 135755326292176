import axios from 'axios'
import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/onempresas/'

// Create new onempresas
const createOnempresas = async (onempresasData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post(API_URL, onempresasData, config)

  return response.data
}

// Get user onempresas
const getOnempresas = async (token,signal) => {
/*   console.log("Ha llegado acá") */
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get(API_URL, config)
/* console.log(response) */
  return response.data
}


// Get user onempresas
const getUsersOnempresasId = async (token,signal) => {
  /*   console.log("Ha llegado acá") */
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get(API_URL + "equipo", config)
  /* console.log(response) */
    return response.data
  }

  // Get user onempresas
const getUsersOnempresasId2 = async (token,signal) => {
  /*   console.log("Ha llegado acá") */
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get(API_URL + "equipo2", config)
  /* console.log(response) */
    return response.data
  }



// Delete user onempresas
const deleteOnempresas = async (onempresasId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL + onempresasId, config)

  return response.data
}

// Upload File uplaod
const uploadFileEmpresas = async (imageData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/upload/empresa", imageData, config)
/* console.log(response.data) */
  
  return response.data
}

// Upload File uplaod
const uploadFile2On = async (imageData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/upload/on", imageData, config)
/* console.log(response.data) */
  
  return response.data
}


// Delete Link
const elimLink = async (linkId, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal
  }

  const response = /* await  */axiosPrivate.delete("/api/link/eliminar/"+linkId, config)
/* console.log(response.data) */
  
  return response.data
}

// Crear Link
const crearLink = async (linkData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/link/crear/",linkData, config)
/* console.log(response.data) */
  
  return response.data
}


// Crear Link
const reenviarLink = async (linkData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/link/reenviar/",linkData, config)
/* console.log(response.data) */
  
  return response.data
}

// Crear Link
const reenviarLink2 = async (linkData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = /* await */ axiosPrivate.post("/api/link/reenviar/",linkData, config)
/* console.log(response.data) */
  
  return response.data
}





//delete several users
const deleteSeveralUsers = async(userId, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.delete(API_URL + "eliminarVarios/" + userId, config)

  return response.data
}

//delete several users
const deleteAndGetSeveralUsers = async(userId, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.delete(API_URL + "eliminarVariosGet/" + userId, config)

  return response.data
}

//edit another users
const editAnotherUser = async(userId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = /* await */ axiosPrivate.patch(API_URL + "otros/" + userId, modData, config)

  return response.data
}

//edit onempresas
const editOnempresas = async(onempresasId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }
//console.log("este "+token)
  const response = await axiosPrivate.patch(API_URL + onempresasId, modData, config)
  
  return response.data
}

const editOnempresas2 = async(onempresasId, modData, token,signal) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }
//console.log("este "+token)
  const response = /* await */ axiosPrivate.patch(API_URL + onempresasId, modData, config)
  
  return response.data
}

const onempresasService = {
  createOnempresas,
  getOnempresas,
  deleteOnempresas,
  editAnotherUser,
  deleteSeveralUsers,
  editOnempresas,
  editOnempresas2,
  getUsersOnempresasId,
  uploadFileEmpresas,
  uploadFile2On,
  elimLink,
  crearLink,
  getUsersOnempresasId2,
  reenviarLink,
  reenviarLink2,
  deleteAndGetSeveralUsers,
  
}

export default onempresasService;