import axios from "axios";
import { axiosPrivate } from "../../API/refreshToken";

const API_URL = "/api/notificaciones/";

// Get user notificaciones
const getNotificaciones = async (token, signal) => {
  /*   console.log("Ha llegado acá") */
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.get(API_URL + "get", config);
  /* console.log(response) */
  return response.data;
};



// Delete notificaciones
const deleteNotificaciones = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axiosPrivate.delete(
    API_URL + "deleteNotificaciones/" + userId,
    config
  );

  return response.data;
};

// Delete Otros notificaciones
const deleteOtrosNotificaciones = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axiosPrivate.delete(
    API_URL + "deleteOtrosNotificaciones/" + userId,
    config
  );

  return response.data;
};


const cambiarUnNotificacion = async (notData, token, signal) => {

  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };
  

  const response = await axiosPrivate.post("/api/notificaciones/cambiarUnNotificacion",notData,config);

  return response.data;
};


const cambiarTodasNotificacion = async (notData, token, signal) => {
 
   const config = {
     headers: {
       Authorization: `Bearer ${token}`,
     },
     signal:signal,
   };
 
   const response = await axiosPrivate.post("/api/notificaciones/cambiarTodasNotificacion",notData,config);
 
   return response.data;
 };





const notificacionesService = {
  getNotificaciones,
  deleteNotificaciones,
  deleteOtrosNotificaciones,
  cambiarUnNotificacion,
  cambiarTodasNotificacion,
};

export default notificacionesService;
