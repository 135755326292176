import axios from 'axios'
import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/invitaciones/'

// Create new invitaciones
const createInvitaciones = async (invitacionesData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  await  axiosPrivate.post(API_URL, invitacionesData, config)

  return response.data
}

// Get user invitaciones
const getInvitaciones = async (token,signal) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get(API_URL, config)

  return response.data
}



// Delete user invitaciones
const deleteInvitaciones = async (invitacionesId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL + invitacionesId, config)

  return response.data
}

// Delete user invitaciones
const deleteOtrosInvitaciones = async (invitacionesId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL+ "deleteAllOtros/" + invitacionesId, config)

  return response.data
}


// Delete user invitaciones
const deleteInvitacionesEnviadas = async (invitacionesId,userReceptorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */ axiosPrivate.delete(API_URL + invitacionesId + "/" + userReceptorId, config)

  return response.data
}

// Delete user invitaciones
const deleteInvitacionesAceptadas = async (invitacionesId,userReceptorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */ axiosPrivate.delete(API_URL + "delete/"+ invitacionesId + "/" + userReceptorId, config)

  return response.data
}

// Delete user invitaciones
const deleteInvitacionesIgnoradas = async (invitacionesId,userReceptorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */ axiosPrivate.delete(API_URL + "deleteIg/"+ invitacionesId + "/" + userReceptorId, config)

  return response.data
}

// Ignorar user invitaciones
const ignorarInvitacion = async (invitacionesId,userEnviadorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */  axiosPrivate.delete(API_URL + "ignorar/"+ invitacionesId + "/" + userEnviadorId, config)

  return response.data
}

// aceptar user invitaciones
const aceptarInvitacion = async (invitacionesId,userEnviadorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */ axiosPrivate.delete(API_URL + "aceptar/"+ invitacionesId + "/" + userEnviadorId, config)

  return response.data
}

// Ignorar user invitaciones
const ignorarInvitacionPersonas = async (invitacionesId,userEnviadorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */  axiosPrivate.delete(API_URL + "ignorarPersonas/"+ invitacionesId + "/" + userEnviadorId, config)

  return response.data
}

// aceptar user invitaciones
const aceptarInvitacionPersonas = async (invitacionesId,userEnviadorId, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response =  /* await */ axiosPrivate.delete(API_URL + "aceptarPersonas/"+ invitacionesId + "/" + userEnviadorId, config)

  return response.data
}


//edit notificaciones
const updateInvitaciones = async(invId, modData, token,signal) => {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }

    const response = await axiosPrivate.patch(API_URL  + invId, modData, config)

    return response.data
  }


const invitacionesService = {
  createInvitaciones,
  getInvitaciones,
  deleteInvitaciones,
  updateInvitaciones,
  deleteInvitacionesEnviadas,
  deleteInvitacionesAceptadas,
  deleteInvitacionesIgnoradas,
  ignorarInvitacion,
  aceptarInvitacion,
  ignorarInvitacionPersonas,
  aceptarInvitacionPersonas,
  deleteOtrosInvitaciones,
}

export default invitacionesService