import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {  useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import rekloot from "../../../../utils/icons/rekloot.webp";
import NavItemLi from "../../components/Topbar/components/NavItem/NavItemLi";
import { useNavigate } from "react-router-dom";
import "./topbar.styles.css";
import NavItemLo from "../../components/Topbar/components/NavItem/NavItemLo";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false, user }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    Candidatos: candidatos,
    Reclutadores: reclutadores,
    Nosotros: nosotros,
  } = pages;

  const navigate = useNavigate();
 //const { user } = useSelector((state) => state.auth);


/* 
const onClicker = () =>{
  navigate("/");
}
 */
/* const onClicker2 = () =>{
  navigate("/login");
}
const onClicker3 = () =>{
  navigate("/registrarse");
} */

/* const updateAndThenLogout = () => async (dispatch) => {
  return await dispatch(logout());
};
 */


  

  return (
    <>
   {/*    {!user ? ( */}
         <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          className="navi"
        >
          <Box
            sx={{ display: { xs: "flex", md: "flex" } }}
            alignItems={"center"}
          >
            <Box className={"supra-img"}>
              <Box
                display={"flex"}
                component="div"
                sx={{cursor:"pointer"}}
                onClick={()=>{
                  navigate("/")
                }}
                title="Rekloot"
                width={{ xs: 100, md: 150 }}
                className={"contenedor-img"}
              >
                <Box
                  component={"img"}
                  src={
                    mode === "light" && !colorInvert
                      ? rekloot
                      : rekloot
                  }
                  alt={'rekloot'}
                  height={1}
                  width={1}
                  className={"image-logo"}
                  paddingRight={{ xs: 0, md: "2rem" }}
                />
              </Box>
            </Box>
            <ul className="listoca">
              {/*     <li className="pri-li">
                <Box
                  marginLeft={0}
                  className={"cont-link-item"}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <Link to="/" className={"item_navbar"}>
                    <Typography
                      fontWeight={700}
                      color={"#1e2022"}
                      textDecoration={"none"}
                      fontSize="0.8rem"
                    >
                      Candidatos
                    </Typography>
                  </Link>
                </Box>
              </li> */}

               <NavItemLi
                title={"Para Candidatos"}
                id={"Candidatos"}
                items={candidatos}
                colorInvert={colorInvert}
                propmod={"translate(20%, -25px)!important"}
                clase={"pri-li"}
               
              /> 

              <NavItemLo
                title={"Para Empresas"}
                id={"Reclutadores"}
                items={reclutadores}
                colorInvert={colorInvert}
                propmod={"translate(20%, -25px)!important"}
                clase={"pri-li"}
              />

              <NavItemLi
                title={"Nosotros"}
                id={"Nosotros"}
                items={nosotros}
                colorInvert={colorInvert}
                propmod={"translate(30%, -25px)!important"}
                clase={"pri-li"}
              />
            </ul>
          </Box>

          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            alignItems={"center"}
          >
     
            <Box marginLeft={2}>
              <Button
                // variant="outlined"
                // color="primary"
                onClick={()=>{
                  navigate("/login")
                }}
                // sx={{fontSize:"14px"}}
               size="large"
                // sx={{ width: 150 }}
                className="boton-loginN"
              >
                Inicia Sesión
              </Button>
            </Box>
            <Box marginLeft={2}>
              <Button
              //  variant="contained"
               // color="primary"
              //  sx={{fontSize:"14px"}}
               onClick={()=>{
                  navigate("/registrarse")
                }}
                size="large"
              //  sx={{ width: 150 }}
                className="boton-registroN"
              >
                Regístrate
              </Button>
            </Box>
            {/*    </>
          
        ) : (
          <>
            <Box marginLeft={4}>
              <Button
                variant="outlined"
                color="primary"
                target="blank"
                onClick={onLogout}
                size="large"
                sx={{width: 150}}
              >
                Logout
              </Button>
            </Box>
          </>
        )} */}
          </Box>
          <Box
            sx={{ display: { xs:"block", md:"none"} }}
            alignItems={"center"}
            
          >
            <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={"outlined"}
              disableTouchRipple
              sx={{
                borderRadius: 2,
                minWidth: "auto",
                padding: 1,
                borderColor: 'transparent',
                "&:hover":{
                  borderColor: 'transparent',
                  backgroundColor:"transparent"
                }
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Box>
 {/*       ) : (
        <>
          <Box marginLeft={4}>
            <Button
              //variant="outlined"
              //color="primary"
            //  target="blank"
              onClick={onLogout}
             // size="large"
              sx={{ width: 150 }}
              className="boton-registro"
            >
              Cerrar sesión
            </Button>
          </Box>
        </>
      )}  */}
    </>
  );
};

export default Topbar;
