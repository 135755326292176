import { axiosPrivate } from "../../API/refreshToken";

const API_URL = "/api/webpay/";

// Get user onempresas
const getSusMedio = async (token,signal) => {
  /*   console.log("Ha llegado acá") */
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.get(API_URL + "susMedio", config);
  /* console.log(response) */
  return response.data;
};

const oneClickFinish = async (oneData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.post(
    API_URL + "oneclickFinish",
    oneData,
    config
  );
  /* console.log(response) */
  return response.data;
};

const oneClickDelete = async (token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };
  /* console.log(token) */

  const response = await axiosPrivate.post(API_URL + "oneclickDelete",{}, config);
   /* console.log(response)  */
  return response.data;
};

const oneClickK = async (oneData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.post(
    API_URL + "autorizarK",
    oneData,
    config
  );
  /* console.log(response) */
  return response.data;
};

const oneClickVal = async (val, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.get(API_URL + "oneclick/" + val, config);
  /* console.log(response) */
  return response.data;
};

const oneClickInscripVal = async (val, token, signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  };

  const response = await axiosPrivate.get(
    API_URL + "oneclickInscrip/" + val,
    config
  );
  /* console.log(response) */
  return response.data;
};

const susMedioService = {
  getSusMedio,
  oneClickFinish,
  oneClickDelete,
  oneClickK,
  oneClickVal,
  oneClickInscripVal,
};

export default susMedioService;
