import { useDispatch } from 'react-redux'
import { axiosPrivate } from '../../API/refreshToken'
import {socket} from "../../components/Header2/components/Buffer"

const API_URL = '/api/mensajes/'


// Get user mensajes
const getMensajes = async (token,signal) => {
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get(API_URL, config)
  
    return response.data
  }

  // Create new goal
const createMensaje = async (mensajeData, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL, mensajeData, config)
    //console.log(mensajeData)
/*     socket?.emit("new message", {
      userReceptor:mensajeData.idReceptor,
      message:mensajeData.cuerpo,
      tipo:"mensaje",
    }) */
    
    return response.data
  }


  // Upload File uplaod
const uploadFileImageMessage = async (imageData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/upload/messageImage", imageData, config)
/* console.log(response.data) */
  // console.log(response.data)

/*   socket?.emit("new message", {
    userReceptor:imageData.idReceptor,
    message:imageData.cuerpo,
    tipo:"mensaje",
  }) */
  return response.data
}


  const leerMensaje = async (idEnviador, token, signal) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response =  /* await */  axiosPrivate.post(API_URL + "leerMensaje", idEnviador, config)
  
    return response.data
  }


    // Create new goal
    const eliminarMensajeEnviado = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response =  /* await */  axiosPrivate.post(API_URL + "eliminarMensajeEnviado", dataNec, config)
    
      return response.data
    }

    const eliminarArchivoEnviado = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
       const response =  /* await */  axiosPrivate.post(API_URL + "eliminarArchivoEnviado", dataNec, config)
    
       return response.data
      //return
    }

    const setCarpeta = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response =  /* await */  axiosPrivate.post(API_URL + "setCarpeta", dataNec, config)
    
      return response.data
    }

    const eliminarConversacion = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response =  /* await */  axiosPrivate.post(API_URL + "eliminarConversacion", dataNec, config)
    
      return response.data
    }

    const eliminarDef = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
    
      const response =  /* await */  axiosPrivate.post(API_URL + "eliminarDef", dataNec, config)
    
      return response.data
    }


    const restaurarMensaje = async (dataNec, token, signal) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal:signal,
      }
   
      const response =   axiosPrivate.post(API_URL + "restaurarMensaje", dataNec, config)
     
      return  response.data 
    }

    // Delete user invitaciones
const deleteMensajes = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL + userId, config)

  return response.data
}

// Delete user invitaciones
const deleteOtrosMensajes = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL+ "deleteAllOtros/" + userId, config)

  return response.data
}

const mensajesService = {
    getMensajes,
    createMensaje,
    uploadFileImageMessage,
    leerMensaje,
    eliminarMensajeEnviado,
    setCarpeta,
    eliminarConversacion,
    eliminarDef,
    restaurarMensaje,
    eliminarArchivoEnviado,
    deleteMensajes,
    deleteOtrosMensajes,
}

export default mensajesService