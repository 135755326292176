import Box from "@mui/material/Box";
import {  useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";

const NavItemLo = ({ title, id, items, colorInvert = false, propmod, clase }) => {
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
      
    },
    popoverContent: {
      pointerEvents: "auto",
      opacity: "1",
      
      //transform: "translateX(60px)!important",
      
 //     transition: "visibility .3s,opacity .3s ease,transform .5s cubic-bezier(.33,0,.6,1.58)!important",
    },

   


  }));

//   const [anchorEl, setAnchorEl] = useState(null);
//   const [openedPopoverId, setOpenedPopoverId] = useState(null);


  const navigate = useNavigate();

//   const popoverAnchor = useRef(null);
//   const handleClick = (event, popoverId) => {
//     setAnchorEl(event.target);
//     setOpenedPopoverId(popoverId);
//   };

//   const handleClose = (e) => {
//     setOpenedPopoverId(null);
//     setAnchorEl(null);
//   };

//   const [activeLink, setActiveLink] = useState("");
//   useEffect(() => {
//     setActiveLink(window && window.location ? window.location.pathname : "");
//   });

//   const hasActiveLink = () => items.find((i) => i.href === activeLink);
   const linkColor = colorInvert ? "common.white" : "text.primary";
//   const classes = useStyles();

  return (
    <li
      className={clase}
      onClick={()=>{navigate("/reclutadores")}}
      //onMouseEnter={(e) => handleClick(e, id)}
     //onMouseLeave={handleClose}
    >
     
      <Box
      //sx={{ display: { xs: "none", md: "flex" } }}
      
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          aria-describedby={id}
          sx={{ cursor: "pointer" }}
          //ref={popoverAnchor}
      //    aria-owns={id}
        >
          <Typography
            fontWeight={ 700}
            color={linkColor}
            fontSize="14px"
            lineHeight={"50px"}
          className="hoverArribaTopNoUs"
          >
            {title}
          </Typography>
          {/* <ExpandMoreIcon
            sx={{
              marginLeft: theme.spacing(1 / 4),
              width: 16,
              height: 16,
              transition: "transform 200ms ease",
            //  transform: openedPopoverId === id ? "rotate(180deg)" : "none",
            //  color: linkColor,
            }}
          /> */}
        </Box>
        
      </Box>
    </li>
  );
};

export default NavItemLo;
