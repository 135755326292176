import axios from 'axios'
import { axiosPrivate } from '../../API/refreshToken'

const API_URL = '/api/onboard/'

// Create new onboard
const createOnboard = async (onboardData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post(API_URL, onboardData, config)

  
  return response.data
}

// Get user onboard
const getOnboard = async (token,signal) => {
/*   console.log("Ha llegado acá") */
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.get(API_URL, config)
/* console.log(response) */
  return response.data
}

// Get user onboard
const getOnboardSinLoad = async (token,signal) => {
  /*   console.log("Ha llegado acá") */
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  
    const response = await axiosPrivate.get(API_URL, config)
  /* console.log(response) */
    return response.data
  }

// Delete user goal
const deleteOnboard = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axiosPrivate.delete(API_URL + userId, config)

  return response.data
}

//edit onboard
const editOnboard = async(onboardId, modData, token,signal) => {
/*  console.log("Pasó???") */
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }
//console.log("este "+token)
  const response = await axiosPrivate.patch(API_URL + onboardId, modData, config)
 
  return response.data
}

//edit onboard
const editOnboard2 = async(onboardId, modData, token,signal) => {
  /*  console.log("Pasó???") */
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal:signal,
    }
  //console.log("este "+token)
    const response = /* await */ axiosPrivate.patch(API_URL + onboardId, modData, config)
   
    return response.data
  }
  


// Upload File uplaod
const uploadFileOn = async (imageData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/upload/on", imageData, config)
/* console.log(response.data) */
  
  return response.data
}

// Upload File uplaod
const uploadCV = async (fileData, token,signal) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
     /*  'Content-Type': 'application/json' */
       "Content-Type": "multipart/form-data",
      /* "Accept": "application/json", */
    },
    signal:signal,
  }

  const response = await axiosPrivate.post("/api/files/uploadCV", fileData, config)
/* console.log(response.data) */
  
  return response.data
}



const onboardService = {
  createOnboard,
  getOnboard,
  deleteOnboard,
  editOnboard,
  uploadFileOn,
  uploadCV,
  editOnboard2,
  getOnboardSinLoad,
}

export default onboardService;